import * as Yup from 'yup'
import {
  IUppsagningFormValues,
  UppsagningFormTypes,
} from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/Uppsagning/Types'
import { IUppsagningContent } from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/Uppsagning/Types/content'
import { isAfter, isBefore, subDays } from 'date-fns'
import { getDate } from '@local/Utils/Helpers/formatDate'
import {
  calculateDate,
  today,
  yesterday,
} from '@local/Utils/Helpers/Datetime.helpers'
import { email } from '@local/Utils/Helpers/regexes'

export const uppsagningValidationSchema = (
  content: IUppsagningContent,
  sistaAnstallningsdatumMinDate: Date
): Yup.ObjectSchema<IUppsagningFormValues> =>
  Yup.object().shape({
    [UppsagningFormTypes.SistaAnstallningsdatum]: Yup.date()
      .typeError(content.sistaAnstallningsdatum.varning)
      .required(content.sistaAnstallningsdatum.varning)
      .test(
        'minDateMustBeAfterOctoberStartOrAnstallningsdatum',
        content.sistaAnstallningsdatum.varningMinDate,
        (date) => isAfter(date, subDays(sistaAnstallningsdatumMinDate, 1))
      )
      .test(
        'minDateMustBeWithinTwoYearsFromToday',
        content.sistaAnstallningsdatum.maxAmountExceeded,
        (date) =>
          isAfter(date, calculateDate(yesterday, { numberOfYearsFromDate: -2 }))
      )
      .test(
        'maxDateMustBeBeforeToday',
        content.sistaAnstallningsdatum.varningMaxDate,
        (date) => isBefore(new Date(getDate(date)), new Date(getDate(today)))
      ),
    [UppsagningFormTypes.ForetagKontaktpersonEpostadress]: Yup.string()
      .nullable()
      .required(content.kontaktPersoner.varningMailadress)
      .matches(email, {
        message: content.kontaktPersoner.varningMailadressFelaktig,
      }),
    [UppsagningFormTypes.ForetagKontaktpersonNamn]: Yup.string()
      .nullable()
      .required(content.kontaktPersoner.varningNamn),
  })

export const initialUppsagningValues: IUppsagningFormValues = {
  sistaAnstallningsdatum: null,
  foretagKontaktpersonEpostadress: null,
  foretagKontaktpersonNamn: null,
}
