import { IAnsokanApiModel } from '@local/Services/Types/apiTypes'
import { booleanToRadioChoice } from '@local/Utils/Helpers/Forms/form.helpers'
import { initialArbetslivserfarenhetTBValues } from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/ArbetslivserfarenhetTB/ArbetslivserfarenhetTB.schema'
import { IArbetslivserfarenhetTBFormValues } from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/ArbetslivserfarenhetTB/Types'

const mapAnsokanToArbetslivserfarenhetTBFormValues = (
  ansokan: IAnsokanApiModel
): IArbetslivserfarenhetTBFormValues => {
  const { hasIntygatEtableringsvillkor } = ansokan

  return {
    ...initialArbetslivserfarenhetTBValues,
    hasIntygatEtableringsvillkor:
      booleanToRadioChoice(hasIntygatEtableringsvillkor) ??
      initialArbetslivserfarenhetTBValues.hasIntygatEtableringsvillkor,
  }
}

export { mapAnsokanToArbetslivserfarenhetTBFormValues }
