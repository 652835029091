import { Button, Typography } from '@mui/material'
import { useFormikContext } from 'formik'
import Gutter from '@local/Components/Gutter'
import TextInput from '@local/Components/TextInput'
import { AnsokanKeys } from '@local/Types'
import { usePickEpiContent } from '@local/Utils/Hooks'
import { ITidsbegransadAnstallningContent } from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/TidsbegransadAnstallning.types'
import { ITidsbegransadAnstallningFormValues } from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/TidsbegransadAnstallningForm/types'
import { useCallback } from 'react'
import { isEmpty, isNil } from 'ramda'
import { useGetArbetsgivare } from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/hooks'
import { usePatchAnsokanMutation } from '@local/Services/API/skapaAnsokanApi'
import { RadioChoice } from '@local/Types/form.types'
import HTMLMapper from '@local/Components/HTMLMapper'

const Organisationsnummer = () => {
  const [patchAnsokan] = usePatchAnsokanMutation()
  const getArbetsgivare = useGetArbetsgivare()
  const formikContext = useFormikContext<ITidsbegransadAnstallningFormValues>()
  const { values, errors, setFieldValue } = formikContext

  const {
    arbetsplats: { organisationsNummer, orgnummerArInteAnslutet },
  } = usePickEpiContent<ITidsbegransadAnstallningContent>()

  const handleSparaOrganisationsnummer = useCallback(() => {
    if (isNil(errors.foretagorganisationsnummer)) {
      getArbetsgivare()
        .unwrap()
        .then(({ results }) => {
          if (!isEmpty(results)) {
            patchAnsokan({
              values: {
                foretagNamn: results[0].name,
                foretagOrganisationsnummer:
                  values.foretagorganisationsnummer.replace('-', ''),
                foretagId: results[0].id,
              },
              isMuiStepperEnabled: true,
            })
              .then(() => {
                setFieldValue(
                  AnsokanKeys.NuvarandeArbetsplats,
                  RadioChoice.Yes
                )?.catch((err) => {
                  console.log(err)
                })
              })
              .catch((err) => {
                console.log(err)
              })
          } else {
            setFieldValue(
              AnsokanKeys.OrganisationsnummerIsAffiliated,
              RadioChoice.No
            )?.catch((err) => {
              console.log(err)
            })
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }, [errors, getArbetsgivare, patchAnsokan, setFieldValue, values])

  return (
    <>
      <Typography variant="h6">{organisationsNummer.titel}</Typography>

      <Gutter xs={16} />

      <Typography variant="subtitle2">
        {organisationsNummer.hjalptext}
      </Typography>

      <Gutter xs={16} />

      <TextInput
        withTrim
        formikContext={formikContext}
        label={organisationsNummer.textFaltTitel}
        name={AnsokanKeys.Organisationsnummer}
      />

      <Gutter xs={24} />

      <Button variant="contained" onClick={handleSparaOrganisationsnummer}>
        {organisationsNummer.uppdateraOrgnummer}
      </Button>

      <Gutter xs={32} />

      {values.organisationsnummerIsAffiliated === RadioChoice.No && (
        <HTMLMapper body={orgnummerArInteAnslutet.mainBody} />
      )}
    </>
  )
}

export default Organisationsnummer
