import { JSX } from 'react'
import { Collapse } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import { ICollapsibleAlert } from '@local/Components/CollapsibleAlert/Types'
import Alert from '@local/Components/Alert'

const CollapsibleAlert = ({
  open = true,
  setIsOpen,
  severity = 'info',
  message,
}: ICollapsibleAlert): JSX.Element => (
  <Collapse in={open}>
    <Alert
      severity={severity}
      action={
        <IconButton
          aria-label="Dölj varningsmeddelande"
          color="default"
          size="small"
          onClick={() => {
            setIsOpen(false)
          }}
        >
          <CloseIcon />
        </IconButton>
      }
    >
      {message}
    </Alert>
  </Collapse>
)

export default CollapsibleAlert
