import FormErrorMessage from '@local/Components/FormErrorMessage'
import { ITjanstepersonAnsokanContent } from '@local/Types'
import { RadioChoice } from '@local/Types/form.types'
import { usePickEpiContent } from '@local/Utils/Hooks'
import { IOnboardingFormValues } from '@local/Views/OnboardingWrapper/Onboarding/OnboardingForm/OnboardingForm.schema'
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material'
import { useFormikContext } from 'formik'
import { isNil } from 'ramda'

const Anstallningssituation = () => {
  const {
    onboarding: { anstallning },
  } = usePickEpiContent<ITjanstepersonAnsokanContent>()
  const { handleChange, handleBlur, errors, touched, values } =
    useFormikContext<IOnboardingFormValues>()

  const isError = touched.iAnstallning && !isNil(errors.iAnstallning)

  return (
    <FormControl error={isError} variant="standard" fullWidth>
      <Typography variant="h6" gutterBottom>
        {anstallning.titel}
      </Typography>

      <RadioGroup
        onChange={handleChange}
        onBlur={handleBlur}
        defaultValue={values.iAnstallning}
      >
        <FormControlLabel
          value={RadioChoice.Yes}
          control={<Radio color="primary" />}
          label={anstallning.ja}
          name="iAnstallning"
          id="iAnstallning.ja"
        />
        <FormControlLabel
          value={RadioChoice.No}
          control={<Radio color="primary" />}
          label={anstallning.nej}
          name="iAnstallning"
          id="iAnstallning.nej"
        />
      </RadioGroup>
      {isError && <FormErrorMessage>{errors.iAnstallning}</FormErrorMessage>}
    </FormControl>
  )
}

export default Anstallningssituation
