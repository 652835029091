import { JSX, useEffect, useState } from 'react'
import { Wizard } from '@trr/wizard-library'
import WizardGuard from '@local/Components/WizardGuard/WizardGuard'
import Arbetsplats from '@local/Views/SkapaAnsokan/Components/Arbetsplats'
import AnstallningTB from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/AnstallningTB'
import GranskaAnsokanTB from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/GranskaAnsokanTB'
import LonOchErsattning from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/LonOchErsattning'
import Uppsagning from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/Uppsagning'
import SlutfordAnsokan from '@local/Views/SkapaAnsokan/Components/SlutfordAnsokan'
import ArbetslivserfarenhetTB from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/ArbetslivserfarenhetTB/ArbetslivserfarenhetTB'
import { usePatchAnsokanMutation } from '@local/Services/API/skapaAnsokanApi'
import Toaster from '@local/Components/Toaster'
import { usePickEpiContent } from '@local/Utils/Hooks'
import { ITidsbegransadAnstallningContent } from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/TidsbegransadAnstallning.types'

const TidsbegransadAnstallning = (): JSX.Element => {
  const [toasterOpen, setToasterOpen] = useState(false)

  const { patchGickFel } = usePickEpiContent<ITidsbegransadAnstallningContent>()

  const [, { isError: isErrorPatchAnsokan }] = usePatchAnsokanMutation({
    fixedCacheKey: 'patchAnsokan',
  })
  useEffect(() => {
    if (isErrorPatchAnsokan) {
      setToasterOpen(true)
    } else {
      setToasterOpen(false)
    }
  }, [isErrorPatchAnsokan])

  return (
    <>
      <Toaster
        message={patchGickFel.heading}
        open={toasterOpen}
        setOpen={setToasterOpen}
      />
      <Wizard>
        <WizardGuard>
          <Arbetsplats />
        </WizardGuard>

        <WizardGuard>
          <AnstallningTB />
        </WizardGuard>

        <WizardGuard>
          <Uppsagning />
        </WizardGuard>

        <WizardGuard>
          <LonOchErsattning />
        </WizardGuard>

        <WizardGuard>
          <ArbetslivserfarenhetTB />
        </WizardGuard>

        <WizardGuard>
          <GranskaAnsokanTB />
        </WizardGuard>

        <SlutfordAnsokan />
      </Wizard>
    </>
  )
}

export default TidsbegransadAnstallning
