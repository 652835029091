import { baseApi } from '@local/Services/API/baseApi'

export interface IJobTitleSuggestionApiModel {
  id: string
  jobTitle: string
  jobTitleWithHighLightedSearchTerm: string
}

export const befattningApi = baseApi.injectEndpoints({
  overrideExisting: false,

  endpoints: (builder) => ({
    getBefattningar: builder.query<IJobTitleSuggestionApiModel[], string>({
      query: (sokOrd) => `/referencedata/jobtitles/v2/suggestions/${sokOrd}`,
    }),
  }),
})

export const { useGetBefattningarQuery } = befattningApi
