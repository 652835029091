import { IAnsokanApiModel } from '@local/Services/Types/apiTypes'
import { toTrrDateOrDefault } from '@local/Utils/Helpers/formatDate'
import { IUppsagningFormValues } from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/Uppsagning/Types'
import { initialUppsagningValues } from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/Uppsagning/Uppsagning.schema'

const mapAnsokanToUppsagningFormValues = (
  ansokan: IAnsokanApiModel
): IUppsagningFormValues => {
  const { anstallningsuppgifter, foretag } = ansokan

  const formValues: IUppsagningFormValues = {
    ...initialUppsagningValues,
    sistaAnstallningsdatum: anstallningsuppgifter?.sistaAnstallningsdatum
      ? toTrrDateOrDefault(anstallningsuppgifter?.sistaAnstallningsdatum)
      : initialUppsagningValues.sistaAnstallningsdatum,
    foretagKontaktpersonEpostadress: foretag?.kontaktperson?.epostadress,
    foretagKontaktpersonNamn: foretag?.kontaktperson.namn,
  }
  return formValues
}

export { mapAnsokanToUppsagningFormValues }
