import { RadioChoice } from '@local/Types/form.types'

export interface ILonOchErsattningFormValues {
  [LonOchErsattningFormTypes.Manadslon]: number
  [LonOchErsattningFormTypes.Provision]: RadioChoice
  [LonOchErsattningFormTypes.ProvisionBelopp]: number
  [LonOchErsattningFormTypes.Jour]: RadioChoice
  [LonOchErsattningFormTypes.JourBelopp]: number
  [LonOchErsattningFormTypes.SjukersattningsgradChoice]: RadioChoice
  [LonOchErsattningFormTypes.Sjukersattningsgrad]: number
}

export enum LonOchErsattningFormTypes {
  Manadslon = 'manadslon',
  Provision = 'provision',
  ProvisionBelopp = 'provisionBelopp',
  Jour = 'jour',
  JourBelopp = 'jourBelopp',
  SjukersattningsgradChoice = 'sjukersattningsgradChoice',
  Sjukersattningsgrad = 'sjukersattningsgrad',
}
