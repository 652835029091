import { IAnsokanPatchApiModel } from '@local/Services/Types/apiTypes'
import { IPatchBodyApiModel, PatchOps } from '@local/Services/Types/patchTypes'

export const mapAnsokanValuesToPatchModel = (
  values: Partial<IAnsokanPatchApiModel>
): IPatchBodyApiModel[] =>
  Object.keys(values).map((key) => ({
    op: PatchOps.Replace,
    path: key,
    value: values[key as keyof IAnsokanPatchApiModel],
  }))
