import { AnsokanKeys } from '@local/Types'
import { RadioChoice } from '@local/Types/form.types'
import { ITjanstemanIAnstallningFormValues } from '@local/Views/SkapaAnsokan/TjanstemanIAnstallning/TjanstemanIAnstallningForm/types'
import { isNil } from 'ramda'

export const purifyEntries = (entries: string[]) => ({
  id: entries[0],
  value: entries[1],
})

//Middleware to be used to transform data before storing in redux slice
export const tjanstemanIAnstallningStoreMiddleware = (
  changes: Partial<ITjanstemanIAnstallningFormValues>
): Partial<ITjanstemanIAnstallningFormValues> => {
  let changesToStore: Partial<ITjanstemanIAnstallningFormValues> = changes

  Object.entries(changes).forEach((change) => {
    const fieldChange = purifyEntries(change)

    switch (fieldChange.id as AnsokanKeys) {
      case AnsokanKeys.Agarandel:
        if (!isNil(fieldChange.value)) {
          changesToStore = {
            ...changesToStore,
            harAgandeskap: RadioChoice.Yes,
          }
        }

        break
    }
  })

  return changesToStore
}
