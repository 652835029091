import { JSX } from 'react'
import { AppShellDataProps } from '@trr/app-shell-data'
import { AppShellRouter, KeyRoute } from '@local/Router'
import { Routes } from '@local/Router/routes'
import OnboardingWrapper from '@local/Views/OnboardingWrapper'
import OgiltigtOrgnummer from '@local/Views/OgiltigtOrgnummer'
import TjanstemanIAnstallning from '@local/Views/SkapaAnsokan/TjanstemanIAnstallning'
import TjanstemanIAnstallningOld from '@local/Views/SkapaAnsokan/TjanstemanIAnstallningOld'
import TidsbegransadAnstallningOld from '@local/Views/SkapaAnsokan/TidsbegransadAnstallningOld'
import { useIsFeatureFlagEnabled } from '@local/Utils/Hooks'
import TidsbegransadAnstallning from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning'
import TackForDinAnsokan from '@local/Views/TackForDinAnsokan'

const Views = ({
  appShellData,
}: {
  appShellData: AppShellDataProps
}): JSX.Element => {
  const isMuiStepperEnabled = useIsFeatureFlagEnabled(
    'TjanstepersonAnsokan-Frontend_muiStepper_temp_241118'
  )

  return (
    <AppShellRouter currentKey={appShellData.currentKey}>
      <KeyRoute urlKey={Routes.Start}>
        <OnboardingWrapper />
      </KeyRoute>

      <KeyRoute urlKey={Routes.OgiltigtOrgnummer}>
        <OgiltigtOrgnummer />
      </KeyRoute>

      <KeyRoute urlKey={Routes.TiaAnsokan}>
        {isMuiStepperEnabled ? (
          <TjanstemanIAnstallning />
        ) : (
          <TjanstemanIAnstallningOld />
        )}
      </KeyRoute>

      <KeyRoute urlKey={Routes.TbAnsokan}>
        {isMuiStepperEnabled ? (
          <TidsbegransadAnstallning />
        ) : (
          <TidsbegransadAnstallningOld />
        )}
      </KeyRoute>

      <KeyRoute urlKey={Routes.TackForDinAnsokan}>
        <TackForDinAnsokan />
      </KeyRoute>
    </AppShellRouter>
  )
}
export default Views
