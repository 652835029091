import { getBefattningSelectOption } from '@local/Components/Befattningar/Befattning.helpers'
import { IAnsokanApiModel } from '@local/Services/Types/apiTypes'
import { toTrrDateOrDefault } from '@local/Utils/Helpers/formatDate'
import { booleanToRadioChoice } from '@local/Utils/Helpers/Forms/form.helpers'
import { initialAnstallningTIAValues } from '@local/Views/SkapaAnsokan/TjanstemanIAnstallningOld/AnstallningTIA/AnstallningTIAForm.schema'
import { IAnstallningTIAFormValues } from '@local/Views/SkapaAnsokan/TjanstemanIAnstallningOld/AnstallningTIA/Types'

const mapAnsokanToAnstallningFormValues = (
  ansokan: IAnsokanApiModel
): IAnstallningTIAFormValues => {
  const { anstallningsuppgifter, loneuppgifter } = ansokan

  return {
    ...initialAnstallningTIAValues,
    befattning: getBefattningSelectOption(
      anstallningsuppgifter?.befattningId,
      anstallningsuppgifter?.befattning
    ),
    isVdEllerUndantagsgrupp:
      booleanToRadioChoice(anstallningsuppgifter?.isVdEllerUndantagsgrupp) ??
      initialAnstallningTIAValues.isVdEllerUndantagsgrupp,
    anstallningsdatum: anstallningsuppgifter?.anstallningsdatum
      ? toTrrDateOrDefault(anstallningsuppgifter?.anstallningsdatum)
      : initialAnstallningTIAValues.anstallningsdatum,
    manadslon: loneuppgifter?.manadslon,
  }
}

export { mapAnsokanToAnstallningFormValues }
