import { IAnsokanApiModel } from '@local/Services/Types/apiTypes'
import { IUppsagningFormValues } from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/Uppsagning/Types'
import { initialUppsagningValues } from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/Uppsagning/UppsagningForm.schema'

const mapAnsokanToUppsagningFormValues = (
  ansokan: IAnsokanApiModel
): IUppsagningFormValues => {
  const { anstallningsuppgifter, foretag } = ansokan

  const reformattedSistaAnstallningsdatum =
    anstallningsuppgifter?.sistaAnstallningsdatum
      ? new Date(anstallningsuppgifter?.sistaAnstallningsdatum)
      : initialUppsagningValues.sistaAnstallningsdatum

  const formValues: IUppsagningFormValues = {
    ...initialUppsagningValues,
    sistaAnstallningsdatum: reformattedSistaAnstallningsdatum,
    foretagKontaktpersonEpostadress: foretag?.kontaktperson?.epostadress,
    foretagKontaktpersonNamn: foretag?.kontaktperson.namn,
  }
  return formValues
}

export { mapAnsokanToUppsagningFormValues }
