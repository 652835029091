import { IAnstallningTBFormValues } from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/AnstallningTB/Types'
import { IAnstallningTIAFormValues } from '@local/Views/SkapaAnsokan/TjanstemanIAnstallning/AnstallningTIA/Types'

export type AnstallningSharedFormValues =
  | IAnstallningTIAFormValues
  | IAnstallningTBFormValues

export enum AnstallningFormTypes {
  AnstallningBilagor = 'anstallningBilagor',
  Anstallningsdatum = 'anstallningsdatum',
  Manadslon = 'manadslon',
  Anstallningsgrad = 'anstallningsgrad',
  ArbetstimmarPerVecka = 'arbetstimmarPerVecka',
  Befattning = 'befattning',
  IsVdEllerUndantagsgrupp = 'isVdEllerUndantagsgrupp',
}
