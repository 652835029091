import { IArbetslivserfarenhetTBFormValues } from '@local/Views/SkapaAnsokan/TidsbegransadAnstallningOld/ArbetslivserfarenhetTB/Types'
import { IArbetslivserfarenhetTIAFormValues } from '@local/Views/SkapaAnsokan/TjanstemanIAnstallningOld/ArbetslivserfarenhetTIA/Types'

export interface IEtableringsvillkor {
  heading: string
  ja: string
  nej: string
  varning: string
}

export type ArbetslivserfarenhetSharedFormValues =
  | IArbetslivserfarenhetTIAFormValues
  | IArbetslivserfarenhetTBFormValues

export enum EtableringsvillkorFormTypes {
  HasIntygatEtableringsvillkor = 'hasIntygatEtableringsvillkor',
}
