import React, { JSX } from 'react'
import { Snackbar } from '@mui/material'
import { IToaster } from '@local/Components/Toaster/Types'
import Alert from '@local/Components/Alert'

const Toaster = ({ message, open, setOpen }: IToaster): JSX.Element => {
  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return
    }

    setOpen(false)
  }

  return (
    <Snackbar
      open={open}
      autoHideDuration={10000}
      onClose={handleClose}
      anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
    >
      <div>
        <Alert onClose={handleClose} severity="error">
          {message}
        </Alert>
      </div>
    </Snackbar>
  )
}

export default Toaster
