import { Provider } from 'react-redux'
import { store } from '@local/Store/configureStore'
import { createTheme, ThemeProvider } from '@mui/material'
import { defaultLocale, themeOptions } from '@trr/mui-theme'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { svSE } from '@mui/x-date-pickers/locales'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import { sv } from 'date-fns/locale'
import { AppShellDataProps, AppShellDataProvider } from '@trr/app-shell-data'
import { GTMTracker } from '@trr/gtm-tracking'
import Views from '@local/Views'

const App = ({ appShellData }: { appShellData: AppShellDataProps }) => {
  const theme = createTheme(themeOptions, defaultLocale, svSE)

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={sv}>
        <GTMTracker mfName="tjanstepersonansokan">
          <Provider store={store}>
            <AppShellDataProvider value={appShellData}>
              <Views appShellData={appShellData} />
            </AppShellDataProvider>
          </Provider>
        </GTMTracker>
      </LocalizationProvider>
    </ThemeProvider>
  )
}

export default App
