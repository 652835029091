import React, { useEffect } from 'react'
import { useGetAnsokanDraftState } from '@local/Services/API/hamtaAnsokanApi'
import { usePickEpiContent } from '@local/Utils/Hooks'
import OnboardingForm from '@local/Views/OnboardingWrapper/Onboarding/OnboardingForm'
import { ITjanstepersonAnsokanContent } from '@local/Types'
import { useCreateAnsokanMutation } from '@local/Services/API/skapaAnsokanApi'
import Toaster from '@local/Components/Toaster'
import { useAuthentication } from '@trr/app-shell-data'

const Onboarding = () => {
  const { sub } = useAuthentication()
  const { onboarding, nagotGickFel } =
    usePickEpiContent<ITjanstepersonAnsokanContent>()
  const { data, isError: isErrorGetAnsokanDraft } = useGetAnsokanDraftState(sub)
  const [, { isError: isErrorCreateAnsokan }] = useCreateAnsokanMutation({
    fixedCacheKey: 'shared-create-ansokan',
  })

  const [open, setOpen] = React.useState(false)

  useEffect(() => {
    if (isErrorCreateAnsokan) {
      setOpen(true)
    } else {
      setOpen(false)
    }
  }, [isErrorCreateAnsokan])

  return (
    <>
      <OnboardingForm
        content={onboarding}
        ansokanDraft={!isErrorGetAnsokanDraft ? data : undefined}
      />

      <Toaster message={nagotGickFel.heading} open={open} setOpen={setOpen} />
    </>
  )
}

export default Onboarding
