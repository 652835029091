import { RadioChoice } from '@local/Types/form.types'
import { IOnboardingFormValues } from '@local/Views/OnboardingWrapper/Onboarding/OnboardingForm/OnboardingForm.schema'
import { useFormikContext } from 'formik'

const useIsTIOTV = (): boolean => {
  const { values } = useFormikContext<IOnboardingFormValues>()
  return (
    values.iAnstallning === RadioChoice.No &&
    values.uppsagdTyp === RadioChoice.Yes
  )
}

export default useIsTIOTV
