import { usePickEpiContent, useGtmWizardTracker } from '@local/Utils/Hooks'
import { ITidsbegransadAnstallningContent } from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/TidsbegransadAnstallning.types'
import { useGetAnsokanQuery } from '@local/Services/API/hamtaAnsokanApi'
import AnstallningTBForm from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/AnstallningTB/AnstallningTBForm'
import Spinner from '@local/Components/Spinner'

const AnstallningTB = () => {
  const { anstallningTb } =
    usePickEpiContent<ITidsbegransadAnstallningContent>()
  useGtmWizardTracker('TB-Ansökan')

  const { data, isLoading } = useGetAnsokanQuery(undefined, {
    refetchOnMountOrArgChange: true,
  })

  if (isLoading) {
    return <Spinner centered />
  }

  return <AnstallningTBForm content={anstallningTb} ansokan={data} />
}

export default AnstallningTB
