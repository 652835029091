import { useCallback, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { equals } from 'ramda'
import { useAppDispatch } from '@local/Store/configureStore'
import { ITidsbegransadAnstallningFormValues } from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/TidsbegransadAnstallningForm/types'
import {
  resetState,
  selectTidsbegransadAnstallningForm,
  setFormIsDirty,
  setValues,
} from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/tidsbegransadAnstallningFormSlice'
import { tidsbegransadAnstallningStoreMiddleware } from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/TidsbegransadAnstallningForm/tidsbegransadAnstallningStoreMiddleware'

const useReduxStore = (
  initialValues: ITidsbegransadAnstallningFormValues,
  values: ITidsbegransadAnstallningFormValues
) => {
  const { formIsDirty, values: storedValues } = useSelector(
    selectTidsbegransadAnstallningForm
  )

  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(setValues(initialValues))
    return () => {
      dispatch(resetState())
    }
  }, [dispatch, initialValues])

  useEffect(() => {
    if (equals(storedValues, values)) {
      dispatch(setFormIsDirty(false))
    } else {
      dispatch(setFormIsDirty(true))
    }
  }, [storedValues, values, dispatch])

  const setStoredValuesAfterPatch = useCallback(
    (valuesToStore: Partial<ITidsbegransadAnstallningFormValues>) => {
      dispatch(
        setValues({
          ...storedValues,
          ...tidsbegransadAnstallningStoreMiddleware(valuesToStore),
        })
      )
    },
    [dispatch, storedValues]
  )

  return { setStoredValuesAfterPatch, storedValues, formIsDirty }
}

export default useReduxStore
