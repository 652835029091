import HTMLMapper from '@local/Components/HTMLMapper'
import { trrDateStrict } from '@local/Utils/Helpers/formatDate'
import Tooltip from '@local/Components/Tooltip'
import Gutter from '@local/Components/Gutter'
import { Typography, useTheme } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import { isNil } from 'ramda'
import { IDateInput } from '@local/Components/DateInput/Types'

const DateInput = ({
  formikContext,
  formikFieldName,
  tooltipContent,
  title,
  label,
  minDate,
  maxDate,
  desktopModeMediaQuery,
}: IDateInput) => {
  const theme = useTheme()
  const { errors, touched, values, setFieldValue, handleBlur } = formikContext

  const isError =
    touched?.[formikFieldName] && !isNil(errors?.[formikFieldName])

  const errorMessage = errors?.[formikFieldName] as string

  const handleOnChange = (formikFieldName: string, value: Date) => {
    setFieldValue(formikFieldName, value).catch((err) => {
      console.log(err)
    })
  }

  return (
    <>
      {tooltipContent ? (
        <Tooltip
          heading={title}
          tooltipContent={<HTMLMapper body={tooltipContent.mainBody} />}
        />
      ) : (
        <>
          <Typography variant="h6" gutterBottom>
            {title}
          </Typography>
          <Gutter xs={8} />
        </>
      )}

      <DatePicker
        onChange={(value) => {
          handleOnChange(formikFieldName, value)
        }}
        value={trrDateStrict(values[formikFieldName] as Date)}
        label={label}
        minDate={minDate ?? new Date(null)}
        maxDate={maxDate}
        openTo="day"
        views={['year', 'day']}
        slotProps={{
          field: { clearable: true },
          textField: {
            error: isError,
            helperText: isError ? errorMessage : null,
            sx: { width: '100%' },
            name: formikFieldName,
            onBlur: handleBlur,
          },
          layout: {
            sx: {
              display: 'block',
            },
          },
        }}
        desktopModeMediaQuery={
          desktopModeMediaQuery ?? theme.breakpoints.up('md')
        }
      />
    </>
  )
}

export default DateInput
