import { usePatchAnsokanMutation } from '@local/Services/API/skapaAnsokanApi'
import useFormikWatcherCallback from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/TidsbegransadAnstallningForm/hooks/useFormikWatcherCallback'
import { ITidsbegransadAnstallningFormValues } from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/TidsbegransadAnstallningForm/types'
import { isNil } from 'ramda'
import { useCallback, useEffect } from 'react'

const usePatchAnsokan = (
  setStoredValuesAfterPatch: (
    values: Partial<ITidsbegransadAnstallningFormValues>
  ) => void
) => {
  const { requestedUpdates, setRequestedUpdates, formikWatcherCallback } =
    useFormikWatcherCallback()

  const [patchAnsokan, { isError, isLoading: isLoadingPatch }] =
    usePatchAnsokanMutation({ fixedCacheKey: 'patchAnsokan' })

  const patchAnsokanCallback = useCallback(async () => {
    const requestedUpdatesRef = requestedUpdates
    setRequestedUpdates({ updatesToPatch: null, updatesToStore: null })

    await patchAnsokan({
      values: requestedUpdatesRef.updatesToPatch,
      isMuiStepperEnabled: true,
    })
      .unwrap()
      .then(() => {
        setStoredValuesAfterPatch(requestedUpdatesRef.updatesToStore)
      })
      .catch((error) => {
        console.log(error)
      })
  }, [
    patchAnsokan,
    requestedUpdates,
    setStoredValuesAfterPatch,
    setRequestedUpdates,
  ])

  useEffect(() => {
    if (
      !isLoadingPatch &&
      !isNil(requestedUpdates.updatesToPatch) &&
      !isNil(requestedUpdates.updatesToStore)
    ) {
      void patchAnsokanCallback()
    }
  }, [isLoadingPatch, requestedUpdates, patchAnsokanCallback])

  return { isError, patchAnsokan: formikWatcherCallback }
}

export default usePatchAnsokan
