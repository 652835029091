import Gutter from '@local/Components/Gutter'
import { useGetAllIntygBilagaQuery } from '@local/Services/API/bilagorApi'
import { useGetAnsokanQuery } from '@local/Services/API/hamtaAnsokanApi'
import { formatCurrencySEK, formatPercentageNumber } from '@local/Utils/Helpers'
import { trrFormat } from '@local/Utils/Helpers/formatDate'
import { usePickEpiContent, useGtmWizardTracker } from '@local/Utils/Hooks'
import GranskaAnsokan from '@local/Views/SkapaAnsokan/Components/GranskaAnsokan'
import { ISammanfattningSektionProps } from '@local/Views/SkapaAnsokan/Components/GranskaAnsokan/Components/SammanfattningSektion'
import { ITidsbegransadAnstallningContent } from '@local/Views/SkapaAnsokan/TidsbegransadAnstallningOld/TidsbegransadAnstallning.types'
import { Chip, Typography } from '@mui/material'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import Spinner from '@local/Components/Spinner'
import ChipEllipsis from '@local/Components/ChipEllipsis'
import { useAuthentication } from '@trr/app-shell-data'

const GranskaAnsokanTB = () => {
  useGtmWizardTracker('TB-Ansökan')

  const {
    data: {
      anstallningsuppgifter: anstallningsUppgifter,
      foretag,
      loneuppgifter: loneUppgifter,
      hasIntygatEtableringsvillkor,
    },
  } = useGetAnsokanQuery(undefined, {
    refetchOnMountOrArgChange: true,
  })

  const { sub } = useAuthentication()

  const { data: bilagorData, isLoading: isLoadingBilagor } =
    useGetAllIntygBilagaQuery(sub)

  const {
    granskaAnsokan: {
      omDinArbetsplats,
      omDinAnstallning,
      omDinUppsagning,
      omDinLonOchErsattning,
      omDinArbetslivserfarenhet,
    },
  } = usePickEpiContent<ITidsbegransadAnstallningContent>()

  const arbetsplatsSektion: ISammanfattningSektionProps = {
    title: omDinArbetsplats.titel,
    undersektioner: [
      {
        title: omDinArbetsplats.arbetsgivare,
        body: foretag.namn,
      },
      {
        title: omDinArbetsplats.agandedel,
        body:
          anstallningsUppgifter.agarandel > 0
            ? omDinArbetsplats.ja
            : omDinArbetsplats.nej,
      },
      {
        title: omDinArbetsplats.agarandelar,
        body: formatPercentageNumber(anstallningsUppgifter.agarandel),
      },
    ],
  }

  const anstallningSektion: ISammanfattningSektionProps = {
    title: omDinAnstallning.titel,
    undersektioner: [
      {
        title: omDinAnstallning.befattning,
        body: anstallningsUppgifter.befattning,
      },
      {
        title: omDinAnstallning.vd,
        body: anstallningsUppgifter.isVdEllerUndantagsgrupp
          ? omDinAnstallning.ja
          : omDinAnstallning.nej,
      },
      {
        title: omDinAnstallning.anstallningsdatum,
        body: trrFormat(anstallningsUppgifter.anstallningsdatum),
      },
      {
        title: omDinAnstallning.arbetstimmar,
        body: anstallningsUppgifter.arbetstimmarPerVecka.toString(),
      },
      {
        title: omDinAnstallning.bifogadeFiler,
        body: (
          <>
            <Gutter xs={8} />
            {bilagorData?.map((bilaga) => (
              <div key={bilaga.fileId}>
                <ChipEllipsis>
                  <Chip
                    icon={<AttachFileIcon />}
                    label={bilaga.name}
                    variant="outlined"
                  />
                </ChipEllipsis>
                <Gutter xs={8} />
              </div>
            ))}
          </>
        ),
      },
    ],
  }

  const uppsagningSektion: ISammanfattningSektionProps = {
    title: omDinUppsagning.titel,
    undersektioner: [
      {
        title: omDinUppsagning.sistaAnstallningsdag,
        body: trrFormat(anstallningsUppgifter.sistaAnstallningsdatum),
      },
      {
        title: omDinUppsagning.omfattning,
        body: formatPercentageNumber(anstallningsUppgifter.anstallningsgrad),
      },
      {
        title: omDinUppsagning.kontaktperson,
        body: (
          <>
            <Typography variant="body1" gutterBottom>
              {foretag.kontaktperson.namn}
            </Typography>

            <Typography variant="body1">
              {foretag.kontaktperson.epostadress}
            </Typography>
          </>
        ),
      },
    ],
  }

  const lonOchErsattningSektion: ISammanfattningSektionProps = {
    title: omDinLonOchErsattning.titel,
    undersektioner: [
      {
        title: omDinLonOchErsattning.manadslon,
        body: formatCurrencySEK(loneUppgifter.manadslon),
      },
      {
        title: omDinLonOchErsattning.provision,
        body: formatCurrencySEK(loneUppgifter.provisionBelopp),
      },
      {
        title: omDinLonOchErsattning.jour,
        body: formatCurrencySEK(loneUppgifter.jourBelopp),
      },
      {
        title: omDinLonOchErsattning.sjukersattning,
        body: loneUppgifter.sjukersattningsgrad
          ? formatPercentageNumber(loneUppgifter.sjukersattningsgrad)
          : omDinLonOchErsattning.saknarSjukersattning,
      },
    ],
  }

  const arbetslivserfarenhetSektion: ISammanfattningSektionProps = {
    title: omDinArbetslivserfarenhet.heading,
    undersektioner: [
      {
        title: omDinArbetslivserfarenhet.etableringsvillkor,
        body: hasIntygatEtableringsvillkor
          ? omDinArbetslivserfarenhet.ja
          : omDinArbetslivserfarenhet.nej,
      },
    ],
  }
  const sammanfattning = [
    arbetsplatsSektion,
    anstallningSektion,
    uppsagningSektion,
    lonOchErsattningSektion,
  ]

  if (isLoadingBilagor) {
    return <Spinner centered />
  }

  return (
    <GranskaAnsokan
      sammanfattningSektioner={[...sammanfattning, arbetslivserfarenhetSektion]}
    />
  )
}

export default GranskaAnsokanTB
