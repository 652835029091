import { useGetAllIntygBilagaQuery } from '@local/Services/API/bilagorApi'
import { useGetAnsokanQuery } from '@local/Services/API/hamtaAnsokanApi'
import { formatCurrencySEK, formatPercentageNumber } from '@local/Utils/Helpers'
import { trrFormat } from '@local/Utils/Helpers/formatDate'
import { usePickEpiContent, useGtmWizardTracker } from '@local/Utils/Hooks'
import GranskaAnsokan from '@local/Views/SkapaAnsokan/Components/GranskaAnsokan'
import { ISammanfattningSektionProps } from '@local/Views/SkapaAnsokan/Components/GranskaAnsokan/Components/SammanfattningSektion'
import { ITjanstemanIAnstallningContent } from '@local/Views/SkapaAnsokan/TjanstemanIAnstallning/TjanstemanIAnstallning.types'
import { Chip } from '@mui/material'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import Gutter from '@local/Components/Gutter'
import Spinner from '@local/Components/Spinner'
import ChipEllipsis from '@local/Components/ChipEllipsis'
import { useAuthentication } from '@trr/app-shell-data'

const GranskaAnsokanTIA = () => {
  useGtmWizardTracker('TIA-Ansökan')

  const {
    data: {
      anstallningsuppgifter,
      loneuppgifter,
      foretag,
      hasIntygatEtableringsvillkor,
    },
  } = useGetAnsokanQuery(undefined, {
    refetchOnMountOrArgChange: true,
  })

  const { sub } = useAuthentication()

  const { data: bilagorData, isLoading: isLoadingBilagor } =
    useGetAllIntygBilagaQuery(sub)

  const {
    granskaAnsokan: {
      omDinArbetsplats,
      omDinAnstallning,
      omDinArbetslivserfarenhet,
    },
    arbetslivserfarenhet: { arbetstimmar },
  } = usePickEpiContent<ITjanstemanIAnstallningContent>()

  const arbetsplatsSektion: ISammanfattningSektionProps = {
    title: omDinArbetsplats.titel,
    undersektioner: [
      {
        title: omDinArbetsplats.arbetsgivare,
        body: foretag.namn,
      },
      {
        title: omDinArbetsplats.agandedel,
        body:
          anstallningsuppgifter.agarandel > 0
            ? omDinArbetsplats.ja
            : omDinArbetsplats.nej,
      },
      {
        title: omDinArbetsplats.agarandelar,
        body: formatPercentageNumber(anstallningsuppgifter.agarandel),
      },
    ],
  }

  const anstallningSektion: ISammanfattningSektionProps = {
    title: omDinAnstallning.titel,
    undersektioner: [
      {
        title: omDinAnstallning.befattning,
        body: anstallningsuppgifter.befattning,
      },
      {
        title: omDinAnstallning.vd,
        body: anstallningsuppgifter.isVdEllerUndantagsgrupp
          ? omDinAnstallning.ja
          : omDinAnstallning.nej,
      },
      {
        title: omDinAnstallning.anstallningsdatum,
        body: trrFormat(anstallningsuppgifter.anstallningsdatum),
      },
      {
        title: omDinAnstallning.manadslon,
        body: formatCurrencySEK(loneuppgifter.manadslon),
      },
      {
        title: omDinAnstallning.bifogadeFiler,
        body: (
          <>
            <Gutter xs={8} />
            {bilagorData?.map((bilaga) => (
              <div key={bilaga.fileId}>
                <ChipEllipsis>
                  <Chip
                    icon={<AttachFileIcon />}
                    label={bilaga.name}
                    variant="outlined"
                  />
                </ChipEllipsis>
                <Gutter xs={8} />
              </div>
            ))}
          </>
        ),
      },
    ],
  }

  const arbetslivserfarenhetSektion: ISammanfattningSektionProps = {
    title: omDinArbetslivserfarenhet.heading,
    undersektioner: [
      {
        title: omDinArbetslivserfarenhet.arbetstimmar,
        body:
          anstallningsuppgifter.arbetstimmarPerVecka > 15
            ? arbetstimmar.ja
            : arbetstimmar.nej,
      },
      {
        title: omDinArbetslivserfarenhet.etableringsvillkor,
        body: hasIntygatEtableringsvillkor
          ? omDinArbetslivserfarenhet.ja
          : omDinArbetslivserfarenhet.nej,
      },
    ],
  }

  if (isLoadingBilagor) {
    return <Spinner centered />
  }

  const sammanfattning = [
    arbetsplatsSektion,
    anstallningSektion,
    arbetslivserfarenhetSektion,
  ]
  return <GranskaAnsokan sammanfattningSektioner={sammanfattning} />
}

export default GranskaAnsokanTIA
