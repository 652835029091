import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IUniqueFile } from '@local/Components/FileUpload/FileUpload.types'
import { RootState } from '@local/Store/configureStore'
import { ITidsbegransadAnstallningFormValues } from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/TidsbegransadAnstallningForm/types'

export interface ISkapaAnsokanState {
  values: ITidsbegransadAnstallningFormValues
  formIsDirty: boolean
}

const initialState: ISkapaAnsokanState = {
  values: null,
  formIsDirty: false,
}

export const tidsbegransadAnstallningFormSlice = createSlice({
  name: 'tidsbegransadAnstallningForm',
  initialState,
  reducers: {
    setValues: (
      state,
      action: PayloadAction<ITidsbegransadAnstallningFormValues>
    ) => {
      state.values = action.payload
    },
    setAnstallningBilagor: (state, action: PayloadAction<IUniqueFile[]>) => {
      if (state.values) {
        state.values.anstallningBilagor = action.payload
      }
    },
    setFormIsDirty: (state, action: PayloadAction<boolean>) => {
      state.formIsDirty = action.payload
    },
    resetState: () => initialState,
  },
})

export const { setValues, setAnstallningBilagor, setFormIsDirty, resetState } =
  tidsbegransadAnstallningFormSlice.actions

export const selectTidsbegransadAnstallningForm = (state: RootState) =>
  state.tidsbegransadAnstallningForm

export default tidsbegransadAnstallningFormSlice.reducer
