import * as Yup from 'yup'
import { RadioChoice } from '@local/Types/form.types'
import { countDecimals } from '@local/Utils/Helpers/Forms/form.helpers'
import { ILonOchErsattningFormValues } from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/LonOchErsattning/Types'
import { AnsokanKeys, ICommonAnsokanValidering } from '@local/Types'

export const lonOchErsattningValidationSchema = (
  validering: ICommonAnsokanValidering
): Yup.ObjectSchema<ILonOchErsattningFormValues> =>
  Yup.object().shape({
    [AnsokanKeys.Manadslon]: Yup.number()
      .required(validering.manadslonIsRequired)
      .typeError(validering.manadslonIsRequired)
      .integer(validering.manadslonInvalidFormat)
      .positive(validering.manadslonMinAmountRequired)
      .max(999999, validering.manadslonMaxAmountExceeded),
    [AnsokanKeys.Provision]: Yup.mixed<RadioChoice>().required(
      validering.harProvisionIsRequired
    ),
    [AnsokanKeys.ProvisionBelopp]: Yup.number()
      .nullable()
      .when([AnsokanKeys.Provision], ([provision]: [RadioChoice], schema) => {
        if (provision === RadioChoice.Yes) {
          return schema
            .required(validering.provisionBeloppIsRequired)
            .typeError(validering.provisionBeloppIsRequired)
            .positive(validering.provisionBeloppMinAmountRequired)
            .test(
              'len',
              validering.provisionBeloppInvalidFormat,
              (value: number) => countDecimals(value) <= 2
            )
            .max(999999, validering.provisionBeloppMaxAmountExceeded)
        }
      }),
    [AnsokanKeys.Jour]: Yup.mixed<RadioChoice>().required(
      validering.harJourIsRequired
    ),
    [AnsokanKeys.JourBelopp]: Yup.number()
      .nullable()
      .when([AnsokanKeys.Jour], ([jour]: [RadioChoice], schema) => {
        if (jour === RadioChoice.Yes) {
          return schema
            .required(validering.jourBeloppIsRequired)
            .typeError(validering.jourBeloppIsRequired)
            .positive(validering.jourBeloppMinAmountRequired)
            .test(
              'len',
              validering.jourBeloppInvalidFormat,
              (value: number) => countDecimals(value) <= 2
            )
            .max(999999, validering.jourBeloppMaxAmountExceeded)
        }
      }),
    [AnsokanKeys.SjukersattningsgradChoice]: Yup.mixed<RadioChoice>().required(
      validering.harSjukersattningsgradIsRequired
    ),
    [AnsokanKeys.Sjukersattningsgrad]: Yup.number()
      .nullable()
      .when(
        [AnsokanKeys.SjukersattningsgradChoice],
        ([sjukersattningsgradChoice]: [RadioChoice], schema) => {
          if (sjukersattningsgradChoice === RadioChoice.Yes) {
            return schema
              .required(validering.sjukersattningsgradBeloppIsRequired)
              .typeError(validering.sjukersattningsgradBeloppIsRequired)
              .positive(validering.sjukersattningsgradBeloppMinAmountRequired)
              .max(100, validering.sjukersattningsgradBeloppMaxAmountExceeded)
              .integer(validering.sjukersattningsgradBeloppInvalidFormat)
          }
        }
      ),
  })

export const initialLonOchErsattningValues: ILonOchErsattningFormValues = {
  manadslon: null,
  provision: null,
  provisionBelopp: null,
  jour: null,
  jourBelopp: null,
  sjukersattningsgradChoice: null,
  sjukersattningsgrad: null,
}
