import React, { JSX } from 'react'
import { TextField } from '@mui/material'
import { isNil } from 'ramda'
import { handleChangeWithTrim } from '@local/Utils/Helpers/Forms/form.helpers'
import { ITextInput } from '@local/Components/TextInput/Types'

const TextInput = ({
  withTrim = false,
  formikContext,
  label,
  name,
  inputMode,
  maxLength,
}: ITextInput): JSX.Element => {
  const { errors, touched, values, handleChange, handleBlur } = formikContext
  const isError = touched?.[name] && !isNil(errors?.[name])

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleChangeWithTrim(e, handleChange)
  }
  return (
    <TextField
      fullWidth
      inputProps={{
        inputMode: inputMode,
        autoComplete: inputMode === 'email' ? 'email' : null,
        maxLength: maxLength,
      }}
      error={isError}
      helperText={isError ? (errors?.[name] as string) : null}
      defaultValue={values?.[name] as string}
      label={label}
      onChange={withTrim ? handleInputChange : handleChange}
      name={name}
      onBlur={handleBlur}
    />
  )
}

export default TextInput
