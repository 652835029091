import { IAnsokanApiModel } from '@local/Services/Types/apiTypes'
import { AnsokanKeys } from '@local/Types'
import { RadioChoice } from '@local/Types/form.types'
import { initialLonOchErsattningValues } from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/LonOchErsattning/LonOchErsattning.schema'
import { ILonOchErsattningFormValues } from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/LonOchErsattning/Types'
import { ITidsbegransadAnstallningFormValues } from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/TidsbegransadAnstallningForm/types'

const mapAnsokanToLonOchErsattningFormValues = (
  ansokan: IAnsokanApiModel
): ILonOchErsattningFormValues => {
  let provision: RadioChoice = null
  let jour: RadioChoice = null
  let sjukersattningsgradChoice: RadioChoice = null

  if (ansokan.loneuppgifter?.provisionBelopp !== null) {
    provision =
      ansokan.loneuppgifter?.provisionBelopp > 0
        ? RadioChoice.Yes
        : RadioChoice.No
  }

  if (ansokan.loneuppgifter?.jourBelopp !== null) {
    jour =
      ansokan.loneuppgifter?.jourBelopp > 0 ? RadioChoice.Yes : RadioChoice.No
  }

  if (ansokan.loneuppgifter?.sjukersattningsgrad !== null) {
    sjukersattningsgradChoice =
      ansokan.loneuppgifter?.sjukersattningsgrad > 0
        ? RadioChoice.Yes
        : RadioChoice.No
  }

  return {
    ...initialLonOchErsattningValues,
    manadslon: ansokan.loneuppgifter?.manadslon,
    provision: provision,
    provisionBelopp: ansokan.loneuppgifter?.provisionBelopp,
    jour: jour,
    jourBelopp: ansokan.loneuppgifter?.jourBelopp,
    sjukersattningsgradChoice: sjukersattningsgradChoice,

    sjukersattningsgrad: ansokan.loneuppgifter?.sjukersattningsgrad,
  }
}

const mapLonOchAnsokanRadioChoiceToPatch = (
  name: keyof ITidsbegransadAnstallningFormValues
): keyof ITidsbegransadAnstallningFormValues => {
  switch (name) {
    case AnsokanKeys.Provision:
      return AnsokanKeys.ProvisionBelopp
    case AnsokanKeys.Jour:
      return AnsokanKeys.JourBelopp
    case AnsokanKeys.SjukersattningsgradChoice:
      return AnsokanKeys.Sjukersattningsgrad
    default:
      return null
  }
}

export {
  mapAnsokanToLonOchErsattningFormValues,
  mapLonOchAnsokanRadioChoiceToPatch,
}
