import { useGetAnsokanDraftState } from '@local/Services/API/hamtaAnsokanApi'
import { ITjanstepersonAnsokanContent } from '@local/Types'
import usePickEpiContent from '@local/Utils/Hooks/usePickEpiContent'
import { TypeOfAnsokan } from '@local/Views/OnboardingWrapper/Onboarding/Types'
import { useAuthentication } from '@trr/app-shell-data'

const useGetAnsokanDraftRoute = (): string => {
  const { sub } = useAuthentication()

  const { tjanstepersonansokanLinks } =
    usePickEpiContent<ITjanstepersonAnsokanContent>()

  const { data: ansokanDraft } = useGetAnsokanDraftState(sub)

  return ansokanDraft?.type === TypeOfAnsokan.Anstalld
    ? tjanstepersonansokanLinks.tjanstemanIAnstallningUrl
    : tjanstepersonansokanLinks.tidsbegransadAnstallningUrl
}

export default useGetAnsokanDraftRoute
