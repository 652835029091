import { JSX, useEffect } from 'react'
import { useFormikContext } from 'formik'
import {
  ILonOchErsattningFormValues,
  LonOchErsattningFormTypes,
} from '@local/Views/SkapaAnsokan/TidsbegransadAnstallningOld/LonOchErsattning/Types'
import { usePickEpiContent } from '@local/Utils/Hooks'
import Gutter from '@local/Components/Gutter'
import { RadioChoice } from '@local/Types/form.types'
import { ITidsbegransadAnstallningContent } from '@local/Views/SkapaAnsokan/TidsbegransadAnstallningOld/TidsbegransadAnstallning.types'
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material'
import { isNil } from 'ramda'
import FormErrorMessage from '@local/Components/FormErrorMessage'
import NumberInput from '@local/Components/NumberInput'

const Provision = (): JSX.Element => {
  const {
    lonOchErsattning: { provision, provisionBelopp },
  } = usePickEpiContent<ITidsbegransadAnstallningContent>()

  const formikContext = useFormikContext<ILonOchErsattningFormValues>()
  const {
    handleChange,
    handleBlur,
    errors,
    touched,
    values,
    setFieldValue,
    setFieldTouched,
  } = formikContext

  useEffect(() => {
    if (values.provision === RadioChoice.No) {
      setFieldValue(LonOchErsattningFormTypes.ProvisionBelopp, null)?.catch(
        (err) => {
          console.log(err)
        }
      )
      setFieldTouched(LonOchErsattningFormTypes.ProvisionBelopp, false)?.catch(
        (err) => {
          console.log(err)
        }
      )
    }
  }, [values.provision, setFieldValue, setFieldTouched])

  const isError = touched.provision && !isNil(errors.provision)

  return (
    <FormControl error={isError} variant="standard" fullWidth>
      <Typography variant="h6">{provision.titel}</Typography>

      <Gutter xs={16} />

      <RadioGroup
        onChange={handleChange}
        onBlur={handleBlur}
        defaultValue={values.provision}
      >
        <FormControlLabel
          value={RadioChoice.Yes}
          control={<Radio color="primary" />}
          label={provision.ja}
          name={LonOchErsattningFormTypes.Provision}
          id={`${LonOchErsattningFormTypes.Provision}.ja`}
        />
        <FormControlLabel
          value={RadioChoice.No}
          control={<Radio color="primary" />}
          label={provision.nej}
          name={LonOchErsattningFormTypes.Provision}
          id={`${LonOchErsattningFormTypes.Provision}.nej`}
        />
      </RadioGroup>

      {isError && <FormErrorMessage>{errors.provision}</FormErrorMessage>}

      {values.provision === RadioChoice.Yes && (
        <>
          <Gutter xs={32} />

          <Typography variant="h6">{provisionBelopp.titel}</Typography>

          <Gutter xs={16} />

          <NumberInput
            formikContext={formikContext}
            label={provisionBelopp.textFaltTitel}
            name={LonOchErsattningFormTypes.ProvisionBelopp}
          />
        </>
      )}
    </FormControl>
  )
}

export default Provision
