import { usePickEpiContent } from '@local/Utils/Hooks'
import { GranskaAnsokanProps } from '@local/Views/SkapaAnsokan/Components/GranskaAnsokan/Types/content'
import { SkapaAnsokanSharedContent } from '@local/Types'
import { useGetAnsokanQuery } from '@local/Services/API/hamtaAnsokanApi'
import GranskaAnsokanForm from '@local/Views/SkapaAnsokan/Components/GranskaAnsokan/GranskaAnsokanForm'
import Spinner from '@local/Components/Spinner'

const GranskaAnsokan = ({ sammanfattningSektioner }: GranskaAnsokanProps) => {
  const { granskaAnsokan } = usePickEpiContent<SkapaAnsokanSharedContent>()
  const { isLoading } = useGetAnsokanQuery(undefined, {
    refetchOnMountOrArgChange: true,
  })

  if (isLoading) {
    return <Spinner centered />
  }

  return (
    <GranskaAnsokanForm
      content={granskaAnsokan}
      sammanfattningSektioner={sammanfattningSektioner}
    />
  )
}

export default GranskaAnsokan
