import { useFormikContext } from 'formik'
import HTMLMapper from '@local/Components/HTMLMapper'
import NumberInput from '@local/Components/NumberInput'
import Tooltip from '@local/Components/Tooltip'
import { usePickEpiContent } from '@local/Utils/Hooks'
import { ITidsbegransadAnstallningContent } from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/TidsbegransadAnstallning.types'
import { ITidsbegransadAnstallningFormValues } from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/TidsbegransadAnstallningForm/types'
import { AnsokanKeys } from '@local/Types'

const ArbetstimmarPerVecka = () => {
  const formikContext = useFormikContext<ITidsbegransadAnstallningFormValues>()

  const {
    anstallningTb: { arbetstimmarPerVecka, tooltiparbetstimmarPerVecka },
  } = usePickEpiContent<ITidsbegransadAnstallningContent>()

  return (
    <>
      <Tooltip
        heading={arbetstimmarPerVecka.titel}
        tooltipContent={
          <HTMLMapper body={tooltiparbetstimmarPerVecka.mainBody} />
        }
      />

      <NumberInput
        formikContext={formikContext}
        label={arbetstimmarPerVecka.textFaltTitel}
        name={AnsokanKeys.ArbetstimmarPerVecka}
      />
    </>
  )
}

export default ArbetstimmarPerVecka
