import { format, toZonedTime, fromZonedTime } from 'date-fns-tz'
import { sv } from 'date-fns/locale'

type trrDateType = (date?: Date | string | number) => Date

type baseTrrFormatType = (formatStr: string) => (date?: Date | string) => string
type DateFormatType = (date?: Date | number) => string

const stockholmTimeZone = 'Europe/Stockholm'

export const trrDate: trrDateType = (date) =>
  toZonedTime(date || new Date(), stockholmTimeZone)

export const trrDateStrict: trrDateType = (date) =>
  date ? toZonedTime(date, stockholmTimeZone) : null

export const trrDateUTC: trrDateType = (date) =>
  fromZonedTime(date, stockholmTimeZone)

export const baseTrrFormat: baseTrrFormatType =
  (formatStr: string) =>
  (date: Date | string = new Date()) =>
    format(trrDate(date), formatStr, {
      timeZone: stockholmTimeZone,
      locale: sv,
    })

export const getDate: DateFormatType = (date: Date | number = new Date()) =>
  format(date, 'yyyy-MM-dd')

export const trrFormat = baseTrrFormat('yyyy-MM-dd')
export const trrFormatOccasionDate = baseTrrFormat('d MMM yyyy')
export const trrFormatTime = baseTrrFormat('H:mm')
export const trrFormatMeetingDateSummary = baseTrrFormat('EEEE d MMMM yyyy')
export const trrFormatMeetingTimeSummary = baseTrrFormat('HH:mm')

export const isoDateToUnixTimestamp = (date: string): number => {
  const dateObj = new Date(date)
  return dateObj.getTime()
}
