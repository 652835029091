import { useCallback } from 'react'
import { mapAnsokanToUppsagningFormValues } from '@local/Views/SkapaAnsokan/TidsbegransadAnstallningOld/Uppsagning/Helpers'
import {
  IUppsagningFormProps,
  IUppsagningContent,
} from '@local/Views/SkapaAnsokan/TidsbegransadAnstallningOld/Uppsagning/Types/content'
import { uppsagningValidationSchema } from '@local/Views/SkapaAnsokan/TidsbegransadAnstallningOld/Uppsagning/UppsagningForm.schema'
import Gutter from '@local/Components/Gutter'
import { usePatchAnsokanMutation } from '@local/Services/API/skapaAnsokanApi'
import { usePickEpiContent, useWizardNextStep } from '@local/Utils/Hooks'
import useFormikWatch from '@local/Utils/Hooks/useFormikWatch'
import Kontaktpersoner from '@local/Views/SkapaAnsokan/TidsbegransadAnstallningOld/Uppsagning/Components/Kontaktpersoner'
import {
  IUppsagningFormValues,
  IUppsagningFormValuesApiModel,
  UppsagningFormTypes,
} from '@local/Views/SkapaAnsokan/TidsbegransadAnstallningOld/Uppsagning/Types'
import { uppsagningMiddleware } from '@local/Views/SkapaAnsokan/TidsbegransadAnstallningOld/Uppsagning/uppsagningMiddleware'
import { useWizard, WizardStep } from '@trr/wizard-library'
import {
  useFormikContext,
  setNestedObjectValues,
  FormikTouched,
  withFormik,
} from 'formik'
import { isEmpty } from 'ramda'
import { useAppDispatch } from '@local/Store/configureStore'
import { setShouldGoToNextStep } from '@local/Utils/Hooks/formikWatchSlice'
import { ITidsbegransadAnstallningContent } from '@local/Views/SkapaAnsokan/TidsbegransadAnstallningOld/TidsbegransadAnstallning.types'
import DateInput from '@local/Components/DateInput'
import { Grid2 as Grid } from '@mui/material'
import { yesterday } from '@local/Utils/Helpers/Datetime.helpers'

const UppsagningForm = ({
  sistaAnstallningsdatumMinDate,
}: {
  sistaAnstallningsdatumMinDate: Date
}) => {
  const dispatch = useAppDispatch()
  const { stepCount, activeStep } = useWizard()
  const [patchAnsokan] = usePatchAnsokanMutation({
    fixedCacheKey: 'patchAnsokan',
  })

  const formikContext = useFormikContext<IUppsagningFormValues>()
  const { validateForm, setTouched } = formikContext

  const {
    uppsagning: {
      stegGuide,
      sistaAnstallningsdatum,
      tooltipSistaAnstallningsdatum,
    },
  } = usePickEpiContent<ITidsbegransadAnstallningContent>()

  const patchAnsokanCallback = useCallback(
    async (values: Partial<IUppsagningFormValuesApiModel>) => {
      await patchAnsokan({ values })
    },
    [patchAnsokan]
  )

  useFormikWatch(uppsagningMiddleware, patchAnsokanCallback)
  useWizardNextStep()

  const handleNext = () => {
    validateForm()
      .then((validationErrors) => {
        if (!isEmpty(validationErrors)) {
          setTouched(
            setNestedObjectValues<FormikTouched<IUppsagningFormValues>>(
              validationErrors,
              true
            )
          ).catch((err) => {
            console.log(err)
          })
        } else {
          dispatch(setShouldGoToNextStep(true))
        }
      })
      .catch((e) => {
        console.error(e)
      })
  }

  return (
    <Grid container>
      <Grid size={{ lg: 8 }} sx={{ width: 1 }}>
        <WizardStep
          progressBar={{ max: stepCount - 1, current: activeStep + 1 }}
          stepInfo={{
            currentStepLabel: stegGuide.nuvarandeSteg,
            nextStepLabel: stegGuide.nastaSteg,
          }}
          nextButton={{ onClick: handleNext }}
          prevButton={{}}
        >
          <DateInput
            formikFieldName={UppsagningFormTypes.SistaAnstallningsdatum}
            formikContext={formikContext}
            label={sistaAnstallningsdatum.textFaltTitel}
            tooltipContent={tooltipSistaAnstallningsdatum}
            maxDate={yesterday}
            minDate={sistaAnstallningsdatumMinDate}
            title={sistaAnstallningsdatum.titel}
          />

          <Gutter xs={32} />

          <Kontaktpersoner />
        </WizardStep>
      </Grid>
    </Grid>
  )
}

const UppsagningFormik = withFormik<
  IUppsagningFormProps,
  IUppsagningFormValues
>({
  validateOnBlur: true,
  enableReinitialize: true,
  mapPropsToValues: ({ ansokan }) => mapAnsokanToUppsagningFormValues(ansokan),
  handleSubmit: (_, { setSubmitting }) => {
    setSubmitting(false)
  },
  validationSchema: ({
    content,
    sistaAnstallningsdatumMinDate,
  }: {
    content: IUppsagningContent
    sistaAnstallningsdatumMinDate: Date
  }) => uppsagningValidationSchema(content, sistaAnstallningsdatumMinDate),
  displayName: 'UppsagningForm',
})(UppsagningForm)

export default UppsagningFormik
