import { useTrackVirtualView } from '@trr/gtm-tracking'
import { isNil } from 'ramda'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { selectTjanstemanIAnstallningStepper } from '@local/Views/SkapaAnsokan/TjanstemanIAnstallning/tjanstemanIAnstallningStepperSlice'

const TjanstemanIAnstallningTracker = () => {
  const stepTrigger = useTrackVirtualView('TIA-ansökan')
  const { activeStep } = useSelector(selectTjanstemanIAnstallningStepper)

  useEffect(() => {
    if (!isNil(activeStep)) {
      stepTrigger(`Step: ${String(activeStep)}`)
    }
  }, [stepTrigger, activeStep])

  return <></>
}

export default TjanstemanIAnstallningTracker
