import { useGetAnsokanState } from '@local/Services/API/hamtaAnsokanApi'
import { octoberStart } from '@local/Utils/Helpers/Datetime.helpers'
import { trrDate } from '@local/Utils/Helpers/formatDate'
import { isBefore, isValid } from 'date-fns'

const useGetSistaAnstallningsdatumMinDate = () => {
  const {
    data: {
      anstallningsuppgifter: { anstallningsdatum },
    },
  } = useGetAnsokanState()

  const anstallningsdatumDate = trrDate(anstallningsdatum)

  if (isValid(anstallningsdatumDate)) {
    const minDate = isBefore(anstallningsdatumDate, octoberStart)
      ? octoberStart
      : anstallningsdatumDate

    return minDate
  } else {
    return octoberStart
  }
}

export default useGetSistaAnstallningsdatumMinDate
