import { ISteps } from '@local/Components/Stepper/Stepper.types'
import { RootState } from '@local/Store/configureStore'
import { TjanstemanIAnstallningStep } from '@local/Views/SkapaAnsokan/TjanstemanIAnstallning/TjanstemanIAnstallning.types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface ITjanstemanIAnstallningStepperState {
  steps: ISteps[]
  activeStep: TjanstemanIAnstallningStep
}

const initialState: ITjanstemanIAnstallningStepperState = {
  steps: [],
  activeStep: null,
}

export const tjanstemanIAnstallningStepperSlice = createSlice({
  name: 'tjanstemanIAnstallningStepper',
  initialState,
  reducers: {
    initStepper: (
      state,
      action: PayloadAction<Partial<ITjanstemanIAnstallningStepperState>>
    ) => {
      state.steps = action.payload.steps
      state.activeStep = action.payload.activeStep
    },
    setActiveStep: (state, action: PayloadAction<number>) => {
      state.activeStep = action.payload
    },
    goToNextStep: (state) => {
      state.activeStep =
        (state.activeStep as number) < state.steps.length - 1
          ? state.activeStep + 1
          : state.activeStep
    },
    goToPreviousStep: (state) => {
      state.activeStep =
        (state.activeStep as number) > 0
          ? state.activeStep - 1
          : state.activeStep
    },
    resetStepperState: () => initialState,
  },
})

export const {
  setActiveStep,
  goToNextStep,
  goToPreviousStep,
  initStepper,
  resetStepperState,
} = tjanstemanIAnstallningStepperSlice.actions

export const selectTjanstemanIAnstallningStepper = (state: RootState) =>
  state.tjanstemanIAnstallningStepper

export default tjanstemanIAnstallningStepperSlice.reducer
