import { JSX, useRef } from 'react'
import { usePickEpiContent } from '@local/Utils/Hooks'
import HTMLMapper from '@local/Components/HTMLMapper'
import Gutter from '@local/Components/Gutter'
import { FormikTouched, setNestedObjectValues, useFormikContext } from 'formik'
import { isEmpty, isNil } from 'ramda'
import { useSkickaInAnsokanMutation } from '@local/Services/API/skapaAnsokanApi'
import { useAppDispatch } from '@local/Store/configureStore'
import { decode } from 'html-entities'
import { removeHtmlTags } from '@local/Utils/Helpers'
import { useTrackCustomClick } from '@trr/gtm-tracking'
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
} from '@mui/material'
import FormErrorMessage from '@local/Components/FormErrorMessage'
import { useIsFeatureEnabled } from '@trr/app-shell-data'
import Alert from '@local/Components/Alert'
import { useSelector } from 'react-redux'
import {
  selectGranskaAnsokanFormModalState,
  setIsModalOpen,
} from '@local/Views/SkapaAnsokan/TjanstemanIAnstallning/GranskaAnsokan/Components/GranskaAnsokanFormModal/granskaAnsokanFormModalSlice'
import { ITjanstemanIAnstallningFormValues } from '@local/Views/SkapaAnsokan/TjanstemanIAnstallning/TjanstemanIAnstallningForm/types'
import { GranskaAnsokanFormKeys } from '@local/Views/SkapaAnsokan/TjanstemanIAnstallning/GranskaAnsokan/Components/GranskaAnsokanFormModal/types'
import { useHistory } from 'react-router-dom'
import { ITjanstemanIAnstallningContent } from '@local/Views/SkapaAnsokan/TjanstemanIAnstallning/TjanstemanIAnstallning.types'

const GranskaAnsokanFormModal = (): JSX.Element => {
  const history = useHistory()
  const dispatch = useAppDispatch()

  const { isModalOpen } = useSelector(selectGranskaAnsokanFormModalState)

  const {
    granskaAnsokan: { forsakraUppgifter, skickaInModal, skickaInModalBrodtext },
    nagotGickFel,
    tjanstepersonansokanLinks,
  } = usePickEpiContent<ITjanstemanIAnstallningContent>()

  const trackButtonClick = useTrackCustomClick()
  const shouldTrack = useIsFeatureEnabled('gtm.enabled.tjanstepersonansokan')

  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    validateForm,
    setTouched,
    setFieldValue,
    setFieldTouched,
  } = useFormikContext<ITjanstemanIAnstallningFormValues>()

  const isError =
    touched.forsakratUppgifter && !isNil(errors.forsakratUppgifter)

  const [skickaInAnsokan, { isLoading, isError: isErrorSkickaIn }] =
    useSkickaInAnsokanMutation()

  const scrollElement = useRef<HTMLInputElement>(null)

  const scrollToElement = () => {
    scrollElement?.current?.scrollIntoView({
      block: 'nearest',
    })
  }

  const handleValidateForm = () =>
    validateForm()
      .then((validationErrors) => {
        if (!isEmpty(validationErrors)) {
          setTouched(
            setNestedObjectValues<
              FormikTouched<ITjanstemanIAnstallningFormValues>
            >(validationErrors, true)
          ).catch((err) => {
            console.log(err)
          })
          return false
        } else {
          return true
        }
      })
      .catch((error) => {
        console.log(error)
        return false
      })

  const handleSkickaIn = () => {
    handleValidateForm()
      .then(async (isValid) => {
        if (isValid) {
          await skickaInAnsokan(
            decode(removeHtmlTags(skickaInModalBrodtext.mainBody))
          )
            .unwrap()
            .then(() => {
              if (shouldTrack) {
                trackButtonClick('Modal: Skicka in Tjänstepersonansökan', {
                  label: 'Skicka in',
                })
              }

              history.push(tjanstepersonansokanLinks.tackForDinAnsokan)
              dispatch(setIsModalOpen(false))
            })
        } else {
          scrollToElement()
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const handleClose = () => {
    dispatch(setIsModalOpen(false))

    setFieldValue(GranskaAnsokanFormKeys.ForsakratUppgifter, false).catch(
      (err) => {
        console.log(err)
      }
    )
    setFieldTouched(GranskaAnsokanFormKeys.ForsakratUppgifter, false).catch(
      (err) => {
        console.log(err)
      }
    )
  }

  return (
    <Dialog
      onClose={handleClose}
      open={isModalOpen}
      closeAfterTransition={false}
    >
      <DialogTitle>{skickaInModal.titel}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <HTMLMapper body={skickaInModalBrodtext.mainBody} />
        </DialogContentText>

        <Gutter xs={24} />

        <FormControl error={isError} variant="standard" fullWidth>
          <FormControlLabel
            control={
              <Checkbox
                checked={values.forsakratUppgifter}
                color="primary"
                onChange={handleChange}
                onBlur={handleBlur}
                name={GranskaAnsokanFormKeys.ForsakratUppgifter}
              />
            }
            label={forsakraUppgifter.text}
          />
          {isError && (
            <>
              <Gutter xs={8} />
              <FormErrorMessage>{errors.forsakratUppgifter}</FormErrorMessage>
            </>
          )}

          <div ref={scrollElement} />
        </FormControl>

        {isErrorSkickaIn && (
          <>
            <Gutter xs={16} />
            <Alert severity="error">{nagotGickFel.heading}</Alert>
          </>
        )}
      </DialogContent>

      <DialogActions>
        <Button disabled={isLoading} variant="text" onClick={handleClose}>
          {skickaInModal.avbrytKnapp}
        </Button>
        <Button disabled={isLoading} variant="text" onClick={handleSkickaIn}>
          {skickaInModal.fortsattKnapp}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default GranskaAnsokanFormModal
