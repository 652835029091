import FormErrorMessage from '@local/Components/FormErrorMessage'
import Gutter from '@local/Components/Gutter'
import { ITjanstepersonAnsokanContent } from '@local/Types'
import { RadioChoice } from '@local/Types/form.types'
import { usePickEpiContent } from '@local/Utils/Hooks'
import { IOnboardingFormValues } from '@local/Views/OnboardingWrapper/Onboarding/OnboardingForm/OnboardingForm.schema'
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material'
import { useFormikContext } from 'formik'
import { isNil } from 'ramda'

const AnstallningssituationTIO = () => {
  const {
    onboarding: { uppsagdTyp },
  } = usePickEpiContent<ITjanstepersonAnsokanContent>()
  const { handleChange, handleBlur, errors, touched, values } =
    useFormikContext<IOnboardingFormValues>()

  const isError = touched.uppsagdTyp && !isNil(errors.uppsagdTyp)

  return (
    <>
      <FormControl error={isError} variant="standard" fullWidth>
        <Typography variant="h6" gutterBottom>
          {uppsagdTyp.titel}
        </Typography>

        <RadioGroup
          onChange={handleChange}
          onBlur={handleBlur}
          defaultValue={values.uppsagdTyp}
        >
          <FormControlLabel
            value={RadioChoice.Yes}
            control={<Radio color="primary" />}
            label={uppsagdTyp.ja}
            name="uppsagdTyp"
            id="uppsagdTyp.ja"
          />
          <FormControlLabel
            value={RadioChoice.No}
            control={<Radio color="primary" />}
            label={uppsagdTyp.nej}
            name="uppsagdTyp"
            id="uppsagdTyp.nej"
          />
        </RadioGroup>
        {isError && <FormErrorMessage>{errors.uppsagdTyp}</FormErrorMessage>}
      </FormControl>

      <Gutter xs={32} />
    </>
  )
}

export default AnstallningssituationTIO
