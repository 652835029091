import * as Yup from 'yup'
import { IAnstallningTIAFormValues } from '@local/Views/SkapaAnsokan/TjanstemanIAnstallning/AnstallningTIA/Types'
import { IAnstallningTIAContent } from '@local/Views/SkapaAnsokan/TjanstemanIAnstallning/AnstallningTIA/Types/content'
import {
  ISelectOptionFormikWrapper,
  RadioChoice,
} from '@local/Types/form.types'
import { isAfter, isBefore, subDays } from 'date-fns'
import { getDate } from '@local/Utils/Helpers/formatDate'
import { startDate, today } from '@local/Utils/Helpers/Datetime.helpers'
import { AnstallningFormTypes } from '@local/Views/SkapaAnsokan/Types'

export const anstallningTIAValidationSchema = (
  content: IAnstallningTIAContent
): Yup.ObjectSchema<IAnstallningTIAFormValues> =>
  Yup.object().shape({
    [AnstallningFormTypes.Befattning]: Yup.mixed<ISelectOptionFormikWrapper>()
      .nullable()
      .required(content.befattning.varning),
    [AnstallningFormTypes.IsVdEllerUndantagsgrupp]: Yup.mixed<RadioChoice>()
      .oneOf(Object.values(RadioChoice))
      .required(content.vd.varning),
    [AnstallningFormTypes.Anstallningsdatum]: Yup.date()
      .typeError(content.anstallningsdatum.varning)
      .required(content.anstallningsdatum.varning)
      .test(
        'validateMinDate',
        content.anstallningsdatum.varningMinDate,
        (date) => isAfter(date, subDays(startDate, 1))
      )
      .test(
        'validateMaxDate',
        content.anstallningsdatum.varningMaxDate,
        (date) => isBefore(new Date(getDate(date)), new Date(getDate(today)))
      ),
    [AnstallningFormTypes.Manadslon]: Yup.number()
      .required(content.manadslon.varning)
      .typeError(content.manadslon.varning)
      .integer(content.manadslon.varningDecimaler)
      .positive(content.manadslon.varningNegativt)
      .max(999999, content.manadslon.maxAmountExceeded),
    [AnstallningFormTypes.AnstallningBilagor]: Yup.array().min(
      2,
      content.bifogaArbetsgivarintyg.varning
    ),
  })

export const initialAnstallningTIAValues: IAnstallningTIAFormValues = {
  befattning: null,
  isVdEllerUndantagsgrupp: undefined,
  anstallningsdatum: null,
  manadslon: null,
  anstallningBilagor: [],
}
