import { useEffect } from 'react'
import { useAppDispatch } from '@local/Store/configureStore'
import { TjanstemanIAnstallningStep } from '@local/Views/SkapaAnsokan/TjanstemanIAnstallning/TjanstemanIAnstallning.types'
import { initStepper } from '@local/Views/SkapaAnsokan/TjanstemanIAnstallning/tjanstemanIAnstallningStepperSlice'
import useGetSteps from '@local/Views/SkapaAnsokan/TjanstemanIAnstallning/TjanstemanIAnstallningForm/hooks/useGetSteps'

const useSetDefaultStep = () => {
  const dispatch = useAppDispatch()
  const { steps } = useGetSteps()

  useEffect(() => {
    dispatch(
      initStepper({
        steps,
        activeStep: TjanstemanIAnstallningStep.OmDinArbetsplats,
      })
    )

    // Only setup once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

export default useSetDefaultStep
