import Gutter from '@local/Components/Gutter'
import TextInput from '@local/Components/TextInput'
import { SkapaAnsokanSharedContent } from '@local/Types'
import { usePickEpiContent } from '@local/Utils/Hooks'
import { IArbetsplatsFormValues } from '@local/Views/SkapaAnsokan/Components/Arbetsplats/Types'
import { Typography } from '@mui/material'
import { useFormikContext } from 'formik'

const Organisationsnummer = () => {
  const formikContext = useFormikContext<IArbetsplatsFormValues>()

  const {
    arbetsplats: { organisationsNummer },
  } = usePickEpiContent<SkapaAnsokanSharedContent>()

  return (
    <>
      <Typography variant="h6">{organisationsNummer.titel}</Typography>

      <Gutter xs={16} />

      <Typography variant="subtitle2">
        {organisationsNummer.hjalptext}
      </Typography>

      <Gutter xs={16} />

      <TextInput
        withTrim
        formikContext={formikContext}
        label={organisationsNummer.textFaltTitel}
        name="foretagorganisationsnummer"
      />
    </>
  )
}

export default Organisationsnummer
