import { ISelectOption } from '@local/Types/form.types'
import { trrDateUTC } from '@local/Utils/Helpers/formatDate'
import { IMiddlewareField } from '@local/Utils/Hooks/useFormikWatch'
import {
  IAnstallningTIAFormValues,
  IAnstallningFormValuesApiModel,
} from '@local/Views/SkapaAnsokan/TjanstemanIAnstallningOld/AnstallningTIA/Types'
import { AnstallningFormTypes } from '@local/Views/SkapaAnsokan/Types'

export const anstallningTIAMiddleware = (
  field: IMiddlewareField<IAnstallningTIAFormValues>,
  next: (
    field: IMiddlewareField<IAnstallningTIAFormValues>
  ) => Partial<IAnstallningFormValuesApiModel>
): Partial<IAnstallningFormValuesApiModel> => {
  switch (field.id) {
    case AnstallningFormTypes.AnstallningBilagor:
      return

    case AnstallningFormTypes.Anstallningsdatum:
      return {
        anstallningsdatum: trrDateUTC(field.value as Date).toISOString(),
      }

    case AnstallningFormTypes.Befattning:
      return {
        befattning: (field.value as ISelectOption).label,
        befattningId: (field.value as ISelectOption).id,
      }

    default:
      return next(field)
  }
}
