import {
  ICommonAnsokanValidering,
  ICommonDatumValideringContent,
  IDinaTrrAnsokningarLinks,
  INagotGickFelContent,
  IPatchGickFel,
  ITjanstepersonansokanLinks,
} from '@local/Types'
import { IArbetsplatsContent } from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/Arbetsplats/Types/types'
import { IGranskaAnsokanTBContent } from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/GranskaAnsokan/Types/types'
import { IAnstallningTbContent } from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/AnstallningTB/Types/types'
import { IArbetslivserfarenhetTBContent } from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/ArbetslivserfarenhetTB/Types/types'
import { ILonOchErsattningContent } from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/LonOchErsattning/Types/types'
import { IUppsagningContent } from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/Uppsagning/Types/types'

export interface ITidsbegransadAnstallningContent {
  steps: ITidsbegransadAnstallningStepsContent
  arbetsplats: IArbetsplatsContent
  anstallningTb: IAnstallningTbContent
  uppsagning: IUppsagningContent
  lonOchErsattning: ILonOchErsattningContent
  granskaAnsokan: IGranskaAnsokanTBContent
  arbetslivserfarenhet: IArbetslivserfarenhetTBContent
  sidfot: ISidfotContent
  datumValidering: ICommonDatumValideringContent
  tjanstepersonansokanValidering: ICommonAnsokanValidering
  nagotGickFel: INagotGickFelContent
  patchGickFel: IPatchGickFel
  dinaTrrAnsokningarLinks: IDinaTrrAnsokningarLinks
  tjanstepersonansokanLinks: ITjanstepersonansokanLinks
}

export interface ITidsbegransadAnstallningStepsContent {
  heading: string
  omDinArbetsplats: string
  omDinAnstallning: string
  omDinUppsagning: string
  lonOchErsattning: string
  omDinSysselsattning: string
  granskaOchSkickaIn: string
  slutfordAnsokan: string
}

export interface ISidfotContent {
  foregaendeSteg: string
  nastaSteg: string
  slutfor: string
  kontrolleraUppgifter: string
  avbryt: string
}

export enum TidsbegransadAnstallningStep {
  OmDinArbetsplats = 0,
  OmDinAnstallning = 1,
  OmDinUppsagning = 2,
  LonOchErsattning = 3,
  OmDinSysselsattning = 4,
  GranskaOchSkickaIn = 5,
}

export enum TidsbegransadAnstallningStepFormikId {
  OmDinArbetsplats = 'omDinArbetsplats',
  OmDinAnstallning = 'omDinAnstallning',
  OmDinUppsagning = 'omDinUppsagning',
  LonOchErsattning = 'lonOchErsattning',
  OmDinSysselsattning = 'omDinSysselsattning',
  GranskaOchSkickaIn = 'granskaOchSkickaIn',
}
