import { RadioChoice } from '@local/Types/form.types'
import { IMiddlewareField } from '@local/Utils/Hooks/useFormikWatch'
import { mapLonOchAnsokanRadioChoiceToPatch } from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/LonOchErsattning/Helpers'
import {
  ILonOchErsattningFormValues,
  LonOchErsattningFormTypes,
} from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/LonOchErsattning/Types'

export const lonOchErsattningMiddleware = (
  field: IMiddlewareField<ILonOchErsattningFormValues>,
  next: (
    field: IMiddlewareField<ILonOchErsattningFormValues>
  ) => Partial<ILonOchErsattningFormValues>
): Partial<ILonOchErsattningFormValues> => {
  switch (field.id) {
    case LonOchErsattningFormTypes.Provision:
    case LonOchErsattningFormTypes.Jour:
    case LonOchErsattningFormTypes.SjukersattningsgradChoice: {
      if (field.value === RadioChoice.No) {
        return { [mapLonOchAnsokanRadioChoiceToPatch(field.id)]: null }
      } else return
    }
    default:
      return next(field)
  }
}
