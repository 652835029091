import { ISelectOption } from '@local/Types/form.types'
import { trrDateUTC } from '@local/Utils/Helpers/formatDate'
import { IMiddlewareField } from '@local/Utils/Hooks/useFormikWatch'
import {
  IAnstallningTBFormValues,
  IAnstallningTBFormValuesApiModel,
} from '@local/Views/SkapaAnsokan/TidsbegransadAnstallningOld/AnstallningTB/Types'
import { AnstallningFormTypes } from '@local/Views/SkapaAnsokan/Types'

export const anstallningTBMiddleware = (
  field: IMiddlewareField<IAnstallningTBFormValues>,
  next: (
    field: IMiddlewareField<IAnstallningTBFormValues>
  ) => Partial<IAnstallningTBFormValuesApiModel>
): Partial<IAnstallningTBFormValuesApiModel> => {
  switch (field.id) {
    case AnstallningFormTypes.AnstallningBilagor:
      return
    case AnstallningFormTypes.Anstallningsdatum:
      return {
        anstallningsdatum: trrDateUTC(field.value as Date).toISOString(),
      }

    case AnstallningFormTypes.Befattning:
      return {
        befattning: (field.value as ISelectOption).label,
        befattningId: (field.value as ISelectOption).id,
      }

    default:
      return next(field)
  }
}
