import * as Yup from 'yup'
import { IAnstallningTIAFormValues } from '@local/Views/SkapaAnsokan/TjanstemanIAnstallning/AnstallningTIA/Types'
import {
  ISelectOptionFormikWrapper,
  RadioChoice,
} from '@local/Types/form.types'
import { isAfter, isBefore, subDays } from 'date-fns'
import { startDate, today } from '@local/Utils/Helpers/Datetime.helpers'
import {
  AnsokanKeys,
  ICommonAnsokanValidering,
  ICommonDatumValideringContent,
} from '@local/Types'

export const anstallningTIAValidationSchema = (
  validering: ICommonAnsokanValidering,
  datumValidering: ICommonDatumValideringContent
): Yup.ObjectSchema<IAnstallningTIAFormValues> =>
  Yup.object().shape({
    [AnsokanKeys.Befattning]: Yup.mixed<ISelectOptionFormikWrapper>()
      .nullable()
      .required(validering.befattningIsRequired),
    [AnsokanKeys.IsVdEllerUndantagsgrupp]: Yup.mixed<RadioChoice>()
      .nullable()
      .required(validering.vdIsRequired),
    [AnsokanKeys.Anstallningsdatum]: Yup.date()
      .typeError(validering.anstallningsdatumIsRequired)
      .required(validering.anstallningsdatumIsRequired)
      .test('validateMinDate', datumValidering.warningMinDate, (date) =>
        isAfter(date, subDays(startDate, 1))
      )
      .test('validateMaxDate', datumValidering.warningMaxDate, (date) =>
        isBefore(date, today)
      ),
    [AnsokanKeys.Manadslon]: Yup.number()
      .required(validering.manadslonIsRequired)
      .typeError(validering.manadslonIsRequired)
      .integer(validering.manadslonInvalidFormat)
      .positive(validering.manadslonMinAmountRequired)
      .max(999999, validering.manadslonMaxAmountExceeded),
    [AnsokanKeys.AnstallningBilagor]: Yup.array().min(
      2,
      validering.bilagorIsRequired
    ),
  })

export const initialAnstallningTIAValues: IAnstallningTIAFormValues = {
  befattning: null,
  isVdEllerUndantagsgrupp: undefined,
  anstallningsdatum: null,
  manadslon: null,
  anstallningBilagor: [],
}
