import { Typography } from '@mui/material'
import { useFormikContext } from 'formik'
import Gutter from '@local/Components/Gutter'
import NumberInput from '@local/Components/NumberInput'
import { AnsokanKeys } from '@local/Types'
import { usePickEpiContent } from '@local/Utils/Hooks'
import { ITidsbegransadAnstallningContent } from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/TidsbegransadAnstallning.types'
import { ITidsbegransadAnstallningFormValues } from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/TidsbegransadAnstallningForm/types'

const Anstallningsgrad = () => {
  const formikContext = useFormikContext<ITidsbegransadAnstallningFormValues>()

  const {
    anstallningTb: { anstallningsgrad },
  } = usePickEpiContent<ITidsbegransadAnstallningContent>()

  return (
    <>
      <Typography variant="h6" gutterBottom>
        {anstallningsgrad.titel}
      </Typography>

      <Gutter xs={8} />

      <NumberInput
        formikContext={formikContext}
        label={anstallningsgrad.textFaltTitel}
        name={AnsokanKeys.Anstallningsgrad}
      />
    </>
  )
}

export default Anstallningsgrad
