import { useEffect } from 'react'
import { useAppDispatch } from '@local/Store/configureStore'
import useGetSteps from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/TidsbegransadAnstallningForm/hooks/useGetSteps'
import { initStepper } from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/tidsbegransadAnstallningStepperSlice'
import { TidsbegransadAnstallningStep } from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/TidsbegransadAnstallning.types'

const useSetDefaultStep = () => {
  const dispatch = useAppDispatch()
  const { steps } = useGetSteps()

  useEffect(() => {
    dispatch(
      initStepper({
        steps,
        activeStep: TidsbegransadAnstallningStep.OmDinArbetsplats,
      })
    )

    // Only setup once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

export default useSetDefaultStep
