import { trrDateUTC } from '@local/Utils/Helpers/formatDate'
import { IMiddlewareField } from '@local/Utils/Hooks/useFormikWatch'
import {
  IUppsagningFormValues,
  IUppsagningFormValuesApiModel,
  UppsagningFormTypes,
} from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/Uppsagning/Types'

export const uppsagningMiddleware = (
  field: IMiddlewareField<IUppsagningFormValues>,
  defaultMiddleware: (
    field: IMiddlewareField<IUppsagningFormValues>
  ) => Partial<IUppsagningFormValuesApiModel>
): Partial<IUppsagningFormValuesApiModel> => {
  if (field.id === UppsagningFormTypes.SistaAnstallningsdatum) {
    return {
      sistaAnstallningsdatum: trrDateUTC(field.value as Date).toISOString(),
    }
  } else {
    return defaultMiddleware(field)
  }
}
