import Gutter from '@local/Components/Gutter'
import Arbetstimmar from '@local/Views/SkapaAnsokan/TjanstemanIAnstallning/ArbetslivserfarenhetTIA/Components/Arbetstimmar'
import Etableringsvillkor from '@local/Views/SkapaAnsokan/TjanstemanIAnstallning/ArbetslivserfarenhetTIA/Components/Etableringsvillkor'
import { Grid2 as Grid } from '@mui/material'

const ArbetslivserfarenhetTIA = () => (
  <Grid container>
    <Grid size={{ lg: 10 }} sx={{ width: 1 }}>
      <Arbetstimmar />

      <Gutter xs={32} />

      <Etableringsvillkor />
    </Grid>
  </Grid>
)

export default ArbetslivserfarenhetTIA
