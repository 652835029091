import { baseApi } from '@local/Services/API/baseApi'
import { getAnsokanId } from '@local/Utils/Helpers'

export interface IBilagaApiModel {
  fileId: string
  name: string
}

export interface IPostBilagaApiModel {
  formData: FormData
  userSub: string
}

export interface IDeleteBilagaApiModel {
  bilagaId: string
  userSub: string
}

export const bilagorApi = baseApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getAllIntygBilaga: builder.query<IBilagaApiModel[], string>({
      query: (userSub) =>
        `/ansokan/bilagor/intygtjansteperson/klient/${userSub}/ansokan/${getAnsokanId()}`,
      providesTags: ['bilagor'],
      forceRefetch: () => true,
    }),
    postBilaga: builder.mutation<void, IPostBilagaApiModel>({
      query: ({ formData, userSub }) => ({
        url: `/ansokan/bilagor/intygtjansteperson/klient/${userSub}/ansokan/${getAnsokanId()}/bilaga`,
        method: 'POST',
        body: formData,
      }),
    }),
    deleteBilaga: builder.mutation<void, IDeleteBilagaApiModel>({
      query: ({ bilagaId, userSub }) => ({
        url: `/ansokan/bilagor/intygtjansteperson/klient/${userSub}/bilaga/${bilagaId}`,
        method: 'DELETE',
      }),
    }),
  }),
})

export const {
  useGetAllIntygBilagaQuery,
  usePostBilagaMutation,
  useDeleteBilagaMutation,
} = bilagorApi
