import * as Yup from 'yup'
import {
  countDecimals,
  getYupCurrentSchemaValues,
} from '@local/Utils/Helpers/Forms/form.helpers'
import { IAnstallningTBFormValues } from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/AnstallningTB/Types'
import {
  ISelectOptionFormikWrapper,
  RadioChoice,
} from '@local/Types/form.types'
import { isAfter, isBefore, isValid, subDays } from 'date-fns'
import { startDate, today } from '@local/Utils/Helpers/Datetime.helpers'
import {
  AnsokanKeys,
  ICommonAnsokanValidering,
  ICommonDatumValideringContent,
} from '@local/Types'
import { ITidsbegransadAnstallningFormValues } from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/TidsbegransadAnstallningForm/types/types'

export const anstallningTBValidationSchema = (
  validering: ICommonAnsokanValidering,
  datumValidering: ICommonDatumValideringContent
): Yup.ObjectSchema<IAnstallningTBFormValues> =>
  Yup.object().shape({
    [AnsokanKeys.Anstallningsgrad]: Yup.number()
      .nullable()
      .required(validering.anstallningsgradIsRequired)
      .integer(validering.anstallningsgradInvalidFormat)
      .min(5, validering.anstallningsgradMinAmountRequired)
      .max(100, validering.anstallningsgradMaxAmountExceeded),
    [AnsokanKeys.ArbetstimmarPerVecka]: Yup.number()
      .nullable()
      .required(validering.arbetstimmarIsRequiredTB)
      .positive(validering.arbetstimmarMinAmountRequired)
      .max(80, validering.arbetstimmarMaxAmountExceeded)
      .test(
        'len',
        validering.arbetstimmarInvalidFormat,
        (value: number) => countDecimals(value) <= 2
      ),
    [AnsokanKeys.Befattning]: Yup.mixed<ISelectOptionFormikWrapper>()
      .nullable()
      .required(validering.befattningIsRequired),
    [AnsokanKeys.IsVdEllerUndantagsgrupp]: Yup.mixed<RadioChoice>().required(
      validering.vdIsRequired
    ),
    [AnsokanKeys.Anstallningsdatum]: Yup.date()
      .typeError(validering.anstallningsdatumIsRequired)
      .required(validering.anstallningsdatumIsRequired)
      .test('validateMinDate', datumValidering.warningMinDate, (date) =>
        isAfter(date, subDays(startDate, 1))
      )
      .test('validateMaxDate', datumValidering.warningMaxDate, (date) =>
        isBefore(date, today)
      )
      .test(
        'maxDateMustBeBeforeSistaAnstallningsdatum',
        datumValidering.warningMaxDate,
        (date, context) => {
          const schema =
            getYupCurrentSchemaValues<ITidsbegransadAnstallningFormValues>(
              context
            )

          return isValid(schema.sistaAnstallningsdatum)
            ? isBefore(date, schema.sistaAnstallningsdatum)
            : true
        }
      ),
    [AnsokanKeys.AnstallningBilagor]: Yup.array().min(
      1,
      validering.bilagaIsRequired
    ),
  })

export const initialAnstallningTBValues: IAnstallningTBFormValues = {
  befattning: null,
  isVdEllerUndantagsgrupp: undefined,
  anstallningsdatum: null,
  arbetstimmarPerVecka: null,
  anstallningBilagor: [],
  anstallningsgrad: null,
}
