import * as Yup from 'yup'
import { AnsokanKeys, ICommonAnsokanValidering } from '@local/Types'
import { RadioChoice } from '@local/Types/form.types'
import { IArbetslivserfarenhetTIAFormValues } from '@local/Views/SkapaAnsokan/TjanstemanIAnstallning/ArbetslivserfarenhetTIA/Types'

export const arbetslivserfarenhetTIAValidationSchema = (
  validering: ICommonAnsokanValidering
): Yup.ObjectSchema<IArbetslivserfarenhetTIAFormValues> =>
  Yup.object().shape({
    [AnsokanKeys.HasIntygatEtableringsvillkor]: Yup.mixed<RadioChoice>()
      .nullable()
      .required(validering.etableringsvillkorIsRequired),
    [AnsokanKeys.ArbetstimmarPerVecka]: Yup.number()
      .typeError(validering.arbetstimmarIsRequiredTIA)
      .required(validering.arbetstimmarIsRequiredTIA),
  })

export const initialArbetslivserfarenhetTIAValues: IArbetslivserfarenhetTIAFormValues =
  {
    hasIntygatEtableringsvillkor: null,
    arbetstimmarPerVecka: null,
  }
