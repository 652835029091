import { JSX } from 'react'
import { useFormikContext } from 'formik'
import { RadioChoice } from '@local/Types/form.types'
import { AnstallningSharedFormValues } from '@local/Views/SkapaAnsokan/Types'
import HTMLMapper from '@local/Components/HTMLMapper'
import { usePickEpiContent } from '@local/Utils/Hooks'
import { AnsokanKeys, SkapaAnsokanSharedContent } from '@local/Types'
import { useIsTiaAnsokan } from '@local/Utils/Hooks/SkapaAnsokan'
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material'
import FormErrorMessage from '@local/Components/FormErrorMessage'
import Tooltip from '@local/Components/Tooltip'
import { isNil } from 'ramda'

const VD = (): JSX.Element => {
  const { errors, touched, values, handleBlur, handleChange } =
    useFormikContext<AnstallningSharedFormValues>()

  const isTiaAnsokan = useIsTiaAnsokan()

  const { anstallning, anstallningTb } =
    usePickEpiContent<SkapaAnsokanSharedContent>()

  const anstallningContent = isTiaAnsokan ? anstallning : anstallningTb
  const isError =
    touched.isVdEllerUndantagsgrupp && !isNil(errors.isVdEllerUndantagsgrupp)

  return (
    <FormControl error={isError} variant="standard" fullWidth>
      <Tooltip
        heading={anstallningContent.vd.heading}
        tooltipContent={
          <HTMLMapper body={anstallningContent.tooltipVd.mainBody} />
        }
      />

      <RadioGroup
        onChange={handleChange}
        onBlur={handleBlur}
        defaultValue={values.isVdEllerUndantagsgrupp}
      >
        <FormControlLabel
          value={RadioChoice.Yes}
          control={<Radio color="primary" />}
          label={anstallningContent.vd.ja}
          name={AnsokanKeys.IsVdEllerUndantagsgrupp}
          id={`${AnsokanKeys.IsVdEllerUndantagsgrupp}.ja`}
        />
        <FormControlLabel
          value={RadioChoice.No}
          control={<Radio color="primary" />}
          label={anstallningContent.vd.nej}
          name={AnsokanKeys.IsVdEllerUndantagsgrupp}
          id={`${AnsokanKeys.IsVdEllerUndantagsgrupp}.nej`}
        />
      </RadioGroup>

      {isError && (
        <FormErrorMessage>{errors.isVdEllerUndantagsgrupp}</FormErrorMessage>
      )}
    </FormControl>
  )
}

export default VD
