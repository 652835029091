import { useGetAnsokanQuery } from '@local/Services/API/hamtaAnsokanApi'
import { formatCurrencySEK, formatPercentageNumber } from '@local/Utils/Helpers'
import { trrFormat } from '@local/Utils/Helpers/formatDate'
import { usePickEpiContent } from '@local/Utils/Hooks'
import GranskaAnsokan from '@local/Views/SkapaAnsokan/TjanstemanIAnstallning/GranskaAnsokan'
import { ISammanfattningSektionProps } from '@local/Views/SkapaAnsokan/TjanstemanIAnstallning/GranskaAnsokan/Components/SammanfattningSektion'
import { ITjanstemanIAnstallningContent } from '@local/Views/SkapaAnsokan/TjanstemanIAnstallning/TjanstemanIAnstallning.types'
import { Chip } from '@mui/material'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import Gutter from '@local/Components/Gutter'
import Spinner from '@local/Components/Spinner'
import ChipEllipsis from '@local/Components/ChipEllipsis'
import { useFormikContext } from 'formik'
import { ITjanstemanIAnstallningFormValues } from '@local/Views/SkapaAnsokan/TjanstemanIAnstallning/TjanstemanIAnstallningForm/types'
import { ISelectOption, RadioChoice } from '@local/Types/form.types'
import { getDesiredOrDefault } from '@local/Views/SkapaAnsokan/TjanstemanIAnstallning/GranskaAnsokanTIA/helpers'

const GranskaAnsokanTIA = () => {
  const { data, isLoading: isLoadingAnsokan } = useGetAnsokanQuery()

  const {
    granskaAnsokan: {
      omDinArbetsplats,
      omDinAnstallning,
      omDinArbetslivserfarenhet,
    },
    arbetslivserfarenhet: { arbetstimmar },
  } = usePickEpiContent<ITjanstemanIAnstallningContent>()

  const {
    values: {
      harAgandeskap,
      agarandel,
      befattning,
      isVdEllerUndantagsgrupp,
      anstallningsdatum,
      manadslon,
      anstallningBilagor,
      arbetstimmarPerVecka,
      hasIntygatEtableringsvillkor,
    },
  } = useFormikContext<ITjanstemanIAnstallningFormValues>()

  const arbetsplatsSektion: ISammanfattningSektionProps = {
    title: omDinArbetsplats.titel,
    undersektioner: [
      {
        title: omDinArbetsplats.arbetsgivare,
        body: data?.foretag?.namn,
      },
      {
        title: omDinArbetsplats.agandedel,
        body: getDesiredOrDefault(
          harAgandeskap,
          harAgandeskap === RadioChoice.Yes
            ? omDinArbetsplats.ja
            : omDinArbetsplats.nej
        ),
      },
    ],
  }

  if (harAgandeskap === RadioChoice.Yes) {
    arbetsplatsSektion.undersektioner.push({
      title: omDinArbetsplats.agarandelar,
      body: getDesiredOrDefault(agarandel, formatPercentageNumber(agarandel)),
    })
  }

  const anstallningSektion: ISammanfattningSektionProps = {
    title: omDinAnstallning.titel,
    undersektioner: [
      {
        title: omDinAnstallning.befattning,
        body: getDesiredOrDefault(
          befattning,
          (befattning as ISelectOption)?.label
        ),
      },
      {
        title: omDinAnstallning.vd,
        body: getDesiredOrDefault(
          isVdEllerUndantagsgrupp,
          isVdEllerUndantagsgrupp === RadioChoice.Yes
            ? omDinAnstallning.ja
            : omDinAnstallning.nej
        ),
      },
      {
        title: omDinAnstallning.anstallningsdatum,
        body: getDesiredOrDefault(
          anstallningsdatum,
          trrFormat(anstallningsdatum)
        ),
      },
      {
        title: omDinAnstallning.manadslon,
        body: getDesiredOrDefault(manadslon, formatCurrencySEK(manadslon)),
      },
      {
        title: omDinAnstallning.bifogadeFiler,
        body: getDesiredOrDefault(
          anstallningBilagor,
          <>
            <Gutter xs={8} />
            {anstallningBilagor?.map((bilaga) => (
              <div key={bilaga.id}>
                <ChipEllipsis>
                  <Chip
                    icon={<AttachFileIcon />}
                    label={bilaga.name}
                    variant="outlined"
                  />
                </ChipEllipsis>
                <Gutter xs={8} />
              </div>
            ))}
          </>
        ),
      },
    ],
  }

  const arbetslivserfarenhetSektion: ISammanfattningSektionProps = {
    title: omDinArbetslivserfarenhet.heading,
    undersektioner: [
      {
        title: omDinArbetslivserfarenhet.arbetstimmar,
        body: getDesiredOrDefault(
          arbetstimmarPerVecka,
          arbetstimmarPerVecka > 15 ? arbetstimmar.ja : arbetstimmar.nej
        ),
      },
      {
        title: omDinArbetslivserfarenhet.etableringsvillkor,
        body: getDesiredOrDefault(
          hasIntygatEtableringsvillkor,
          hasIntygatEtableringsvillkor === RadioChoice.Yes
            ? omDinArbetslivserfarenhet.ja
            : omDinArbetslivserfarenhet.nej
        ),
      },
    ],
  }

  if (isLoadingAnsokan) {
    return <Spinner centered />
  }

  const sammanfattning = [
    arbetsplatsSektion,
    anstallningSektion,
    arbetslivserfarenhetSektion,
  ]

  return <GranskaAnsokan sammanfattningSektioner={sammanfattning} />
}

export default GranskaAnsokanTIA
