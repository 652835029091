import { JSX, useEffect, useState } from 'react'
import { usePatchAnsokanMutation } from '@local/Services/API/skapaAnsokanApi'
import Toaster from '@local/Components/Toaster'
import { usePickEpiContent } from '@local/Utils/Hooks'
import { ITidsbegransadAnstallningContent } from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/TidsbegransadAnstallning.types'
import StepperGuard from '@local/Components/StepperGuard/StepperGuard'
import TidsbegransadAnstallningForm from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/TidsbegransadAnstallningForm'
import { useGetAnsokanQuery } from '@local/Services/API/hamtaAnsokanApi'
import { useGetAllIntygBilagaQuery } from '@local/Services/API/bilagorApi'
import { useAuthentication } from '@trr/app-shell-data'
import Spinner from '@local/Components/Spinner'

const TidsbegransadAnstallning = (): JSX.Element => {
  const { sub } = useAuthentication()
  const [toasterOpen, setToasterOpen] = useState(false)

  const content = usePickEpiContent<ITidsbegransadAnstallningContent>()

  const [, { isError: isErrorPatchAnsokan }] = usePatchAnsokanMutation({
    fixedCacheKey: 'patchAnsokan',
  })

  const { data: ansokan, isLoading: isLoadingAnsokan } = useGetAnsokanQuery()

  const { data: bilagor, isLoading: isLoadingBilagor } =
    useGetAllIntygBilagaQuery(sub)

  useEffect(() => {
    if (isErrorPatchAnsokan) {
      setToasterOpen(true)
    } else {
      setToasterOpen(false)
    }
  }, [isErrorPatchAnsokan])

  if (isLoadingAnsokan || isLoadingBilagor) {
    return <Spinner centered />
  }

  return (
    <>
      <Toaster
        message={content.patchGickFel.heading}
        open={toasterOpen}
        setOpen={setToasterOpen}
      />

      <StepperGuard>
        <TidsbegransadAnstallningForm
          content={content}
          bilagor={bilagor}
          ansokan={ansokan}
        />
      </StepperGuard>
    </>
  )
}

export default TidsbegransadAnstallning
