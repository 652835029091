import { useDispatch } from 'react-redux'
import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { baseApi } from '@local/Services/API/baseApi'
import { formikWatchSlice } from '@local/Utils/Hooks/formikWatchSlice'

const rootReducer = combineReducers({
  [baseApi.reducerPath]: baseApi.reducer,
  ['formikWatch']: formikWatchSlice.reducer,
})

export const setupStore = (preloadedState?: DeepPartial<RootState>) =>
  configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      // adding the api middleware enables caching, invalidation, polling and other features of `rtk-query`
      getDefaultMiddleware().concat([baseApi.middleware]),
    preloadedState,
  })

export const store = setupStore()

export type DeepPartial<T> = T extends object
  ? {
      [P in keyof T]?: DeepPartial<T[P]>
    }
  : T

export type RootState = ReturnType<typeof rootReducer>
export type AppStore = ReturnType<typeof setupStore>
export type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>()
