import { addDays, isValid, subDays } from 'date-fns'

import { toTrrDateOrTrrNow } from './formatDate'

export const calculateDate = (
  date: Date,
  {
    numberOfYearsFromDate = 0,
    numberOfMonthsFromDate = 0,
    numberOfDaysFromDate = 0,
  }: {
    numberOfYearsFromDate?: number
    numberOfMonthsFromDate?: number
    numberOfDaysFromDate?: number
  }
) =>
  date
    ? toTrrDateOrTrrNow(
        new Date(
          date.getFullYear() + numberOfYearsFromDate,
          date.getMonth() + numberOfMonthsFromDate,
          date.getDate() + numberOfDaysFromDate
        )
      )
    : null

export const startDate = toTrrDateOrTrrNow(
  calculateDate(new Date('1970-01-01'), {})
)
export const octoberStart = toTrrDateOrTrrNow(
  calculateDate(new Date('2022-10-01'), {})
)
export const today = toTrrDateOrTrrNow(calculateDate(new Date(), {}))
export const yesterday = toTrrDateOrTrrNow(subDays(today, 1))
export const tomorrow = toTrrDateOrTrrNow(addDays(today, 1))

export const dayBeforeDate = (date: Date) =>
  isValid(date) ? calculateDate(date, { numberOfDaysFromDate: -1 }) : null

export const dayAfterDate = (date: Date) =>
  isValid(date) ? calculateDate(date, { numberOfDaysFromDate: 1 }) : null

export const getMaxStartDate = (endDate: Date) => {
  if (isValid(endDate)) {
    return endDate <= today ? dayBeforeDate(endDate) : yesterday
  } else {
    return yesterday
  }
}

/**
 * Gets the latest date from the two date inputs
 */
export const getMaxDate = (firstDate: Date, secondDate: Date) =>
  firstDate > secondDate ? firstDate : secondDate

//Function to sort list of dates from earlieast to latest
export const sortDates = (dates: Date[]) =>
  dates
    .filter((date) => date !== null)
    .sort((currentDate, nextDate) => currentDate.valueOf() - nextDate.valueOf())

// Retrieves the earliest occuring date from an array of dates
export const getEarliestOccuringDate = (dates: Date[]) => {
  const validDates = dates.filter((date) => isValid(date))
  const sortedDates = sortDates(validDates)

  return sortedDates.length > 0 ? sortedDates[0] : null
}

// Retrieves the latest occuring date from an array of dates
export const getLatestOccuringDate = (dates: Date[]) => {
  const validDates = dates.filter((date) => isValid(date))
  const sortedDates = sortDates(validDates)

  return sortedDates.length > 0 ? sortedDates[sortedDates.length - 1] : null
}
