import { JSX } from 'react'
import { useFormikContext } from 'formik'
import { RadioChoice } from '@local/Types/form.types'
import HTMLMapper from '@local/Components/HTMLMapper'
import { usePickEpiContent } from '@local/Utils/Hooks'
import { AnsokanKeys } from '@local/Types'
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material'
import FormErrorMessage from '@local/Components/FormErrorMessage'
import Tooltip from '@local/Components/Tooltip'
import { isNil } from 'ramda'
import { ITidsbegransadAnstallningFormValues } from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/TidsbegransadAnstallningForm/types'
import { ITidsbegransadAnstallningContent } from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/TidsbegransadAnstallning.types'

const VD = (): JSX.Element => {
  const { errors, touched, values, handleBlur, handleChange } =
    useFormikContext<ITidsbegransadAnstallningFormValues>()

  const { anstallningTb } =
    usePickEpiContent<ITidsbegransadAnstallningContent>()

  const isError =
    touched.isVdEllerUndantagsgrupp && !isNil(errors.isVdEllerUndantagsgrupp)

  return (
    <FormControl error={isError} variant="standard" fullWidth>
      <Tooltip
        heading={anstallningTb.vd.heading}
        tooltipContent={<HTMLMapper body={anstallningTb.tooltipVd.mainBody} />}
      />

      <RadioGroup
        onChange={handleChange}
        onBlur={handleBlur}
        defaultValue={values.isVdEllerUndantagsgrupp}
      >
        <FormControlLabel
          value={RadioChoice.Yes}
          control={<Radio color="primary" />}
          label={anstallningTb.vd.ja}
          name={AnsokanKeys.IsVdEllerUndantagsgrupp}
          id={`${AnsokanKeys.IsVdEllerUndantagsgrupp}.ja`}
          checked={values.isVdEllerUndantagsgrupp === RadioChoice.Yes}
        />
        <FormControlLabel
          value={RadioChoice.No}
          control={<Radio color="primary" />}
          label={anstallningTb.vd.nej}
          name={AnsokanKeys.IsVdEllerUndantagsgrupp}
          id={`${AnsokanKeys.IsVdEllerUndantagsgrupp}.nej`}
          checked={values.isVdEllerUndantagsgrupp === RadioChoice.No}
        />
      </RadioGroup>

      {isError && (
        <FormErrorMessage>{errors.isVdEllerUndantagsgrupp}</FormErrorMessage>
      )}
    </FormControl>
  )
}

export default VD
