import { ICommonAnsokanValidering } from '@local/Types'
import { GranskaAnsokanFormKeys } from '@local/Views/SkapaAnsokan/TjanstemanIAnstallning/GranskaAnsokan/Components/GranskaAnsokanFormModal/types'
import * as Yup from 'yup'

export interface IGranskaAnsokanFormValues {
  [GranskaAnsokanFormKeys.ForsakratUppgifter]: boolean
}

export const granskaAnsokanValidationSchema = (
  validering: ICommonAnsokanValidering
): Yup.ObjectSchema<IGranskaAnsokanFormValues> =>
  Yup.object().shape({
    [GranskaAnsokanFormKeys.ForsakratUppgifter]: Yup.boolean().oneOf(
      [true],
      validering.forsakranIsRequired
    ),
  })

export const initialGranskaAnsokanValues: IGranskaAnsokanFormValues = {
  [GranskaAnsokanFormKeys.ForsakratUppgifter]: false,
}
