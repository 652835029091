import { usePickEpiContent, useGtmWizardTracker } from '@local/Utils/Hooks'
import { ITidsbegransadAnstallningContent } from '@local/Views/SkapaAnsokan/TidsbegransadAnstallningOld/TidsbegransadAnstallning.types'
import { useGetAnsokanQuery } from '@local/Services/API/hamtaAnsokanApi'
import UppsagningForm from '@local/Views/SkapaAnsokan/TidsbegransadAnstallningOld/Uppsagning/UppsagningForm'
import Spinner from '@local/Components/Spinner'
import { useGetSistaAnstallningsdatumMinDate } from '@local/Views/SkapaAnsokan/TidsbegransadAnstallningOld/Uppsagning/Hooks'

const Uppsagning = () => {
  const { uppsagning } = usePickEpiContent<ITidsbegransadAnstallningContent>()
  useGtmWizardTracker('TB-Ansökan')

  const sistaAnstallningsdatumMinDate = useGetSistaAnstallningsdatumMinDate()

  const { data, isLoading } = useGetAnsokanQuery(undefined, {
    refetchOnMountOrArgChange: true,
  })

  if (isLoading) {
    return <Spinner centered />
  }

  return (
    <UppsagningForm
      content={uppsagning}
      ansokan={data}
      sistaAnstallningsdatumMinDate={sistaAnstallningsdatumMinDate}
    />
  )
}

export default Uppsagning
