import { baseApi } from '@local/Services/API/baseApi'
import { AnsokanDraftState } from '@local/Types'
import { getAnsokanId } from '@local/Utils/Helpers'
import { TypeOfAnsokan } from '@local/Views/OnboardingWrapper/Onboarding/Types'
import { IAnsokanApiModel } from '@local/Services/Types/apiTypes'

export interface IAnsokanDraftApiResponse {
  id: string
  type: TypeOfAnsokan
  ansokanState: AnsokanDraftState
}

export const hamtaAnsokanApi = baseApi.injectEndpoints({
  overrideExisting: false,

  endpoints: (builder) => ({
    getAnsokanDraft: builder.query<IAnsokanDraftApiResponse, string>({
      query: (userSub) => `ansokan/klient/${userSub}/draft`,
      keepUnusedDataFor: 7200,
      providesTags: ['ansokanDraft'],
    }),
    kanAnsoka: builder.query<boolean, void>({
      query: () => `ansokan/kanansoka`,
      keepUnusedDataFor: 7200,
      providesTags: ['kanAnsoka'],
    }),
    getAnsokan: builder.query<IAnsokanApiModel, void>({
      query: () => `ansokan/${getAnsokanId()}`,
      providesTags: ['ansokan'],
    }),
  }),
})

export const {
  useGetAnsokanDraftQuery,
  useKanAnsokaQuery,
  useGetAnsokanQuery,
} = hamtaAnsokanApi

export const { useQueryState: useGetAnsokanDraftState } =
  hamtaAnsokanApi.endpoints.getAnsokanDraft

export const { useQueryState: useGetAnsokanState } =
  hamtaAnsokanApi.endpoints.getAnsokan
