import { usePickEpiContent } from '@local/Utils/Hooks'
import { GranskaAnsokanProps } from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/GranskaAnsokan/Types/types'
import { ITidsbegransadAnstallningContent } from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/TidsbegransadAnstallning.types'
import { Grid2 as Grid, Typography } from '@mui/material'
import Gutter from '@local/Components/Gutter'
import Accordion from '@local/Components/Accordion'
import { SammanfattningSektion } from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/GranskaAnsokan/Components/SammanfattningSektion'
import Divider from '@local/Components/Divider'
import GranskaAnsokanFormModal from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/GranskaAnsokan/Components/GranskaAnsokanFormModal'

const GranskaAnsokan = ({ sammanfattningSektioner }: GranskaAnsokanProps) => {
  const {
    granskaAnsokan: { textinnehall },
  } = usePickEpiContent<ITidsbegransadAnstallningContent>()

  return (
    <Grid container>
      <Grid size={{ lg: 10 }} sx={{ width: 1 }}>
        <Gutter xs={8} />

        <Typography variant="body1">{textinnehall.titel}</Typography>

        <Gutter xs={16} />

        <Accordion
          heading={textinnehall.sektionTitel}
          disableGutters
          ariaControls="granska-ansokan-accordion-content"
          ariaControlsId="granska-ansokan-accordion-header"
        >
          {sammanfattningSektioner.map((sektion, index: number) => (
            <div key={sektion.title}>
              <SammanfattningSektion {...sektion} />
              {index !== sammanfattningSektioner.length - 1 && (
                <>
                  <Divider offset_xs={0} />

                  <Gutter xs={24} />
                </>
              )}
            </div>
          ))}
        </Accordion>

        <GranskaAnsokanFormModal />
      </Grid>
    </Grid>
  )
}

export default GranskaAnsokan
