import Gutter from '@local/Components/Gutter'
import { usePickEpiContent } from '@local/Utils/Hooks'
import HTMLMapper from '@local/Components/HTMLMapper'
import { replaceEpiVariables } from '@local/Utils/Helpers'
import useOnUnmount from '@local/Utils/Hooks/useOnUnmount'
import { baseApi } from '@local/Services/API/baseApi'
import { useAppDispatch } from '@local/Store/configureStore'
import EpiImage from '@local/Components/EpiImage/EpiImage'
import { Button } from '@mui/material'
import { useUser } from '@trr/app-shell-data'
import { ITackForDinAnsokanContent } from '@local/Views/TackForDinAnsokan/Types'
import { useKanAnsokaQuery } from '@local/Services/API/hamtaAnsokanApi'
import { Redirect } from 'react-router-dom'
import Spinner from '@local/Components/Spinner'

const TackForDinAnsokan = () => {
  const {
    textinnehall,
    dinaAnsokningarLank: { heading, url },
    bild,
    tjanstepersonansokanLinks,
  } = usePickEpiContent<ITackForDinAnsokanContent>()

  const { firstName } = useUser()

  const { data: kanAnsoka, isLoading } = useKanAnsokaQuery()

  const dispatch = useAppDispatch()
  useOnUnmount(() => {
    dispatch(baseApi.util.invalidateTags(['ansokan', 'ansokanDraft']))
  })

  if (isLoading) {
    return <Spinner />
  }

  if (kanAnsoka) {
    return <Redirect to={tjanstepersonansokanLinks.ansokanUrl} />
  }

  return (
    <>
      <EpiImage url={bild.url} />

      <Gutter xs={40} />

      <HTMLMapper
        body={replaceEpiVariables(textinnehall.mainBody, {
          fornamn: firstName,
        })}
      />

      <Gutter xs={48} />

      <Button variant="contained" href={url}>
        {heading}
      </Button>
    </>
  )
}

export default TackForDinAnsokan
