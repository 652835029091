import { usePickEpiContent } from '@local/Utils/Hooks'
import { ITjanstemanIAnstallningContent } from '@local/Views/SkapaAnsokan/TjanstemanIAnstallning/TjanstemanIAnstallning.types'
import { useGetAnsokanQuery } from '@local/Services/API/hamtaAnsokanApi'
import Spinner from '@local/Components/Spinner'
import { ITjanstemanIAnstallningFormValues } from '@local/Views/SkapaAnsokan/TjanstemanIAnstallning/TjanstemanIAnstallningForm/types'
import { useFormikContext } from 'formik'
import { Grid2 as Grid } from '@mui/material'
import Gutter from '@local/Components/Gutter'
import DateInput from '@local/Components/DateInput'
import { replaceEpiVariables } from '@local/Utils/Helpers'
import { AnsokanKeys } from '@local/Types'
import { startDate, yesterday } from '@local/Utils/Helpers/Datetime.helpers'
import Manadslon from '@local/Views/SkapaAnsokan/TjanstemanIAnstallning/AnstallningTIA/Components/Manadslon'
import Bilagor from '@local/Views/SkapaAnsokan/TjanstemanIAnstallning/AnstallningTIA/Components/Bilagor'
import { generateErrorMessage } from '@local/Utils/Helpers/Forms/form.helpers'
import VD from '@local/Views/SkapaAnsokan/TjanstemanIAnstallning/AnstallningTIA/Components/VD'
import { Befattningar } from '@local/Views/SkapaAnsokan/TjanstemanIAnstallning/AnstallningTIA/Components/Befattningar/Befattningar'

const AnstallningTIA = () => {
  const { data, isLoading } = useGetAnsokanQuery()

  const formikContext = useFormikContext<ITjanstemanIAnstallningFormValues>()
  const { errors, touched, values, setFieldValue } = formikContext

  const {
    anstallning: {
      befattning,
      anstallningsdatum,
      bifogaArbetsgivarintyg,
      bifogaFiler,
      tooltipBefattning,
      tooltipAnstallningsdatum,
      tooltipArbetsgivarintyg,
      laddaUppBilaga,
    },
  } = usePickEpiContent<ITjanstemanIAnstallningContent>()

  if (isLoading) {
    return <Spinner centered />
  }

  return (
    <Grid container>
      <Grid size={{ lg: 10 }} sx={{ width: 1 }}>
        <Befattningar
          formikContext={formikContext}
          content={befattning}
          tooltipContent={tooltipBefattning}
        />

        <Gutter xs={32} />

        <VD />

        <Gutter xs={32} />

        <DateInput
          formikFieldName={AnsokanKeys.Anstallningsdatum}
          formikContext={formikContext}
          label={anstallningsdatum.textFaltTitel}
          tooltipContent={tooltipAnstallningsdatum}
          minDate={startDate}
          maxDate={yesterday}
          title={replaceEpiVariables(anstallningsdatum.titel, {
            foretag: data.foretag.namn,
          })}
        />

        <Gutter xs={32} />

        <Manadslon />

        <Gutter xs={32} />

        <Bilagor
          content={{
            ...laddaUppBilaga,
            description: bifogaFiler.mainBody,
            heading: bifogaArbetsgivarintyg.heading,
            uploadButtonText: bifogaArbetsgivarintyg.laddaUppKnapp,
            tooltip: tooltipArbetsgivarintyg.mainBody,
          }}
          formikFieldName={AnsokanKeys.AnstallningBilagor}
          files={values.anstallningBilagor}
          setFieldValue={setFieldValue}
          errorMessage={generateErrorMessage({
            touched: touched?.anstallningBilagor !== undefined,
            errorMsg: errors?.anstallningBilagor as string,
          })}
        />
      </Grid>
    </Grid>
  )
}

export default AnstallningTIA
