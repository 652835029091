import { FormikContextType } from 'formik'
import HTMLMapper from '@local/Components/HTMLMapper'
import { useGetAnsokanQuery } from '@local/Services/API/hamtaAnsokanApi'
import { AnsokanKeys, IEditorialBlock } from '@local/Types'
import {
  replaceEpiVariables,
  setFieldValue as customSetFieldValue,
} from '@local/Utils/Helpers'
import { IBefattningContent } from '@local/Views/SkapaAnsokan/TjanstemanIAnstallning/AnstallningTIA/Types'
import { ISelectOptionFormikWrapper } from '@local/Types/form.types'
import Tooltip from '@local/Components/Tooltip'
import Spinner from '@local/Components/Spinner'
import Autocomplete from '@local/Views/SkapaAnsokan/TjanstemanIAnstallning/AnstallningTIA/Components/Befattningar/Autocomplete'

interface IBefattning {
  befattning: ISelectOptionFormikWrapper
}

interface BefattningarProps {
  content: IBefattningContent
  tooltipContent: IEditorialBlock
  formikContext: FormikContextType<IBefattning>
}

export const Befattningar = ({
  content,
  tooltipContent,
  formikContext,
}: BefattningarProps) => {
  const { values, setFieldValue, setFieldTouched } = formikContext

  const { data, isLoading } = useGetAnsokanQuery()

  if (isLoading) {
    return <Spinner />
  }

  return (
    <>
      <Tooltip
        heading={replaceEpiVariables(content.titel, {
          foretag: data.foretag.namn,
        })}
        tooltipContent={<HTMLMapper body={tooltipContent.mainBody} />}
      />

      <Autocomplete
        formikName={AnsokanKeys.Befattning}
        label={content.textFaltTitel}
        placeholder={content.textFaltTitel}
        value={values.befattning}
        formikContext={formikContext}
        onChange={customSetFieldValue(
          setFieldValue,
          'befattning',
          setFieldTouched
        )}
      />
    </>
  )
}
