import { IAnstallningTIAFormValues } from '@local/Views/SkapaAnsokan/TjanstemanIAnstallningOld/AnstallningTIA/Types'
import { IArbetsplatsFormValues } from '@local/Views/SkapaAnsokan/Components/Arbetsplats/Types'
import { IGranskaAnsokanFormValues } from '@local/Views/SkapaAnsokan/Components/GranskaAnsokan/GranskaAnsokanForm.schema'
import { IAnstallningTBFormValues } from '@local/Views/SkapaAnsokan/TidsbegransadAnstallningOld/AnstallningTB/Types'
import { ILonOchErsattningFormValues } from '@local/Views/SkapaAnsokan/TidsbegransadAnstallningOld/LonOchErsattning/Types'
import { IUppsagningFormValues } from '@local/Views/SkapaAnsokan/TidsbegransadAnstallningOld/Uppsagning/Types'
import { ITjanstemanIAnstallningContent } from '@local/Views/SkapaAnsokan/TjanstemanIAnstallningOld/TjanstemanIAnstallning.types'
import { ITidsbegransadAnstallningContent } from '@local/Views/SkapaAnsokan/TidsbegransadAnstallningOld/TidsbegransadAnstallning.types'
import { IArbetslivserfarenhetTBFormValues } from '@local/Views/SkapaAnsokan/TidsbegransadAnstallningOld/ArbetslivserfarenhetTB/Types'
import { IArbetslivserfarenhetTIAFormValues } from '@local/Views/SkapaAnsokan/TjanstemanIAnstallningOld/ArbetslivserfarenhetTIA/Types'
import { ITjanstemanIAnstallningFormValues } from '@local/Views/SkapaAnsokan/TjanstemanIAnstallning/TjanstemanIAnstallningForm/types'

export interface ICommonAnsokanValidering {
  agarandelInvalidFormat: string
  agarandelIsRequired: string
  agarandelMaxAmountExceeded: string
  agarandelMinAmountRequired: string
  anstallningsdatumIsRequired: string
  anstallningsgradInvalidFormat: string
  anstallningsgradIsRequired: string
  anstallningsgradMaxAmountExceeded: string
  anstallningsgradMinAmountRequired: string
  arbetstimmarInvalidFormat: string
  arbetstimmarIsRequiredTB: string
  arbetstimmarIsRequiredTIA: string
  arbetstimmarMaxAmountExceeded: string
  arbetstimmarMinAmountRequired: string
  befattningIsRequired: string
  bilagaIsRequired: string
  bilagorIsRequired: string
  etableringsvillkorIsRequired: string
  forsakranIsRequired: string
  harAgarandelIsRequired: string
  harJourIsRequired: string
  harProvisionIsRequired: string
  harSjukersattningsgradIsRequired: string
  jourBeloppInvalidFormat: string
  jourBeloppIsRequired: string
  jourBeloppMaxAmountExceeded: string
  jourBeloppMinAmountRequired: string
  kontaktpersonEpostInvalidFormat: string
  kontaktpersonEpostIsRequired: string
  kontaktpersonNamnIsRequired: string
  manadslonInvalidFormat: string
  manadslonIsRequired: string
  manadslonMaxAmountExceeded: string
  manadslonMinAmountRequired: string
  nuvarandeArbetsplatsIsRequired: string
  orgnummerHasInvalidFormat: string
  orgnummerIsInvalid: string
  orgnummerIsNotAffiliated: string
  orgnummerIsRequired: string
  provisionBeloppInvalidFormat: string
  provisionBeloppIsRequired: string
  provisionBeloppMaxAmountExceeded: string
  provisionBeloppMinAmountRequired: string
  sistaAnstallningsdatumIsRequired: string
  sistaAnstallningsdatumMaxAmountExceeded: string
  sjukersattningsgradBeloppInvalidFormat: string
  sjukersattningsgradBeloppIsRequired: string
  sjukersattningsgradBeloppMaxAmountExceeded: string
  sjukersattningsgradBeloppMinAmountRequired: string
  vdIsRequired: string
}

export interface ICommonDatumValideringContent {
  warningMinDate: string
  warningMaxDate: string
  warningDateIsRequired: string
  warningDateIsInvalid: string
}

export enum AnsokanKeys {
  NuvarandeArbetsplats = 'nuvarandeArbetsplats',
  Organisationsnummer = 'foretagorganisationsnummer',
  HarAgandeskap = 'harAgandeskap',
  Agarandel = 'agarandel',
  Befattning = 'befattning',
  IsVdEllerUndantagsgrupp = 'isVdEllerUndantagsgrupp',
  Anstallningsdatum = 'anstallningsdatum',
  Manadslon = 'manadslon',
  AnstallningBilagor = 'anstallningBilagor',
  ArbetstimmarPerVecka = 'arbetstimmarPerVecka',
  HasIntygatEtableringsvillkor = 'hasIntygatEtableringsvillkor',
  Anstallningsgrad = 'anstallningsgrad',
  SistaAnstallningsdatum = 'sistaAnstallningsdatum',
  ForetagKontaktpersonEpostadress = 'foretagKontaktpersonEpostadress',
  ForetagKontaktpersonNamn = 'foretagKontaktpersonNamn',
  Provision = 'provision',
  ProvisionBelopp = 'provisionBelopp',
  Jour = 'jour',
  JourBelopp = 'jourBelopp',
  SjukersattningsgradChoice = 'sjukersattningsgradChoice',
  Sjukersattningsgrad = 'sjukersattningsgrad',
  OrganisationsnummerIsAffiliated = 'organisationsnummerIsAffiliated',
}

export type FormikWatcherValues = ITjanstemanIAnstallningFormValues

export enum AnsokanDraftState {
  Utkast = 'utkast',
  Fullstandig = 'fullstandig',
  Inskickad = 'inskickad',
}

export interface IEditorialBlock {
  mainBody: string
}

export interface ILinkBlock {
  heading: string
  url: string
}

export interface ITioTiaLinkBlock {
  heading: string
  urlTio: string
  urlTia: string
  url: string
}

export interface INagotGickFelContent {
  heading: string
}

export type IPatchGickFel = INagotGickFelContent

export type IFormValues = IArbetsplatsFormValues &
  IAnstallningTIAFormValues &
  IGranskaAnsokanFormValues &
  IAnstallningTBFormValues &
  ILonOchErsattningFormValues &
  IUppsagningFormValues &
  IArbetslivserfarenhetTBFormValues &
  IArbetslivserfarenhetTIAFormValues

export interface IKeyValue {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any
}

export enum UserRoles {
  TIAOprovad = 'TIAOprovad',
  TIOOprovad = 'TIOOprovad',
}

export enum Worksituation {
  Employed = 'employed',
  Unemployed = 'unemployed',
}

export interface ILaddaUppBilaga {
  heading: string
  uploadButtonText: string
  fileDeletedError: string
  fileSizeLimitExceeded: string
  fileUploadError: string
  fileExtensionError: string
  fileUploadCannotReadFileError: string
  description: string
  tooltip: string
}

export interface IRadioChoice {
  ja: string
  nej: string
}

export interface ITjanstepersonansokanLinks {
  ansokanUrl: string
  tidsbegransadAnstallningUrl: string
  ogiltigtOrgnummerUrl: string
  tjanstemanIAnstallningUrl: string
  tackForDinAnsokan: string
}

export interface IDinaTrrAnsokningarLinks {
  dinaTrrAnsokningarUrl: string
  tidigareSysselsattningarUrl: string
  ansokanUrl: string
}

export type SkapaAnsokanSharedContent = ITjanstemanIAnstallningContent &
  ITidsbegransadAnstallningContent
