import { useLazyGetArbetsgivareQuery } from '@local/Services/API/arbetsgivareApi'
import { useGetAnsokanState } from '@local/Services/API/hamtaAnsokanApi'
import { Worksituation } from '@local/Types'
import { RadioChoice } from '@local/Types/form.types'
import useIsTiaAnsokan from '@local/Utils/Hooks/SkapaAnsokan/useIsTiaAnsokan'
import { IArbetsplatsFormValues } from '@local/Views/SkapaAnsokan/Components/Arbetsplats/Types'
import { useFormikContext } from 'formik'

const useGetArbetsgivareFromState = () => {
  const [getArbetsgivare] = useLazyGetArbetsgivareQuery()
  const isTia = useIsTiaAnsokan()

  const {
    data: {
      foretag: { organisationsnummer: foretagOrganisationsnummer },
    },
  } = useGetAnsokanState()

  const { values } = useFormikContext<IArbetsplatsFormValues>()

  const isNuvarandeArbetsplats = values.nuvarandeArbetsplats === RadioChoice.Yes

  return () =>
    getArbetsgivare({
      orgNummer: isNuvarandeArbetsplats
        ? foretagOrganisationsnummer
        : values.foretagorganisationsnummer,
      worksituation: isTia ? Worksituation.Employed : Worksituation.Unemployed,
    })
}

export default useGetArbetsgivareFromState
