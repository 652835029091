import { JSX } from 'react'
import { IUndersektion } from '@local/Views/SkapaAnsokan/TjanstemanIAnstallning/GranskaAnsokan/Components/SammanfattningSektion'
import Gutter from '@local/Components/Gutter'
import { Typography } from '@mui/material'

const SammanfattningUndersektion = ({
  sektion,
}: {
  sektion: IUndersektion
}): JSX.Element => (
  <>
    <Typography variant="h6" gutterBottom>
      {sektion.title}
    </Typography>
    {typeof sektion.body === 'string' ? (
      <Typography variant="body1">{sektion.body}</Typography>
    ) : (
      sektion.body
    )}
    <Gutter xs={24} />
  </>
)

export default SammanfattningUndersektion
