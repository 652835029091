export const orgNummerRegex = /^\d{6}-?\d{4}$/

export const UUIDRegex =
  /[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}/i

export const matchStringByRegex = (input: string, matcher: RegExp): string =>
  matcher.exec(input)?.toString()?.split(',')[0] || ''

export const email = new RegExp(
  /^(?!.*\.\.)(?!^\.)[A-Z0-9._%+-]+(?<!\.)@[A-Z0-9.-]+\.[A-Z]{2,24}$/i
)
