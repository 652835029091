import * as Yup from 'yup'
import { IUppsagningFormValues } from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/Uppsagning/Types'
import { isAfter, isBefore, subDays } from 'date-fns'
import { toTrrDateOrDefault } from '@local/Utils/Helpers/formatDate'
import {
  calculateDate,
  dayAfterDate,
  getLatestOccuringDate,
  octoberStart,
  today,
  yesterday,
} from '@local/Utils/Helpers/Datetime.helpers'
import { email } from '@local/Utils/Helpers/regexes'
import {
  AnsokanKeys,
  ICommonAnsokanValidering,
  ICommonDatumValideringContent,
} from '@local/Types'
import { ITidsbegransadAnstallningFormValues } from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/TidsbegransadAnstallningForm/types'
import { getYupCurrentSchemaValues } from '@local/Utils/Helpers/Forms/form.helpers'

export const uppsagningValidationSchema = (
  validering: ICommonAnsokanValidering,
  datumValidering: ICommonDatumValideringContent
): Yup.ObjectSchema<IUppsagningFormValues> =>
  Yup.object().shape({
    [AnsokanKeys.SistaAnstallningsdatum]: Yup.date()
      .typeError(validering.sistaAnstallningsdatumIsRequired)
      .required(validering.sistaAnstallningsdatumIsRequired)
      .test(
        'minDateMustBeAfterLatestOccuringDate',
        datumValidering.warningMinDate,
        (date, context) => {
          const schema =
            getYupCurrentSchemaValues<ITidsbegransadAnstallningFormValues>(
              context
            )

          const datesToCompare: Date[] = [
            octoberStart,
            dayAfterDate(toTrrDateOrDefault(schema.anstallningsdatum)),
          ]

          return isAfter(
            date,
            subDays(getLatestOccuringDate(datesToCompare), 1)
          )
        }
      )
      .test(
        'minDateMustBeWithinTwoYearsFromToday',
        validering.sistaAnstallningsdatumMaxAmountExceeded,
        (date) =>
          isAfter(date, calculateDate(yesterday, { numberOfYearsFromDate: -2 }))
      )
      .test(
        'maxDateMustBeBeforeToday',
        datumValidering.warningMaxDate,
        (date) => isBefore(date, today)
      ),
    [AnsokanKeys.ForetagKontaktpersonEpostadress]: Yup.string()
      .nullable()
      .required(validering.kontaktpersonEpostIsRequired)
      .matches(email, {
        message: validering.kontaktpersonEpostInvalidFormat,
      }),
    [AnsokanKeys.ForetagKontaktpersonNamn]: Yup.string()
      .nullable()
      .required(validering.kontaktpersonNamnIsRequired),
  })

export const initialUppsagningValues: IUppsagningFormValues = {
  sistaAnstallningsdatum: null,
  foretagKontaktpersonEpostadress: null,
  foretagKontaktpersonNamn: null,
}
