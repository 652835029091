import { useAppDispatch } from '@local/Store/configureStore'
import {
  selectFormikWatchState,
  setShouldGoToNextStep,
} from '@local/Utils/Hooks/formikWatchSlice'
import { useWizard } from '@trr/wizard-library'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'

const useWizardNextStep = () => {
  const dispatch = useAppDispatch()
  const { nextStep } = useWizard()
  const { watcherIsRunning, shouldGoToNextStep } = useSelector(
    selectFormikWatchState
  )

  useEffect(() => {
    if (!watcherIsRunning && shouldGoToNextStep) {
      nextStep()
      dispatch(setShouldGoToNextStep(false))
    }
  }, [watcherIsRunning, shouldGoToNextStep, nextStep, dispatch])
}

export default useWizardNextStep
