import Gutter from '@local/Components/Gutter'
import Jour from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/LonOchErsattning/Components/Jour'
import Manadslon from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/LonOchErsattning/Components/Manadslon'
import Provision from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/LonOchErsattning/Components/Provision'
import Sjukersattningsgrad from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/LonOchErsattning/Components/Sjukersattningsgrad'
import { Grid2 as Grid } from '@mui/material'

const LonOchErsattning = () => (
  <Grid container>
    <Grid size={{ lg: 10 }} sx={{ width: 1 }}>
      <Manadslon />

      <Gutter xs={32} />

      <Provision />

      <Gutter xs={32} />

      <Jour />

      <Gutter xs={32} />

      <Sjukersattningsgrad />
    </Grid>
  </Grid>
)

export default LonOchErsattning
