import { useAppDispatch } from '@local/Store/configureStore'
import { resetStepperState } from '@local/Views/SkapaAnsokan/TjanstemanIAnstallning/tjanstemanIAnstallningStepperSlice'
import { useEffect } from 'react'

const useResetStepperState = () => {
  const dispatch = useAppDispatch()
  useEffect(
    () => () => {
      dispatch(resetStepperState())
    },
    [dispatch]
  )
}

export default useResetStepperState
