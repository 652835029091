export enum RadioChoice {
  Yes = 'true',
  No = 'false',
}

export interface ISelectOption {
  label: string
  value: string | number
  id?: string
}

export type ISelectOptionFormikWrapper = ISelectOption | string
