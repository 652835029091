import { JSX, useCallback } from 'react'
import { ISteps } from '@local/Components/Stepper/Stepper.types'
import { useSelector } from 'react-redux'
import { Grid2 as Grid, Typography } from '@mui/material'
import { usePickEpiContent } from '@local/Utils/Hooks'
import Gutter from '@local/Components/Gutter'
import {
  ITjanstemanIAnstallningContent,
  TjanstemanIAnstallningStep,
} from '@local/Views/SkapaAnsokan/TjanstemanIAnstallning/TjanstemanIAnstallning.types'
import {
  selectTjanstemanIAnstallningStepper,
  setActiveStep,
} from '@local/Views/SkapaAnsokan/TjanstemanIAnstallning/tjanstemanIAnstallningStepperSlice'
import Arbetsplats from '@local/Views/SkapaAnsokan/TjanstemanIAnstallning/Arbetsplats'
import AnstallningTIA from '@local/Views/SkapaAnsokan/TjanstemanIAnstallning/AnstallningTIA'
import ArbetslivserfarenhetTIA from '@local/Views/SkapaAnsokan/TjanstemanIAnstallning/ArbetslivserfarenhetTIA'
import GranskaAnsokanTIA from '@local/Views/SkapaAnsokan/TjanstemanIAnstallning/GranskaAnsokanTIA'
import { useAppDispatch } from '@local/Store/configureStore'
import Stepper from '@local/Components/Stepper'
import useGetSteps from '@local/Views/SkapaAnsokan/TjanstemanIAnstallning/TjanstemanIAnstallningForm/hooks/useGetSteps'
import useSetDefaultStep from '@local/Views/SkapaAnsokan/TjanstemanIAnstallning/TjanstemanIAnstallningForm/hooks/useSetDefaultStep'
import useUpdateStepParam from '@local/Views/SkapaAnsokan/TjanstemanIAnstallning/TjanstemanIAnstallningForm/hooks/useUpdateStepParam'
import useUpdateActiveStep from '@local/Views/SkapaAnsokan/TjanstemanIAnstallning/TjanstemanIAnstallningForm/hooks/useUpdateActiveStep'
import useResetStepperState from '@local/Views/SkapaAnsokan/TjanstemanIAnstallning/TjanstemanIAnstallningForm/hooks/useResetStepperState'

const useStepper = (): {
  stepper: JSX.Element
  steps: ISteps[]
  currentStepBody: JSX.Element
  currentStepHeader: JSX.Element
  nextStepPreview: JSX.Element
  currentStepTitle: JSX.Element
} => {
  const dispatch = useAppDispatch()

  const { steps: stepsContent } =
    usePickEpiContent<ITjanstemanIAnstallningContent>()

  useSetDefaultStep()
  useUpdateStepParam()
  useUpdateActiveStep()
  useResetStepperState()

  const { steps } = useGetSteps()
  const { activeStep } = useSelector(selectTjanstemanIAnstallningStepper)

  const setActiveStepCallback = useCallback(
    (step: number) => {
      dispatch(setActiveStep(step))
      window.scroll({ top: 0 })
    },
    [dispatch]
  )

  const stepper = (
    <Stepper
      steps={steps}
      activeStep={activeStep ?? 0}
      setActiveStep={setActiveStepCallback}
      orientation="vertical"
      stickyVertical
    />
  )

  const stepperContent: { [step: number]: JSX.Element } = {
    [TjanstemanIAnstallningStep.OmDinArbetsplats]: <Arbetsplats />,
    [TjanstemanIAnstallningStep.OmDinAnstallning]: <AnstallningTIA />,
    [TjanstemanIAnstallningStep.OmDinSysselsattning]: (
      <ArbetslivserfarenhetTIA />
    ),
    [TjanstemanIAnstallningStep.GranskaOchSkickaIn]: <GranskaAnsokanTIA />,
  }

  const stepperTitles: { [step: number]: string } = {
    [TjanstemanIAnstallningStep.OmDinArbetsplats]:
      stepsContent.omDinArbetsplats,
    [TjanstemanIAnstallningStep.OmDinAnstallning]:
      stepsContent.omDinAnstallning,
    [TjanstemanIAnstallningStep.OmDinSysselsattning]:
      stepsContent.omDinSysselsattning,
    [TjanstemanIAnstallningStep.GranskaOchSkickaIn]:
      stepsContent.granskaOchSkickaIn,
  }

  const nextStepPreview = (
    <Typography variant="subtitle2">
      {stepsContent.heading}
      {activeStep === TjanstemanIAnstallningStep.GranskaOchSkickaIn
        ? stepsContent.slutfordAnsokan
        : stepperTitles[activeStep + 1]}
    </Typography>
  )
  const currentStepTitle = (
    <Typography variant="h5">{stepperTitles[activeStep]}</Typography>
  )

  const currentStepBody = <>{stepperContent[activeStep]}</>

  const currentStepHeader = (
    <>
      <Grid container rowGap={4}>
        <Grid size={{ xs: 12, md: 'grow' }}>
          {currentStepTitle}

          <Gutter xs={16} />

          {nextStepPreview}
        </Grid>
      </Grid>
    </>
  )

  return {
    stepper,
    steps,
    currentStepHeader,
    currentStepTitle,
    currentStepBody,
    nextStepPreview,
  }
}

export default useStepper
