import { IAnstallningTIAFormValues } from '@local/Views/SkapaAnsokan/TjanstemanIAnstallning/AnstallningTIA/Types'
import { IArbetsplatsFormValues } from '@local/Views/SkapaAnsokan/Components/Arbetsplats/Types'
import { IGranskaAnsokanFormValues } from '@local/Views/SkapaAnsokan/Components/GranskaAnsokan/GranskaAnsokanForm.schema'
import { IAnstallningTBFormValues } from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/AnstallningTB/Types'
import { ILonOchErsattningFormValues } from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/LonOchErsattning/Types'
import { IUppsagningFormValues } from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/Uppsagning/Types'
import { ITjanstemanIAnstallningContent } from '@local/Views/SkapaAnsokan/TjanstemanIAnstallning/TjanstemanIAnstallning.types'
import { ITidsbegransadAnstallningContent } from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/TidsbegransadAnstallning.types'
import { IArbetslivserfarenhetTBFormValues } from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/ArbetslivserfarenhetTB/Types'
import { IArbetslivserfarenhetTIAFormValues } from '@local/Views/SkapaAnsokan/TjanstemanIAnstallning/ArbetslivserfarenhetTIA/Types'

export enum AnsokanDraftState {
  Utkast = 'utkast',
  Fullstandig = 'fullstandig',
  Inskickad = 'inskickad',
}

export interface IEditorialBlock {
  mainBody: string
}

export interface ILinkBlock {
  heading: string
  url: string
}

export interface ITioTiaLinkBlock {
  heading: string
  urlTio: string
  urlTia: string
  url: string
}

export interface INagotGickFelContent {
  heading: string
}

export type IPatchGickFel = INagotGickFelContent

export type IFormValues = IArbetsplatsFormValues &
  IAnstallningTIAFormValues &
  IGranskaAnsokanFormValues &
  IAnstallningTBFormValues &
  ILonOchErsattningFormValues &
  IUppsagningFormValues &
  IArbetslivserfarenhetTBFormValues &
  IArbetslivserfarenhetTIAFormValues

export interface IKeyValue {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any
}

export enum UserRoles {
  TIAOprovad = 'TIAOprovad',
  TIOOprovad = 'TIOOprovad',
}

export enum Worksituation {
  Employed = 'employed',
  Unemployed = 'unemployed',
}

export interface ILaddaUppBilaga {
  heading: string
  uploadButtonText: string
  fileDeletedError: string
  fileSizeLimitExceeded: string
  fileUploadError: string
  fileExtensionError: string
  fileUploadCannotReadFileError: string
  description: string
  tooltip: string
}

export interface IRadioChoice {
  ja: string
  nej: string
}

export interface ITjanstepersonansokanLinks {
  ansokanUrl: string
  tidsbegransadAnstallningUrl: string
  ogiltigtOrgnummerUrl: string
  tjanstemanIAnstallningUrl: string
}

export type SkapaAnsokanSharedContent = ITjanstemanIAnstallningContent &
  ITidsbegransadAnstallningContent
