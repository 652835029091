import { JSX } from 'react'
import { ITjanstemanIAnstallningFormValues } from '@local/Views/SkapaAnsokan/TjanstemanIAnstallning/TjanstemanIAnstallningForm/types'
import { isNil, isEmpty } from 'ramda'

export const getDesiredOrDefault = <
  T extends keyof ITjanstemanIAnstallningFormValues,
>(
  nullableValue: ITjanstemanIAnstallningFormValues[T],
  desired: string | number | JSX.Element
) => {
  if (isNil(nullableValue) || isEmpty(nullableValue)) {
    return '—'
  } else {
    return desired
  }
}
