import { subDays } from 'date-fns'

import { trrDate } from './formatDate'

export const startDate = trrDate(new Date(1970))
export const octoberStart = trrDate(new Date('2022-10-01'))
export const today = trrDate()
export const yesterday = trrDate(subDays(today, 1))

export const calculateDate = (
  date: Date,
  {
    numberOfYearsFromDate = 0,
    numberOfMonthsFromDate = 0,
    numberOfDaysFromDate = 0,
  }: {
    numberOfYearsFromDate?: number
    numberOfMonthsFromDate?: number
    numberOfDaysFromDate?: number
  }
) =>
  date
    ? trrDate(
        new Date(
          date.getFullYear() + numberOfYearsFromDate,
          date.getMonth() + numberOfMonthsFromDate,
          date.getDate() + numberOfDaysFromDate
        )
      )
    : null

export const dayBeforeDate = (date: Date) =>
  calculateDate(date, { numberOfDaysFromDate: -1 })

export const dayAfterDate = (date: Date) =>
  calculateDate(date, { numberOfDaysFromDate: 1 })

export const getMaxStartDate = (endDate: Date) => {
  if (endDate) {
    return endDate <= today ? dayBeforeDate(endDate) : yesterday
  } else {
    return yesterday
  }
}

/**
 * Gets the latest date from the two date inputs
 */
export const getMaxDate = (firstDate: Date, secondDate: Date) =>
  firstDate > secondDate ? firstDate : secondDate

//Function to sort list of dates from earlieast to latest
export const sortDates = (dates: Date[]) =>
  dates
    .filter((date) => date !== null)
    .sort((currentDate, nextDate) => currentDate.valueOf() - nextDate.valueOf())

// Retrieves the earliest occuring date from an array of dates
export const getEarliestOccuringDate = (dates: Date[]) => {
  const sortedDates = sortDates(dates)

  return sortedDates.length > 0 ? sortedDates[0] : null
}

// Retrieves the latest occuring date from an array of dates
export const getLatestOccuringDate = (dates: Date[]) => {
  const sortedDates = sortDates(dates)

  return sortedDates.length > 0 ? sortedDates[sortedDates.length - 1] : null
}
