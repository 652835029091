import { RadioChoice } from '@local/Types/form.types'
import { IOnboardingContent } from '@local/Views/OnboardingWrapper/Onboarding/Types/Onboarding.types'
import * as Yup from 'yup'

export interface IOnboardingFormValues {
  iAnstallning: RadioChoice
  uppsagdTyp: RadioChoice
  accepteratVillkor: boolean
}

export const onboardingValidationSchema = (
  content: IOnboardingContent
): Yup.ObjectSchema<IOnboardingFormValues> =>
  Yup.object().shape({
    iAnstallning: Yup.mixed<RadioChoice>()
      .nullable()
      .oneOf(Object.values(RadioChoice))
      .required(content.anstallning.varning),
    uppsagdTyp: Yup.mixed<RadioChoice>()
      .nullable()
      .when(['iAnstallning'], ([iAnstallning]: [RadioChoice], schema) => {
        if (iAnstallning === RadioChoice.No) {
          return schema
            .oneOf(Object.values(RadioChoice))
            .required(content.anstallning.varning)
        }
      }),
    accepteratVillkor: Yup.boolean().oneOf(
      [true],
      content.accepteraVillkor.varning
    ),
  })

export const initialOnboardingValues: IOnboardingFormValues = {
  iAnstallning: null,
  uppsagdTyp: null,
  accepteratVillkor: false,
}
