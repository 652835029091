import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useAppDispatch } from '@local/Store/configureStore'
import { TidsbegransadAnstallningStep } from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/TidsbegransadAnstallning.types'
import { setActiveStep } from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/tidsbegransadAnstallningStepperSlice'

const useUpdateActiveStep = () => {
  const dispatch = useAppDispatch()
  const history = useHistory()

  const stepParam = Number(
    new URLSearchParams(history.location.search).get('step')
  )

  useEffect(() => {
    const isValidStep = Object.values(TidsbegransadAnstallningStep).includes(
      stepParam
    )

    if (isValidStep) {
      dispatch(setActiveStep(stepParam))
    }
  }, [history.location.search, stepParam, dispatch])
}

export default useUpdateActiveStep
