import { RadioChoice } from '@local/Types/form.types'
import { EtableringsvillkorFormTypes } from '@local/Views/SkapaAnsokan/Components/Etableringsvillkor/Types'
import {
  ArbetslivserfarenhetTIAFormTypes,
  IArbetslivserfarenhetTIAFormValues,
} from '@local/Views/SkapaAnsokan/TjanstemanIAnstallning/ArbetslivserfarenhetTIA/Types'
import { IArbetslivserfarenhetTIAContent } from '@local/Views/SkapaAnsokan/TjanstemanIAnstallning/ArbetslivserfarenhetTIA/Types/content'
import * as Yup from 'yup'

export const arbetslivserfarenhetTIAValidationSchema = (
  content: IArbetslivserfarenhetTIAContent
): Yup.ObjectSchema<IArbetslivserfarenhetTIAFormValues> =>
  Yup.object().shape({
    [EtableringsvillkorFormTypes.HasIntygatEtableringsvillkor]:
      Yup.mixed<RadioChoice>().required(content.etableringsvillkor.varning),
    [ArbetslivserfarenhetTIAFormTypes.ArbetstimmarPerVecka]: Yup.number()
      .typeError(content.arbetstimmar.varning)
      .required(content.arbetstimmar.varning),
  })

export const initialArbetslivserfarenhetTIAValues: IArbetslivserfarenhetTIAFormValues =
  {
    hasIntygatEtableringsvillkor: null,
    arbetstimmarPerVecka: null,
  }
