import { RadioChoice } from '@local/Types/form.types'

export interface IArbetsplatsFormValues {
  [ArbetsplatsFormTypes.NuvarandeArbetsplats]: RadioChoice
  [ArbetsplatsFormTypes.ForetagOrganisationsnummer]: string
  [ArbetsplatsFormTypes.HarAgandeskap]: RadioChoice
  [ArbetsplatsFormTypes.Agarandel]: number
}

export enum ArbetsplatsFormTypes {
  NuvarandeArbetsplats = 'nuvarandeArbetsplats',
  ForetagOrganisationsnummer = 'foretagorganisationsnummer',
  HarAgandeskap = 'harAgandeskap',
  Agarandel = 'agarandel',
}
