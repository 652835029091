import { JSX, useEffect } from 'react'
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material'
import { useFormikContext } from 'formik'
import { isNil } from 'ramda'
import { replaceEpiVariables } from '@local/Utils/Helpers'
import { AnsokanKeys } from '@local/Types'
import { usePickEpiContent } from '@local/Utils/Hooks'
import { useGetAnsokanQuery } from '@local/Services/API/hamtaAnsokanApi'
import { RadioChoice } from '@local/Types/form.types'
import FormErrorMessage from '@local/Components/FormErrorMessage'
import Gutter from '@local/Components/Gutter'
import Spinner from '@local/Components/Spinner'
import { ITjanstemanIAnstallningFormValues } from '@local/Views/SkapaAnsokan/TjanstemanIAnstallning/TjanstemanIAnstallningForm/types'
import { ITjanstemanIAnstallningContent } from '@local/Views/SkapaAnsokan/TjanstemanIAnstallning/TjanstemanIAnstallning.types'

const NuvarandeArbetsplats = (): JSX.Element => {
  const { data, isLoading } = useGetAnsokanQuery()

  const {
    errors,
    touched,
    values,
    handleChange,
    handleBlur,
    setFieldValue,
    setFieldTouched,
  } = useFormikContext<ITjanstemanIAnstallningFormValues>()

  const {
    arbetsplats: { nuvarandeArbetsplats },
  } = usePickEpiContent<ITjanstemanIAnstallningContent>()

  useEffect(() => {
    if (values.nuvarandeArbetsplats === RadioChoice.Yes) {
      setFieldValue(
        AnsokanKeys.Organisationsnummer,
        data.foretag.organisationsnummer
      ).catch((err) => {
        console.log(err)
      })
      setFieldTouched(AnsokanKeys.Organisationsnummer, false).catch((err) => {
        console.log(err)
      })
    }
  }, [values.nuvarandeArbetsplats, setFieldValue, setFieldTouched, data])

  if (isLoading) {
    return <Spinner />
  }

  return (
    <FormControl
      error={
        touched.nuvarandeArbetsplats && !isNil(errors.nuvarandeArbetsplats)
      }
      variant="standard"
      fullWidth
    >
      <Typography variant="h6">
        {replaceEpiVariables(nuvarandeArbetsplats.titel, {
          foretag: data.foretag.namn,
        })}
      </Typography>

      <Gutter xs={16} />

      <RadioGroup
        onChange={handleChange}
        onBlur={handleBlur}
        defaultValue={values.nuvarandeArbetsplats}
      >
        <FormControlLabel
          value={RadioChoice.Yes}
          control={<Radio color="primary" />}
          label={nuvarandeArbetsplats.ja}
          name={AnsokanKeys.NuvarandeArbetsplats}
          id={`${AnsokanKeys.NuvarandeArbetsplats}.ja`}
          checked={values.nuvarandeArbetsplats === RadioChoice.Yes}
        />
        <FormControlLabel
          value={RadioChoice.No}
          control={<Radio color="primary" />}
          label={nuvarandeArbetsplats.nej}
          name={AnsokanKeys.NuvarandeArbetsplats}
          id={`${AnsokanKeys.NuvarandeArbetsplats}.nej`}
          checked={values.nuvarandeArbetsplats === RadioChoice.No}
        />
      </RadioGroup>
      {touched.nuvarandeArbetsplats && !isNil(errors.nuvarandeArbetsplats) && (
        <FormErrorMessage>{errors.nuvarandeArbetsplats}</FormErrorMessage>
      )}
    </FormControl>
  )
}

export default NuvarandeArbetsplats
