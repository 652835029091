import { IAnsokanApiModel } from '@local/Services/Types/apiTypes'
import { RadioChoice } from '@local/Types/form.types'
import { initialLonOchErsattningValues } from '@local/Views/SkapaAnsokan/TidsbegransadAnstallningOld/LonOchErsattning/LonOchErsattningForm.schema'
import {
  ILonOchErsattningFormValues,
  LonOchErsattningFormTypes,
} from '@local/Views/SkapaAnsokan/TidsbegransadAnstallningOld/LonOchErsattning/Types'

const mapAnsokanToLonOchErsattningFormValues = (
  ansokan: IAnsokanApiModel
): ILonOchErsattningFormValues => {
  let provision: RadioChoice = null
  let jour: RadioChoice = null
  let sjukersattningsgradChoice: RadioChoice = null

  if (ansokan.loneuppgifter?.provisionBelopp !== null) {
    provision =
      ansokan.loneuppgifter?.provisionBelopp > 0
        ? RadioChoice.Yes
        : RadioChoice.No
  }

  if (ansokan.loneuppgifter?.jourBelopp !== null) {
    jour =
      ansokan.loneuppgifter?.jourBelopp > 0 ? RadioChoice.Yes : RadioChoice.No
  }

  if (ansokan.loneuppgifter?.sjukersattningsgrad !== null) {
    sjukersattningsgradChoice =
      ansokan.loneuppgifter?.sjukersattningsgrad > 0
        ? RadioChoice.Yes
        : RadioChoice.No
  }

  return {
    ...initialLonOchErsattningValues,
    manadslon: ansokan.loneuppgifter?.manadslon,
    provision: provision,
    provisionBelopp: ansokan.loneuppgifter?.provisionBelopp,
    jour: jour,
    jourBelopp: ansokan.loneuppgifter?.jourBelopp,
    sjukersattningsgradChoice: sjukersattningsgradChoice,

    sjukersattningsgrad: ansokan.loneuppgifter?.sjukersattningsgrad,
  }
}

const mapLonOchAnsokanRadioChoiceToPatch = (
  name: keyof ILonOchErsattningFormValues
): keyof ILonOchErsattningFormValues => {
  switch (name) {
    case LonOchErsattningFormTypes.Provision:
      return LonOchErsattningFormTypes.ProvisionBelopp
    case LonOchErsattningFormTypes.Jour:
      return LonOchErsattningFormTypes.JourBelopp
    case LonOchErsattningFormTypes.SjukersattningsgradChoice:
      return LonOchErsattningFormTypes.Sjukersattningsgrad
    default:
      return null
  }
}

export {
  mapAnsokanToLonOchErsattningFormValues,
  mapLonOchAnsokanRadioChoiceToPatch,
}
