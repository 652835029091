import { RootState } from '@local/Store/configureStore'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface GranskaAnsokanFormModalState {
  isModalOpen: boolean
}

const initialState: GranskaAnsokanFormModalState = {
  isModalOpen: false,
}
export const granskaAnsokanFormModalSlice = createSlice({
  name: 'granskaAnsokanFormModal',
  initialState,
  reducers: {
    setIsModalOpen: (state, action: PayloadAction<boolean>) => {
      state.isModalOpen = action.payload
    },
  },
})

export const { setIsModalOpen } = granskaAnsokanFormModalSlice.actions

export const selectGranskaAnsokanFormModalState = (state: RootState) =>
  state.granskaAnsokanFormModal

export default granskaAnsokanFormModalSlice.reducer
