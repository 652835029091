import { baseApi } from '@local/Services/API/baseApi'
import getConfig from '@local/Utils/getConfig'

export interface IArbetsgivareApiModel {
  name: string
  id: string
}

export interface IGetArbetsgivareResponse {
  results: IArbetsgivareApiModel[]
}

export interface IGetArbetsgivarePayload {
  orgNummer: string
  worksituation: string
}

export const arbetsgivareApi = baseApi.injectEndpoints({
  overrideExisting: false,

  endpoints: (builder) => ({
    getArbetsgivare: builder.query<
      IGetArbetsgivareResponse,
      IGetArbetsgivarePayload
    >({
      query: ({ orgNummer, worksituation }) =>
        `${
          getConfig().PUBLIC_WEB_API_URL
        }/workplace/search/membership-by-org-number?query=${orgNummer}&worksituation=${worksituation}`,
    }),
  }),
})

export const { useLazyGetArbetsgivareQuery } = arbetsgivareApi
