import { JSX } from 'react'
import { Grid2 as Grid, styled } from '@mui/material'
import Gutter from '@local/Components/Gutter'
import { useStepper } from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/TidsbegransadAnstallningForm/TidsbegransadAnstallningStepper/hooks'
import TidsbegransadAnstallningFormSubmit from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/TidsbegransadAnstallningForm/TidsbegransadAnstallningStepper/TidsbegransadAnstallningFormSubmit'

const StyledStepperContent = styled('div')(({ theme }) => ({
  padding: `0 0 ${theme.spacing(4)} 0`,

  [theme.breakpoints.up('md')]: {
    padding: `0 0 ${theme.spacing(6)} 0`,
  },
}))

const TidsbegransadAnstallningStepper = (): JSX.Element => {
  const { stepper, currentStepBody, currentStepHeader } = useStepper()

  return (
    <>
      <Grid container columnGap={12} rowGap={3}>
        <Grid size={{ xs: 12, md: 'auto' }}>{stepper}</Grid>
        <Grid size={{ xs: 12, md: 'grow' }}>
          <StyledStepperContent>
            {currentStepHeader}

            <Gutter xs={40} />

            {currentStepBody}
          </StyledStepperContent>
        </Grid>
      </Grid>

      <Gutter xs={24} />

      <TidsbegransadAnstallningFormSubmit />
    </>
  )
}

export default TidsbegransadAnstallningStepper
