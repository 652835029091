import { useMemo } from 'react'
import { ISteps } from '@local/Components/Stepper/Stepper.types'
import { usePickEpiContent } from '@local/Utils/Hooks'
import {
  TidsbegransadAnstallningStep,
  ITidsbegransadAnstallningContent,
  TidsbegransadAnstallningStepFormikId,
} from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/TidsbegransadAnstallning.types'
import { FormikContextType, useFormikContext } from 'formik'
import { IKeyValue } from '@local/Types'
import stepsContext from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/TidsbegransadAnstallningForm/stepsContext'
import { isFieldErrorAndTouched } from '@local/Utils/Helpers/Forms/form.helpers'
import { ITidsbegransadAnstallningFormValues } from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/TidsbegransadAnstallningForm/types'

const getStepHasErrors = (
  step: TidsbegransadAnstallningStepFormikId,
  formikContext: FormikContextType<IKeyValue>
) => {
  let stepHasErrors = false
  const { touched, errors } = formikContext

  stepsContext[step].forEach((fieldInStep) => {
    if (isFieldErrorAndTouched(errors, touched, fieldInStep)) {
      stepHasErrors = true
    }
  })

  return stepHasErrors
}

const useGetSteps = () => {
  const formikContext = useFormikContext<ITidsbegransadAnstallningFormValues>()

  const { steps: stepsContent } =
    usePickEpiContent<ITidsbegransadAnstallningContent>()

  const steps: ISteps[] = useMemo(
    () => [
      {
        step: TidsbegransadAnstallningStep.OmDinArbetsplats,
        title: stepsContent.omDinArbetsplats,
        formikId: TidsbegransadAnstallningStepFormikId.OmDinArbetsplats,
        stepHasErrors: getStepHasErrors(
          TidsbegransadAnstallningStepFormikId.OmDinArbetsplats,
          formikContext
        ),
        shouldShowStep: true,
      },
      {
        step: TidsbegransadAnstallningStep.OmDinAnstallning,
        title: stepsContent.omDinAnstallning,
        formikId: TidsbegransadAnstallningStepFormikId.OmDinAnstallning,
        stepHasErrors: getStepHasErrors(
          TidsbegransadAnstallningStepFormikId.OmDinAnstallning,
          formikContext
        ),
        shouldShowStep: true,
      },
      {
        step: TidsbegransadAnstallningStep.OmDinUppsagning,
        title: stepsContent.omDinUppsagning,
        formikId: TidsbegransadAnstallningStepFormikId.OmDinUppsagning,
        stepHasErrors: getStepHasErrors(
          TidsbegransadAnstallningStepFormikId.OmDinUppsagning,
          formikContext
        ),
        shouldShowStep: true,
      },
      {
        step: TidsbegransadAnstallningStep.LonOchErsattning,
        title: stepsContent.lonOchErsattning,
        formikId: TidsbegransadAnstallningStepFormikId.LonOchErsattning,
        stepHasErrors: getStepHasErrors(
          TidsbegransadAnstallningStepFormikId.LonOchErsattning,
          formikContext
        ),
        shouldShowStep: true,
      },
      {
        step: TidsbegransadAnstallningStep.OmDinSysselsattning,
        title: stepsContent.omDinSysselsattning,
        formikId: TidsbegransadAnstallningStepFormikId.OmDinSysselsattning,
        stepHasErrors: getStepHasErrors(
          TidsbegransadAnstallningStepFormikId.OmDinSysselsattning,
          formikContext
        ),
        shouldShowStep: true,
      },
      {
        step: TidsbegransadAnstallningStep.GranskaOchSkickaIn,
        title: stepsContent.granskaOchSkickaIn,
        formikId: TidsbegransadAnstallningStepFormikId.GranskaOchSkickaIn,
        stepHasErrors: getStepHasErrors(
          TidsbegransadAnstallningStepFormikId.GranskaOchSkickaIn,
          formikContext
        ),
        shouldShowStep: true,
      },
    ],
    [stepsContent, formikContext]
  )

  return { steps }
}

export default useGetSteps
