import { JSX } from 'react'
import { useHistory } from 'react-router-dom'
import { isEmpty } from 'ramda'
import { FormikTouched, setNestedObjectValues, useFormikContext } from 'formik'
import { usePickEpiContent } from '@local/Utils/Hooks'
import { IOnboardingFormValues } from '@local/Views/OnboardingWrapper/Onboarding/OnboardingForm/OnboardingForm.schema'
import { useCreateAnsokanMutation } from '@local/Services/API/skapaAnsokanApi'
import { useGetAnsokanDraftState } from '@local/Services/API/hamtaAnsokanApi'
import {
  useGetAnsokanDraftRoute,
  useGetSkapaAnsokanRequisites,
} from '@local/Utils/Hooks/OnboardingForm'
import { ITjanstepersonAnsokanContent } from '@local/Types'
import { Button } from '@mui/material'
import { useAuthentication } from '@trr/app-shell-data'

const OnboardingFormSubmit = (): JSX.Element => {
  const { sub } = useAuthentication()
  const history = useHistory()
  const { validateForm, setTouched } = useFormikContext<IOnboardingFormValues>()
  const {
    onboarding: { skapaKnapp },
  } = usePickEpiContent<ITjanstepersonAnsokanContent>()
  const [skapaAnsokan] = useCreateAnsokanMutation({
    fixedCacheKey: 'shared-create-ansokan',
  })
  const { data: ansokanDraft } = useGetAnsokanDraftState(sub)
  const ansokanRequisites = useGetSkapaAnsokanRequisites()
  const ansokanDraftRoute = useGetAnsokanDraftRoute()

  const handleNext = () => {
    validateForm()
      .then((validationErrors) => {
        if (!isEmpty(validationErrors)) {
          setTouched(
            setNestedObjectValues<FormikTouched<IOnboardingFormValues>>(
              validationErrors,
              true
            )
          ).catch((err) => {
            console.log(err)
          })
        } else if (ansokanDraft) {
          history.push(`${ansokanDraftRoute}${ansokanDraft.id}/`)
        } else {
          skapaAnsokan(ansokanRequisites.type)
            .unwrap()
            .then((ansokanId) => {
              history.push(`${ansokanRequisites.route}${ansokanId}/`)
            })
            .catch((e) => {
              console.log(e)
            })
        }
      })
      .catch((e) => {
        console.error(e)
      })
  }

  return (
    <Button color="primary" onClick={handleNext}>
      {ansokanDraft !== null
        ? skapaKnapp.fortsattAnsokan
        : skapaKnapp.skapaAnsokan}
    </Button>
  )
}

export default OnboardingFormSubmit
