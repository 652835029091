import { getBefattningSelectOption } from '@local/Components/Befattningar/Befattning.helpers'
import { IBilagaApiModel } from '@local/Services/API/bilagorApi'
import { IAnsokanApiModel } from '@local/Services/Types/apiTypes'
import { toTrrDateOrDefault } from '@local/Utils/Helpers/formatDate'
import { booleanToRadioChoice } from '@local/Utils/Helpers/Forms/form.helpers'
import { initialAnstallningTBValues } from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/AnstallningTB/AnstallningTB.schema'
import { IAnstallningTBFormValues } from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/AnstallningTB/Types'

const mapAnsokanToAnstallningTbFormValues = (
  ansokan: IAnsokanApiModel,
  bilagor: IBilagaApiModel[]
): IAnstallningTBFormValues => {
  const { anstallningsuppgifter } = ansokan

  return {
    ...initialAnstallningTBValues,
    befattning: getBefattningSelectOption(
      anstallningsuppgifter?.befattningId,
      anstallningsuppgifter?.befattning
    ),
    isVdEllerUndantagsgrupp:
      booleanToRadioChoice(anstallningsuppgifter?.isVdEllerUndantagsgrupp) ??
      initialAnstallningTBValues.isVdEllerUndantagsgrupp,
    anstallningsdatum: anstallningsuppgifter?.anstallningsdatum
      ? toTrrDateOrDefault(anstallningsuppgifter?.anstallningsdatum)
      : initialAnstallningTBValues.anstallningsdatum,
    arbetstimmarPerVecka: anstallningsuppgifter?.arbetstimmarPerVecka,
    anstallningsgrad: anstallningsuppgifter?.anstallningsgrad,
    anstallningBilagor: bilagor.map((bilaga) => ({
      id: bilaga.fileId,
      name: bilaga.name,
    })),
  } as IAnstallningTBFormValues
}

export { mapAnsokanToAnstallningTbFormValues }
