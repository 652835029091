import * as Yup from 'yup'
import { RadioChoice } from '@local/Types/form.types'
import {
  countDecimals,
  validateOrganizationNumber,
} from '@local/Utils/Helpers/Forms/form.helpers'
import { orgNummerRegex } from '@local/Utils/Helpers/regexes'
import {
  IArbetsplatsFormValues,
  ArbetsplatsFormTypes,
} from '@local/Views/SkapaAnsokan/Components/Arbetsplats/Types'
import { IArbetsplatsContent } from '@local/Views/SkapaAnsokan/Components/Arbetsplats/Types/content'

export const arbetsplatsValidationSchema = (
  content: IArbetsplatsContent
): Yup.ObjectSchema<IArbetsplatsFormValues> =>
  Yup.object().shape({
    [ArbetsplatsFormTypes.NuvarandeArbetsplats]: Yup.mixed<RadioChoice>()
      .oneOf(Object.values(RadioChoice))
      .required(content.nuvarandeArbetsplats.varning),
    [ArbetsplatsFormTypes.ForetagOrganisationsnummer]: Yup.string().when(
      [ArbetsplatsFormTypes.NuvarandeArbetsplats],
      ([nuvarandeArbetsplats]: [RadioChoice], schema) => {
        if (nuvarandeArbetsplats === RadioChoice.No) {
          return schema
            .required(content.organisationsNummer.orgnummerIsRequired)
            .trim()
            .matches(
              orgNummerRegex,
              content.organisationsNummer.orgnummerHasInvalidFormat
            )
            .test(
              'validateOrgnummer',
              content.organisationsNummer.orgnummerIsInvalid,
              (orgNummer) => validateOrganizationNumber(orgNummer)
            )
            .typeError(content.organisationsNummer.orgnummerIsInvalid)
        }
      }
    ),
    [ArbetsplatsFormTypes.HarAgandeskap]: Yup.mixed<RadioChoice>()
      .oneOf(Object.values(RadioChoice))
      .required(content.agandedel.varning),
    [ArbetsplatsFormTypes.Agarandel]: Yup.number()
      .nullable()
      .when(
        [ArbetsplatsFormTypes.HarAgandeskap],
        ([harAgandeskap]: [RadioChoice], schema) => {
          if (harAgandeskap === RadioChoice.Yes) {
            return schema
              .required(content.agandeAndel.varning)
              .positive(content.agandeAndel.varningNegativt)
              .max(100, content.agandeAndel.varningHogtVarde)
              .test(
                'len',
                content.agandeAndel.varningDecimaler,
                (value: number) => countDecimals(value) <= 2
              )
              .typeError(content.agandeAndel.varning)
          }
        }
      ),
  })

export const initialArbetsplatsValues: IArbetsplatsFormValues = {
  nuvarandeArbetsplats: undefined,
  foretagorganisationsnummer: undefined,
  harAgandeskap: undefined,
  agarandel: null,
}
