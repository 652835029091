import { IAnsokanPatchApiModel } from '@local/Services/Types/apiTypes'
import { AnsokanKeys } from '@local/Types'
import { ISelectOption, RadioChoice } from '@local/Types/form.types'
import { trrDateUTC } from '@local/Utils/Helpers/formatDate'
import { IMiddlewareField } from '@local/Utils/Hooks/useFormikWatch'
import { GranskaAnsokanFormKeys } from '@local/Views/SkapaAnsokan/TjanstemanIAnstallning/GranskaAnsokan/Components/GranskaAnsokanFormModal/types'
import { ITjanstemanIAnstallningFormValues } from '@local/Views/SkapaAnsokan/TjanstemanIAnstallning/TjanstemanIAnstallningForm/types'

//Middleware to be used by formik watcher to transform data before patching to API
export const tjanstemanIAnstallningWatcherMiddleware = (
  field: IMiddlewareField<ITjanstemanIAnstallningFormValues>,
  defaultMiddleware: (
    field: IMiddlewareField<ITjanstemanIAnstallningFormValues>
  ) => Partial<IAnsokanPatchApiModel>
): Partial<IAnsokanPatchApiModel> => {
  switch (field.id as AnsokanKeys | GranskaAnsokanFormKeys) {
    case GranskaAnsokanFormKeys.ForsakratUppgifter:
    case AnsokanKeys.NuvarandeArbetsplats:
    case AnsokanKeys.Organisationsnummer:
    case AnsokanKeys.OrganisationsnummerIsAffiliated:
    case AnsokanKeys.AnstallningBilagor:
      return

    case AnsokanKeys.HarAgandeskap: {
      if (field.value === RadioChoice.No) return { agarandel: null }
      else return
    }

    case AnsokanKeys.Anstallningsdatum:
      return {
        anstallningsdatum: trrDateUTC(field.value as Date).toISOString(),
      }

    case AnsokanKeys.Befattning:
      return {
        befattning: (field.value as ISelectOption).label,
        befattningId: (field.value as ISelectOption).id,
      }

    default:
      return defaultMiddleware(field)
  }
}
