import { usePickEpiContent, useGtmWizardTracker } from '@local/Utils/Hooks'
import { ITjanstemanIAnstallningContent } from '@local/Views/SkapaAnsokan/TjanstemanIAnstallningOld/TjanstemanIAnstallning.types'
import { useGetAnsokanQuery } from '@local/Services/API/hamtaAnsokanApi'
import ArbetslivserfarenhetTIAForm from '@local/Views/SkapaAnsokan/TjanstemanIAnstallningOld/ArbetslivserfarenhetTIA/ArbetslivserfarenhetTIAForm'
import Spinner from '@local/Components/Spinner'

const ArbetslivserfarenhetTIA = () => {
  const { arbetslivserfarenhet } =
    usePickEpiContent<ITjanstemanIAnstallningContent>()
  useGtmWizardTracker('TIA-Ansökan')

  const { data, isLoading } = useGetAnsokanQuery(undefined, {
    refetchOnMountOrArgChange: true,
  })

  if (isLoading) {
    return <Spinner centered />
  }

  return (
    <ArbetslivserfarenhetTIAForm
      content={arbetslivserfarenhet}
      ansokan={data}
    />
  )
}

export default ArbetslivserfarenhetTIA
