import { useLazyGetArbetsgivareQuery } from '@local/Services/API/arbetsgivareApi'
import { useGetAnsokanQuery } from '@local/Services/API/hamtaAnsokanApi'
import { Worksituation } from '@local/Types'
import { RadioChoice } from '@local/Types/form.types'
import { ITidsbegransadAnstallningFormValues } from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/TidsbegransadAnstallningForm/types'
import { useFormikContext } from 'formik'

const useGetArbetsgivare = () => {
  const [getArbetsgivare] = useLazyGetArbetsgivareQuery()

  const { data } = useGetAnsokanQuery()

  const { values } = useFormikContext<ITidsbegransadAnstallningFormValues>()

  const isNuvarandeArbetsplats = values.nuvarandeArbetsplats === RadioChoice.Yes

  return () =>
    getArbetsgivare({
      orgNummer: isNuvarandeArbetsplats
        ? data?.foretag?.organisationsnummer
        : values.foretagorganisationsnummer,
      worksituation: Worksituation.Unemployed,
    })
}

export default useGetArbetsgivare
