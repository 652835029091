import { JSX, useCallback } from 'react'
import { ISteps } from '@local/Components/Stepper/Stepper.types'
import { useSelector } from 'react-redux'
import { Grid2 as Grid, Typography } from '@mui/material'
import { usePickEpiContent } from '@local/Utils/Hooks'
import Gutter from '@local/Components/Gutter'
import { useAppDispatch } from '@local/Store/configureStore'
import Stepper from '@local/Components/Stepper'
import {
  ITidsbegransadAnstallningContent,
  TidsbegransadAnstallningStep,
} from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/TidsbegransadAnstallning.types'
import useSetDefaultStep from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/TidsbegransadAnstallningForm/hooks/useSetDefaultStep'
import useUpdateStepParam from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/TidsbegransadAnstallningForm/hooks/useUpdateStepParam'
import useGetSteps from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/TidsbegransadAnstallningForm/hooks/useGetSteps'
import {
  selectTidsbegransadAnstallningStepper,
  setActiveStep,
} from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/tidsbegransadAnstallningStepperSlice'
import Arbetsplats from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/Arbetsplats'
import AnstallningTB from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/AnstallningTB'
import Uppsagning from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/Uppsagning'
import LonOchErsattning from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/LonOchErsattning'
import ArbetslivserfarenhetTB from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/ArbetslivserfarenhetTB'
import GranskaAnsokanTB from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/GranskaAnsokanTB'
import useUpdateActiveStep from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/TidsbegransadAnstallningForm/hooks/useUpdateActiveStep'
import useResetStepperState from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/TidsbegransadAnstallningForm/hooks/useResetStepperState'

const useStepper = (): {
  stepper: JSX.Element
  steps: ISteps[]
  currentStepBody: JSX.Element
  currentStepHeader: JSX.Element
  nextStepPreview: JSX.Element
  currentStepTitle: JSX.Element
} => {
  const dispatch = useAppDispatch()
  const { steps: stepsContent } =
    usePickEpiContent<ITidsbegransadAnstallningContent>()

  useSetDefaultStep()
  useUpdateStepParam()
  useUpdateActiveStep()
  useResetStepperState()

  const { steps } = useGetSteps()
  const { activeStep } = useSelector(selectTidsbegransadAnstallningStepper)

  const setActiveStepCallback = useCallback(
    (step: number) => {
      dispatch(setActiveStep(step))
      window.scroll({ top: 0 })
    },
    [dispatch]
  )

  const stepper = (
    <Stepper
      steps={steps}
      activeStep={activeStep ?? 0}
      setActiveStep={setActiveStepCallback}
      orientation="vertical"
      stickyVertical
    />
  )

  const stepperContent: { [step: number]: JSX.Element } = {
    [TidsbegransadAnstallningStep.OmDinArbetsplats]: <Arbetsplats />,
    [TidsbegransadAnstallningStep.OmDinAnstallning]: <AnstallningTB />,
    [TidsbegransadAnstallningStep.OmDinUppsagning]: <Uppsagning />,
    [TidsbegransadAnstallningStep.LonOchErsattning]: <LonOchErsattning />,
    [TidsbegransadAnstallningStep.OmDinSysselsattning]: (
      <ArbetslivserfarenhetTB />
    ),
    [TidsbegransadAnstallningStep.GranskaOchSkickaIn]: <GranskaAnsokanTB />,
  }

  const stepperTitles: { [step: number]: string } = {
    [TidsbegransadAnstallningStep.OmDinArbetsplats]:
      stepsContent.omDinArbetsplats,
    [TidsbegransadAnstallningStep.OmDinAnstallning]:
      stepsContent.omDinAnstallning,
    [TidsbegransadAnstallningStep.OmDinUppsagning]:
      stepsContent.omDinUppsagning,
    [TidsbegransadAnstallningStep.LonOchErsattning]:
      stepsContent.lonOchErsattning,
    [TidsbegransadAnstallningStep.OmDinSysselsattning]:
      stepsContent.omDinSysselsattning,
    [TidsbegransadAnstallningStep.GranskaOchSkickaIn]:
      stepsContent.granskaOchSkickaIn,
  }

  const nextStepPreview = (
    <Typography variant="subtitle2">
      {stepsContent.heading}
      {activeStep === TidsbegransadAnstallningStep.GranskaOchSkickaIn
        ? stepsContent.slutfordAnsokan
        : stepperTitles[activeStep + 1]}
    </Typography>
  )
  const currentStepTitle = (
    <Typography variant="h5">{stepperTitles[activeStep]}</Typography>
  )

  const currentStepBody = <>{stepperContent[activeStep]}</>

  const currentStepHeader = (
    <>
      <Grid container rowGap={4}>
        <Grid size={{ xs: 12, md: 'grow' }}>
          {currentStepTitle}

          <Gutter xs={16} />

          {nextStepPreview}
        </Grid>
      </Grid>
    </>
  )

  return {
    stepper,
    steps,
    currentStepHeader,
    currentStepTitle,
    currentStepBody,
    nextStepPreview,
  }
}

export default useStepper
