import * as Yup from 'yup'
import { RadioChoice } from '@local/Types/form.types'
import {
  countDecimals,
  getYupCurrentSchemaValues,
  validateOrganizationNumber,
} from '@local/Utils/Helpers/Forms/form.helpers'
import { orgNummerRegex } from '@local/Utils/Helpers/regexes'
import { IArbetsplatsFormValues } from '@local/Views/SkapaAnsokan/TjanstemanIAnstallning/Arbetsplats/Types'
import { AnsokanKeys, ICommonAnsokanValidering } from '@local/Types'
import { ITjanstemanIAnstallningFormValues } from '@local/Views/SkapaAnsokan/TjanstemanIAnstallning/TjanstemanIAnstallningForm/types'

export const arbetsplatsValidationSchema = (
  validering: ICommonAnsokanValidering
) =>
  Yup.object().shape({
    [AnsokanKeys.NuvarandeArbetsplats]: Yup.mixed<RadioChoice>().required(
      validering.nuvarandeArbetsplatsIsRequired
    ),
    [AnsokanKeys.Organisationsnummer]: Yup.string()
      .nullable()
      .when(
        [AnsokanKeys.NuvarandeArbetsplats],
        ([nuvarandeArbetsplats]: [RadioChoice], schema) => {
          if (nuvarandeArbetsplats === RadioChoice.No) {
            return schema
              .required(validering.orgnummerIsRequired)
              .trim()
              .matches(orgNummerRegex, validering.orgnummerHasInvalidFormat)
              .test(
                'validateOrgnummer',
                validering.orgnummerIsInvalid,
                (orgNummer) => validateOrganizationNumber(orgNummer)
              )
              .test(
                'orgnummerHasToBeAffiliated',
                validering.orgnummerIsNotAffiliated,
                (_, context) => {
                  const schema =
                    getYupCurrentSchemaValues<ITjanstemanIAnstallningFormValues>(
                      context
                    )

                  return schema.organisationsnummerIsAffiliated ===
                    RadioChoice.No
                    ? false
                    : true
                }
              )
              .typeError(validering.orgnummerIsInvalid)
          }
        }
      ),
    [AnsokanKeys.HarAgandeskap]: Yup.mixed<RadioChoice>()
      .nullable()
      .required(validering.harAgarandelIsRequired),
    [AnsokanKeys.Agarandel]: Yup.number()
      .nullable()
      .when(
        [AnsokanKeys.HarAgandeskap],
        ([harAgandeskap]: [RadioChoice], schema) => {
          if (harAgandeskap === RadioChoice.Yes) {
            return schema
              .required(validering.agarandelIsRequired)
              .positive(validering.agarandelMinAmountRequired)
              .max(100, validering.agarandelMaxAmountExceeded)
              .test(
                'len',
                validering.agarandelInvalidFormat,
                (value: number) => countDecimals(value) <= 2
              )
              .typeError(validering.agarandelIsRequired)
          }
        }
      ),
  })

export const initialArbetsplatsValues: IArbetsplatsFormValues = {
  nuvarandeArbetsplats: undefined,
  foretagorganisationsnummer: undefined,
  harAgandeskap: undefined,
  agarandel: null,
  organisationsnummerIsAffiliated: RadioChoice.Yes,
}
