import { JSX } from 'react'
import { usePickEpiContent } from '@local/Utils/Hooks'
import { ITjanstemanIAnstallningContent } from '@local/Views/SkapaAnsokan/TjanstemanIAnstallning/TjanstemanIAnstallning.types'
import { useFormikContext } from 'formik'
import { IAnstallningTIAFormValues } from '@local/Views/SkapaAnsokan/TjanstemanIAnstallning/AnstallningTIA/Types'
import { AnstallningFormTypes } from '@local/Views/SkapaAnsokan/Types'
import HTMLMapper from '@local/Components/HTMLMapper'
import Tooltip from '@local/Components/Tooltip'
import NumberInput from '@local/Components/NumberInput'

const Manadslon = (): JSX.Element => {
  const {
    anstallning: { manadslon, tooltipManadslon },
  } = usePickEpiContent<ITjanstemanIAnstallningContent>()

  const formikContext = useFormikContext<IAnstallningTIAFormValues>()

  return (
    <>
      <Tooltip
        heading={manadslon.titel}
        tooltipContent={<HTMLMapper body={tooltipManadslon.mainBody} />}
      />

      <NumberInput
        formikContext={formikContext}
        label={manadslon.textFaltTitel}
        name={AnstallningFormTypes.Manadslon}
      />
    </>
  )
}

export default Manadslon
