import { CreateAnsokanTypes } from '@local/Services/Types/apiTypes'
import { ITjanstepersonAnsokanContent } from '@local/Types'
import useIsTIA from '@local/Utils/Hooks/OnboardingForm/useIsTIA'
import usePickEpiContent from '@local/Utils/Hooks/usePickEpiContent'

interface ISkapaAnsokanRequisites {
  type: CreateAnsokanTypes
  route: string
}

const useGetSkapaAnsokanRequisites = (): ISkapaAnsokanRequisites => {
  const { tjanstepersonansokanLinks } =
    usePickEpiContent<ITjanstepersonAnsokanContent>()
  const isTIA = useIsTIA()

  return {
    type: isTIA
      ? CreateAnsokanTypes.Anstalld
      : CreateAnsokanTypes.Tidsbegransad,
    route: isTIA
      ? tjanstepersonansokanLinks.tjanstemanIAnstallningUrl
      : tjanstepersonansokanLinks.tidsbegransadAnstallningUrl,
  }
}

export default useGetSkapaAnsokanRequisites
