import { AnsokanKeys } from '@local/Types'
import { GranskaAnsokanFormKeys } from '@local/Views/SkapaAnsokan/TjanstemanIAnstallning/GranskaAnsokan/Components/GranskaAnsokanFormModal/types'
import { TjanstemanIAnstallningStepFormikId } from '@local/Views/SkapaAnsokan/TjanstemanIAnstallning/TjanstemanIAnstallning.types'

interface IStepsContext {
  [TjanstemanIAnstallningStepFormikId.OmDinArbetsplats]: AnsokanKeys[]
  [TjanstemanIAnstallningStepFormikId.OmDinAnstallning]: AnsokanKeys[]
  [TjanstemanIAnstallningStepFormikId.OmDinSysselsattning]: AnsokanKeys[]
  [TjanstemanIAnstallningStepFormikId.GranskaOchSkickaIn]: GranskaAnsokanFormKeys[]
}

const stepsContext: IStepsContext = {
  [TjanstemanIAnstallningStepFormikId.OmDinArbetsplats]: [
    AnsokanKeys.NuvarandeArbetsplats,
    AnsokanKeys.Organisationsnummer,
    AnsokanKeys.HarAgandeskap,
    AnsokanKeys.Agarandel,
  ],
  [TjanstemanIAnstallningStepFormikId.OmDinAnstallning]: [
    AnsokanKeys.Befattning,
    AnsokanKeys.IsVdEllerUndantagsgrupp,
    AnsokanKeys.Anstallningsdatum,
    AnsokanKeys.Manadslon,
    AnsokanKeys.AnstallningBilagor,
  ],
  [TjanstemanIAnstallningStepFormikId.OmDinSysselsattning]: [
    AnsokanKeys.HasIntygatEtableringsvillkor,
    AnsokanKeys.ArbetstimmarPerVecka,
  ],
  [TjanstemanIAnstallningStepFormikId.GranskaOchSkickaIn]: [
    GranskaAnsokanFormKeys.ForsakratUppgifter,
  ],
}

export default stepsContext
