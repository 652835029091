import { useCallback } from 'react'
import {
  FormikTouched,
  setNestedObjectValues,
  useFormikContext,
  withFormik,
} from 'formik'
import { useAppDispatch } from '@local/Store/configureStore'
import { usePatchAnsokanMutation } from '@local/Services/API/skapaAnsokanApi'
import { useWizard, WizardStep } from '@trr/wizard-library'
import { isEmpty } from 'ramda'
import { setShouldGoToNextStep } from '@local/Utils/Hooks/formikWatchSlice'
import {
  useFormikWatch,
  usePickEpiContent,
  useWizardNextStep,
} from '@local/Utils/Hooks'
import { ITjanstemanIAnstallningContent } from '@local/Views/SkapaAnsokan/TjanstemanIAnstallningOld/TjanstemanIAnstallning.types'
import { IArbetslivserfarenhetTBFormValues } from '@local/Views/SkapaAnsokan/TidsbegransadAnstallningOld/ArbetslivserfarenhetTB/Types'
import { arbetslivserfarenhetTBMiddleware } from '@local/Views/SkapaAnsokan/TidsbegransadAnstallningOld/ArbetslivserfarenhetTB/arbetslivserfarenhetTBMiddleware'
import Etableringsvillkor from '@local/Views/SkapaAnsokan/Components/Etableringsvillkor'
import {
  IArbetslivserfarenhetTBContent,
  IArbetslivserfarenhetTBFormProps,
} from '@local/Views/SkapaAnsokan/TidsbegransadAnstallningOld/ArbetslivserfarenhetTB/Types/content'
import { mapAnsokanToArbetslivserfarenhetTBFormValues } from '@local/Views/SkapaAnsokan/TidsbegransadAnstallningOld/ArbetslivserfarenhetTB/Helpers'
import { arbetslivserfarenhetTBValidationSchema } from '@local/Views/SkapaAnsokan/TidsbegransadAnstallningOld/ArbetslivserfarenhetTB/ArbetslivserfarenhetTBForm.schema'
import { Grid2 as Grid } from '@mui/material'

const ArbetslivserfarenhetTBForm = () => {
  const dispatch = useAppDispatch()
  const [patchAnsokan] = usePatchAnsokanMutation({
    fixedCacheKey: 'patchAnsokan',
  })
  const { stepCount, activeStep } = useWizard()

  const formikContext = useFormikContext<IArbetslivserfarenhetTBFormValues>()
  const { setTouched, validateForm } = formikContext

  const handleNext = () => {
    validateForm()
      .then((validationErrors) => {
        if (!isEmpty(validationErrors)) {
          setTouched(
            setNestedObjectValues<
              FormikTouched<IArbetslivserfarenhetTBFormValues>
            >(validationErrors, true)
          ).catch((err) => {
            console.log(err)
          })
        } else {
          dispatch(setShouldGoToNextStep(true))
        }
      })
      .catch((e) => {
        console.error(e)
      })
  }

  const {
    arbetslivserfarenhet: { stegGuide },
  } = usePickEpiContent<ITjanstemanIAnstallningContent>()

  const patchAnsokanCallback = useCallback(
    async (values: Partial<IArbetslivserfarenhetTBFormValues>) => {
      await patchAnsokan({ values })
    },
    [patchAnsokan]
  )

  useFormikWatch(arbetslivserfarenhetTBMiddleware, patchAnsokanCallback)
  useWizardNextStep()
  return (
    <Grid container>
      <Grid size={{ lg: 8 }} sx={{ width: 1 }}>
        <WizardStep
          progressBar={{ max: stepCount - 1, current: activeStep + 1 }}
          stepInfo={{
            currentStepLabel: stegGuide.nuvarandeSteg,
            nextStepLabel: stegGuide.nastaSteg,
          }}
          nextButton={{
            onClick: () => {
              handleNext()
            },
          }}
          prevButton={{}}
        >
          <Etableringsvillkor />
        </WizardStep>
      </Grid>
    </Grid>
  )
}

const ArbetslivserfarenhetTBFormik = withFormik<
  IArbetslivserfarenhetTBFormProps,
  IArbetslivserfarenhetTBFormValues
>({
  validateOnBlur: true,
  enableReinitialize: true,
  mapPropsToValues: ({ ansokan }) =>
    mapAnsokanToArbetslivserfarenhetTBFormValues(ansokan),
  displayName: 'ArbetslivserfarenhetTBForm',
  handleSubmit: (_, { setSubmitting }) => {
    setSubmitting(false)
  },
  validationSchema: ({
    content,
  }: {
    content: IArbetslivserfarenhetTBContent
  }) => arbetslivserfarenhetTBValidationSchema(content),
})(ArbetslivserfarenhetTBForm)

export default ArbetslivserfarenhetTBFormik
