import { baseApi } from '@local/Services/API/baseApi'
import { mapAnsokanValuesToPatchModel } from '@local/Services/Helpers'
import { CreateAnsokanTypes } from '@local/Services/Types/apiTypes'
import { IPatchApiInput } from '@local/Services/Types/patchTypes'
import { getAnsokanId } from '@local/Utils/Helpers'

export const skapaAnsokanApi = baseApi.injectEndpoints({
  overrideExisting: false,

  endpoints: (builder) => ({
    createAnsokan: builder.mutation<string, CreateAnsokanTypes>({
      query: (typeOfAnsokan) => ({
        url: `/ansokan`,
        method: 'POST',
        body: {
          ansokanTyp: typeOfAnsokan,
        },
      }),
      invalidatesTags: ['ansokan', 'ansokanDraft'],
    }),
    patchAnsokan: builder.mutation<void, IPatchApiInput>({
      query: ({ values }) => ({
        url: `/ansokan/${getAnsokanId()}`,
        method: 'PATCH',
        body: mapAnsokanValuesToPatchModel(values),
      }),
      invalidatesTags: (_response, _error, { isMuiStepperEnabled = false }) =>
        isMuiStepperEnabled ? ['ansokan'] : [],
    }),
    deleteAnsokan: builder.mutation<Response, string>({
      query: (ansokanId) => ({
        url: `/ansokan/${ansokanId}/draft`,
        method: 'DELETE',
      }),
      invalidatesTags: ['ansokan', 'ansokanDraft'],
    }),
    skickaInAnsokan: builder.mutation<void, string>({
      query: (villkorstext) => ({
        url: `/ansokan/skickain/tjansteperson`,
        method: 'POST',
        body: {
          ansokanId: getAnsokanId(),
          villkorstext: villkorstext,
        },
      }),
      invalidatesTags: ['ansokan', 'kanAnsoka'],
    }),
  }),
})

export const {
  useCreateAnsokanMutation,
  usePatchAnsokanMutation,
  useDeleteAnsokanMutation,
  useSkickaInAnsokanMutation,
} = skapaAnsokanApi
