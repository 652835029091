import React from 'react'
import { Redirect } from 'react-router-dom'
import {
  useGetAnsokanDraftState,
  useKanAnsokaQuery,
} from '@local/Services/API/hamtaAnsokanApi'
import { SkapaAnsokanSharedContent } from '@local/Types'
import { usePickEpiContent } from '@local/Utils/Hooks'
import { useAuthentication } from '@trr/app-shell-data'
import Spinner from '@local/Components/Spinner'

const StepperGuard: React.FunctionComponent<React.PropsWithChildren> = ({
  children,
}) => {
  const { sub } = useAuthentication()
  const { isUninitialized, isLoading: isLoadingDraft } =
    useGetAnsokanDraftState(sub)
  const { data: kanAnsoka, isLoading: isLoadingKanAnsoka } = useKanAnsokaQuery()
  const { tjanstepersonansokanLinks } =
    usePickEpiContent<SkapaAnsokanSharedContent>()

  if (isLoadingDraft || isLoadingKanAnsoka) {
    return <Spinner />
  }

  if (isUninitialized || (typeof kanAnsoka === 'boolean' && !kanAnsoka)) {
    return <Redirect to={tjanstepersonansokanLinks.ansokanUrl} />
  }

  return <>{children}</>
}

export default StepperGuard
