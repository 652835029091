import { JSX, useEffect, useState } from 'react'
import { usePickEpiContent } from '@local/Utils/Hooks'
import { ITjanstemanIAnstallningContent } from '@local/Views/SkapaAnsokan/TjanstemanIAnstallning/TjanstemanIAnstallning.types'
import { usePatchAnsokanMutation } from '@local/Services/API/skapaAnsokanApi'
import Toaster from '@local/Components/Toaster'
import TjanstemanIAnstallningForm from '@local/Views/SkapaAnsokan/TjanstemanIAnstallning/TjanstemanIAnstallningForm'
import StepperGuard from '@local/Components/StepperGuard/StepperGuard'
import { useGetAnsokanQuery } from '@local/Services/API/hamtaAnsokanApi'
import { useAuthentication } from '@trr/app-shell-data'
import { useGetAllIntygBilagaQuery } from '@local/Services/API/bilagorApi'
import Spinner from '@local/Components/Spinner'

const TjanstemanIAnstallning = (): JSX.Element => {
  const { sub } = useAuthentication()
  const [toasterOpen, setToasterOpen] = useState(false)
  const content = usePickEpiContent<ITjanstemanIAnstallningContent>()
  const [, { isError: isErrorPatchAnsokan }] = usePatchAnsokanMutation({
    fixedCacheKey: 'patchAnsokan',
  })

  const { data: ansokan, isLoading: isLoadingAnsokan } = useGetAnsokanQuery()

  const { data: bilagor, isLoading: isLoadingBilagor } =
    useGetAllIntygBilagaQuery(sub)

  useEffect(() => {
    if (isErrorPatchAnsokan) {
      setToasterOpen(true)
    } else {
      setToasterOpen(false)
    }
  }, [isErrorPatchAnsokan])

  if (isLoadingAnsokan || isLoadingBilagor) {
    return <Spinner centered />
  }

  return (
    <>
      <Toaster
        message={content.patchGickFel.heading}
        open={toasterOpen}
        setOpen={setToasterOpen}
      />

      <StepperGuard>
        <TjanstemanIAnstallningForm
          content={content}
          bilagor={bilagor}
          ansokan={ansokan}
        />
      </StepperGuard>
    </>
  )
}
export default TjanstemanIAnstallning
