import { JSX, useEffect } from 'react'
import { useFormikContext } from 'formik'
import {
  ILonOchErsattningFormValues,
  LonOchErsattningFormTypes,
} from '@local/Views/SkapaAnsokan/TidsbegransadAnstallningOld/LonOchErsattning/Types'
import { usePickEpiContent } from '@local/Utils/Hooks'
import Gutter from '@local/Components/Gutter'
import { RadioChoice } from '@local/Types/form.types'
import { ITidsbegransadAnstallningContent } from '@local/Views/SkapaAnsokan/TidsbegransadAnstallningOld/TidsbegransadAnstallning.types'
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material'
import FormErrorMessage from '@local/Components/FormErrorMessage'
import NumberInput from '@local/Components/NumberInput'
import { isNil } from 'ramda'
import Tooltip from '@local/Components/Tooltip'
import HTMLMapper from '@local/Components/HTMLMapper'

const Jour = (): JSX.Element => {
  const {
    lonOchErsattning: { jour, jourBelopp, tooltipJour },
  } = usePickEpiContent<ITidsbegransadAnstallningContent>()

  const formikContext = useFormikContext<ILonOchErsattningFormValues>()
  const {
    handleChange,
    handleBlur,
    errors,
    touched,
    values,
    setFieldValue,
    setFieldTouched,
  } = formikContext

  useEffect(() => {
    if (values.jour === RadioChoice.No) {
      setFieldValue(LonOchErsattningFormTypes.JourBelopp, null)?.catch(
        (err) => {
          console.log(err)
        }
      )
      setFieldTouched(LonOchErsattningFormTypes.JourBelopp, false)?.catch(
        (err) => {
          console.log(err)
        }
      )
    }
  }, [values.jour, setFieldValue, setFieldTouched])

  const isError = touched.jour && !isNil(errors.jour)

  return (
    <FormControl error={isError} variant="standard" fullWidth>
      <Tooltip
        heading={jour.titel}
        tooltipContent={<HTMLMapper body={tooltipJour.mainBody} />}
      />

      <RadioGroup
        onChange={handleChange}
        onBlur={handleBlur}
        defaultValue={values.jour}
      >
        <FormControlLabel
          value={RadioChoice.Yes}
          control={<Radio color="primary" />}
          label={jour.ja}
          name={LonOchErsattningFormTypes.Jour}
          id={`${LonOchErsattningFormTypes.Jour}.ja`}
        />
        <FormControlLabel
          value={RadioChoice.No}
          control={<Radio color="primary" />}
          label={jour.nej}
          name={LonOchErsattningFormTypes.Jour}
          id={`${LonOchErsattningFormTypes.Jour}.nej`}
        />
      </RadioGroup>

      {isError && <FormErrorMessage>{errors.jour}</FormErrorMessage>}

      {values.jour === RadioChoice.Yes && (
        <>
          <Gutter xs={32} />

          <Typography variant="h6">{jourBelopp.titel}</Typography>

          <Gutter xs={16} />

          <NumberInput
            formikContext={formikContext}
            label={jourBelopp.textFaltTitel}
            name={LonOchErsattningFormTypes.JourBelopp}
          />
        </>
      )}
    </FormControl>
  )
}

export default Jour
