import { JSX, useEffect, useState } from 'react'
import { useDebounce } from '@local/Utils/Hooks'
import { useGetBefattningarQuery } from '@local/Services/API/befattningApi'
import {
  ISelectOption,
  ISelectOptionFormikWrapper,
} from '@local/Types/form.types'
import {
  Autocomplete as MuiAutoComplete,
  InputAdornment,
  TextField,
} from '@mui/material'
import { FormikContextType } from 'formik'
import SearchIcon from '@mui/icons-material/Search'
import parse from 'autosuggest-highlight/parse'
import match from 'autosuggest-highlight/match'
import { isNil } from 'ramda'

import { getBefattningSelectOption } from './Befattning.helpers'

interface IBefattning {
  befattning: ISelectOptionFormikWrapper
}

export interface IAutocompleteProps {
  label?: string
  placeholder?: string
  formikName: string
  value: ISelectOptionFormikWrapper
  onChange: (value: ISelectOptionFormikWrapper) => void
  formikContext: FormikContextType<IBefattning>
}

const Autocomplete = ({
  label,
  placeholder,
  formikName,
  value,
  onChange,
  formikContext,
}: IAutocompleteProps): JSX.Element => {
  const [searchTerm, setSearchTerm] = useState('')
  const [shouldGetJobs, setShouldGetJobs] = useState(false)
  const debouncedSearchTerm = useDebounce(searchTerm, 300)

  const [selectOptions, setSelectOptions] = useState<ISelectOption[]>(
    value ? [value as ISelectOption] : []
  )

  const { currentData: jobs } = useGetBefattningarQuery(debouncedSearchTerm, {
    skip: !shouldGetJobs,
  })

  const handleChange = (_: unknown, value: ISelectOption) => {
    if (value) {
      onChange({ ...value, label: String(value.value) })
    }
  }

  useEffect(() => {
    if (debouncedSearchTerm?.trim().length > 1) {
      setShouldGetJobs(true)
    } else {
      setShouldGetJobs(false)
    }
  }, [debouncedSearchTerm])

  useEffect(() => {
    if (jobs && shouldGetJobs) {
      setSelectOptions(
        jobs.map((job) => getBefattningSelectOption(job.id, job.jobTitle))
      )

      setShouldGetJobs(false)
    }
  }, [jobs, shouldGetJobs])

  const isDebouncing =
    searchTerm.length > 0 && (!debouncedSearchTerm || shouldGetJobs)

  return (
    <MuiAutoComplete
      filterOptions={(x) => x}
      onInputChange={(_, value) => {
        setSearchTerm(value)
      }}
      onChange={handleChange}
      options={selectOptions}
      isOptionEqualToValue={(option, value) =>
        (option as ISelectOption).value === (value as ISelectOption).value
      }
      id={formikName}
      value={value}
      loading={isDebouncing}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          error={
            formikContext.touched.befattning &&
            !isNil(formikContext.errors.befattning)
          }
          helperText={
            formikContext.touched.befattning
              ? formikContext.errors.befattning
              : null
          }
          placeholder={placeholder ?? null}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      )}
      renderOption={(props, option, { inputValue }) => {
        const value = (option as ISelectOption).value as string
        const id = (option as ISelectOption).id

        const matches = match(value, inputValue, {
          insideWords: true,
        })

        const parts = parse(value, matches)

        return (
          <li {...props} key={`${value}_${id}`}>
            <div>
              {parts.map((part) => (
                <span
                  key={part.text}
                  style={{
                    fontWeight: part.highlight ? 700 : 400,
                  }}
                >
                  {part.text}
                </span>
              ))}
            </div>
          </li>
        )
      }}
    />
  )
}

export default Autocomplete
