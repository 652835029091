import { useDispatch } from 'react-redux'
import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { baseApi } from '@local/Services/API/baseApi'
import { formikWatchSlice } from '@local/Utils/Hooks/formikWatchSlice'
import { tjanstemanIAnstallningStepperSlice } from '@local/Views/SkapaAnsokan/TjanstemanIAnstallning/tjanstemanIAnstallningStepperSlice'
import { granskaAnsokanFormModalSlice } from '@local/Views/SkapaAnsokan/TjanstemanIAnstallning/GranskaAnsokan/Components/GranskaAnsokanFormModal/granskaAnsokanFormModalSlice'
import { tidsbegransadAnstallningStepperSlice } from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/tidsbegransadAnstallningStepperSlice'
import { tjanstemanIAnstallningFormSlice } from '@local/Views/SkapaAnsokan/TjanstemanIAnstallning/tjanstemanIAnstallningFormSlice'
import { tidsbegransadAnstallningFormSlice } from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/tidsbegransadAnstallningFormSlice'

const rootReducer = combineReducers({
  [baseApi.reducerPath]: baseApi.reducer,
  ['formikWatch']: formikWatchSlice.reducer,
  ['tjanstemanIAnstallningStepper']: tjanstemanIAnstallningStepperSlice.reducer,
  ['tidsbegransadAnstallningStepper']:
    tidsbegransadAnstallningStepperSlice.reducer,
  ['granskaAnsokanFormModal']: granskaAnsokanFormModalSlice.reducer,
  ['tjanstemanIAnstallningForm']: tjanstemanIAnstallningFormSlice.reducer,
  ['tidsbegransadAnstallningForm']: tidsbegransadAnstallningFormSlice.reducer,
})

export const setupStore = (preloadedState?: DeepPartial<RootState>) =>
  configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      // adding the api middleware enables caching, invalidation, polling and other features of `rtk-query`
      getDefaultMiddleware({ serializableCheck: false }).concat([
        baseApi.middleware,
      ]),
    preloadedState,
  })

export const store = setupStore()

export type DeepPartial<T> = T extends object
  ? {
      [P in keyof T]?: DeepPartial<T[P]>
    }
  : T

export type RootState = ReturnType<typeof rootReducer>
export type AppStore = ReturnType<typeof setupStore>
export type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>()
