import { IAnsokanPatchApiModel } from '@local/Services/Types/apiTypes'
import { ISelectOptionFormikWrapper } from '@local/Types/form.types'

export enum PatchOps {
  Replace = 'replace',
}

export interface IPatchBodyApiModel {
  op: PatchOps
  path: string
  value: string | number | boolean | ISelectOptionFormikWrapper
}

export interface IPatchApiInput {
  values: Partial<IAnsokanPatchApiModel>
  isMuiStepperEnabled?: boolean
}
