import { isNil } from 'ramda'
import { useFormikContext } from 'formik'
import Gutter from '@local/Components/Gutter'
import HTMLMapper from '@local/Components/HTMLMapper'
import { usePickEpiContent } from '@local/Utils/Hooks'
import { RadioChoice } from '@local/Types/form.types'
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material'
import FormErrorMessage from '@local/Components/FormErrorMessage'
import Accordion from '@local/Components/Accordion'
import { ITidsbegransadAnstallningFormValues } from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/TidsbegransadAnstallningForm/types'
import { ITidsbegransadAnstallningContent } from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/TidsbegransadAnstallning.types'
import { AnsokanKeys } from '@local/Types'

const Etableringsvillkor = () => {
  const { errors, touched, values, handleBlur, handleChange } =
    useFormikContext<ITidsbegransadAnstallningFormValues>()

  const {
    arbetslivserfarenhet: {
      forklaringvillkorHeader,
      etableringsvillkor: { heading, ja: labelJa, nej: labelNej },
      etableringsvillkorbeskrivning: { mainBody: description },
      etableringsvillkorAccordion,
    },
  } = usePickEpiContent<ITidsbegransadAnstallningContent>()

  const isError =
    touched.hasIntygatEtableringsvillkor &&
    !isNil(errors.hasIntygatEtableringsvillkor)

  return (
    <FormControl error={isError} variant="standard" fullWidth>
      <Typography variant="h6">{heading}</Typography>

      <Gutter xs={16} />

      <HTMLMapper body={description} />

      <Gutter xs={16} />

      <Accordion
        heading={forklaringvillkorHeader.heading}
        ariaControls="etableringsvillkor-content"
        ariaControlsId="etableringsvillkor-accordion"
        simple
      >
        <HTMLMapper body={etableringsvillkorAccordion.mainBody} />
      </Accordion>

      <RadioGroup
        onChange={handleChange}
        onBlur={handleBlur}
        defaultValue={values.hasIntygatEtableringsvillkor}
      >
        <FormControlLabel
          value={RadioChoice.Yes}
          control={<Radio color="primary" />}
          label={labelJa}
          name={AnsokanKeys.HasIntygatEtableringsvillkor}
          id={`${AnsokanKeys.HasIntygatEtableringsvillkor}-${labelJa}`}
        />
        <FormControlLabel
          value={RadioChoice.No}
          control={<Radio color="primary" />}
          label={labelNej}
          name={AnsokanKeys.HasIntygatEtableringsvillkor}
          id={`${AnsokanKeys.HasIntygatEtableringsvillkor}-${labelNej}`}
        />
      </RadioGroup>

      {isError && (
        <FormErrorMessage>
          {errors.hasIntygatEtableringsvillkor}
        </FormErrorMessage>
      )}
    </FormControl>
  )
}

export default Etableringsvillkor
