import { usePickEpiContent } from '@local/Utils/Hooks'
import { ITidsbegransadAnstallningContent } from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/TidsbegransadAnstallning.types'
import { Grid2 as Grid } from '@mui/material'
import DateInput from '@local/Components/DateInput'
import Gutter from '@local/Components/Gutter'
import Kontaktpersoner from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/Uppsagning/Components/Kontaktpersoner'
import { AnsokanKeys } from '@local/Types'
import { useFormikContext } from 'formik'
import { ITidsbegransadAnstallningFormValues } from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/TidsbegransadAnstallningForm/types'
import {
  dayAfterDate,
  getLatestOccuringDate,
  octoberStart,
  yesterday,
} from '@local/Utils/Helpers/Datetime.helpers'

const Uppsagning = () => {
  const formikContext = useFormikContext<ITidsbegransadAnstallningFormValues>()
  const { values } = formikContext

  const {
    uppsagning: { sistaAnstallningsdatum, tooltipSistaAnstallningsdatum },
  } = usePickEpiContent<ITidsbegransadAnstallningContent>()

  return (
    <Grid container>
      <Grid size={{ lg: 10 }} sx={{ width: 1 }}>
        <DateInput
          formikFieldName={AnsokanKeys.SistaAnstallningsdatum}
          formikContext={formikContext}
          label={sistaAnstallningsdatum.textFaltTitel}
          tooltipContent={tooltipSistaAnstallningsdatum}
          maxDate={yesterday}
          minDate={getLatestOccuringDate([
            octoberStart,
            dayAfterDate(values.anstallningsdatum),
          ])}
          title={sistaAnstallningsdatum.titel}
        />

        <Gutter xs={32} />

        <Kontaktpersoner />
      </Grid>
    </Grid>
  )
}

export default Uppsagning
