import { JSX, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Button, Grid2 as Grid } from '@mui/material'
import StickyFooter from '@local/Components/StickyFooter'
import { useIsFeatureFlagEnabled, usePickEpiContent } from '@local/Utils/Hooks'
import { useTrackCustomClick } from '@trr/gtm-tracking'
import {
  ITjanstemanIAnstallningContent,
  TjanstemanIAnstallningStep,
} from '@local/Views/SkapaAnsokan/TjanstemanIAnstallning/TjanstemanIAnstallning.types'
import {
  goToNextStep,
  goToPreviousStep,
  selectTjanstemanIAnstallningStepper,
} from '@local/Views/SkapaAnsokan/TjanstemanIAnstallning/tjanstemanIAnstallningStepperSlice'
import { useAppDispatch } from '@local/Store/configureStore'
import { setIsModalOpen } from '@local/Views/SkapaAnsokan/TjanstemanIAnstallning/GranskaAnsokan/Components/GranskaAnsokanFormModal/granskaAnsokanFormModalSlice'
import { FormikTouched, setNestedObjectValues, useFormikContext } from 'formik'
import { ITjanstemanIAnstallningFormValues } from '@local/Views/SkapaAnsokan/TjanstemanIAnstallning/TjanstemanIAnstallningForm/types'
import { isEmpty, omit } from 'ramda'
import { setFormIsDirty } from '@local/Views/SkapaAnsokan/TjanstemanIAnstallning/tjanstemanIAnstallningFormSlice'
import { GranskaAnsokanFormKeys } from '@local/Views/SkapaAnsokan/TjanstemanIAnstallning/GranskaAnsokan/Components/GranskaAnsokanFormModal/types'

const TjanstemanIAnstallningFormSubmit = (): JSX.Element => {
  const dispatch = useAppDispatch()
  const [shouldShowErrorMessage, setShouldShowErrorMessage] = useState(false)
  const shouldTrack = useIsFeatureFlagEnabled(
    'gtm.enabled.tjanstepersonansokan'
  )
  const trackButtonClick = useTrackCustomClick()

  const { values, errors, validateForm, setTouched } =
    useFormikContext<ITjanstemanIAnstallningFormValues>()

  const { sidfot, dinaTrrAnsokningarLinks } =
    usePickEpiContent<ITjanstemanIAnstallningContent>()

  const { activeStep } = useSelector(selectTjanstemanIAnstallningStepper)

  const isStepGranskaOchSkickaIn =
    activeStep === TjanstemanIAnstallningStep.GranskaOchSkickaIn

  useEffect(() => {
    if (isEmpty(omit([GranskaAnsokanFormKeys.ForsakratUppgifter], errors))) {
      setShouldShowErrorMessage(false)
    }
  }, [errors])

  const goBackHandler = useCallback(() => {
    dispatch(goToPreviousStep())
    window.scroll({ top: 0 })
  }, [dispatch])

  const goNextHandler = useCallback(() => {
    dispatch(goToNextStep())
    window.scroll({ top: 0 })
  }, [dispatch])

  const handleFinish = useCallback(() => {
    void (async () => {
      const formErrors = await validateForm(
        omit([GranskaAnsokanFormKeys.ForsakratUppgifter], values)
      ).catch((err) => {
        console.log(err)
      })

      if (isEmpty(formErrors)) {
        if (shouldTrack) {
          trackButtonClick('Granska och skicka in', {
            label: 'Skicka in',
          })
        }
        dispatch(setIsModalOpen(true))
        dispatch(setFormIsDirty(false))
      } else {
        setTouched(
          setNestedObjectValues<
            FormikTouched<ITjanstemanIAnstallningFormValues>
          >(formErrors, true)
        )?.catch((err) => {
          console.log(err)
        })
        setShouldShowErrorMessage(true)
      }
    })()
  }, [
    values,
    dispatch,
    setTouched,
    shouldTrack,
    trackButtonClick,
    validateForm,
  ])

  return (
    <StickyFooter
      leftElements={
        <Button
          variant="outlined"
          href={dinaTrrAnsokningarLinks.dinaTrrAnsokningarUrl}
        >
          {sidfot.avbryt}
        </Button>
      }
      rightElements={
        <div>
          <Grid container columnSpacing={{ xs: 1, sm: 2 }}>
            <Grid size={{ xs: 6, sm: 'auto' }}>
              <Button
                disabled={
                  activeStep === TjanstemanIAnstallningStep.OmDinArbetsplats
                }
                variant="outlined"
                onClick={goBackHandler}
              >
                {sidfot.foregaendeSteg}
              </Button>
            </Grid>

            <Grid size={{ xs: 6, sm: 'auto' }}>
              {isStepGranskaOchSkickaIn && (
                <>
                  <Button
                    variant="contained"
                    onClick={handleFinish}
                    data-trackable="false"
                  >
                    {sidfot.slutfor}
                  </Button>
                </>
              )}
              {!isStepGranskaOchSkickaIn && (
                <Button variant="contained" onClick={goNextHandler}>
                  {sidfot.nastaSteg}
                </Button>
              )}
            </Grid>
          </Grid>
        </div>
      }
      isError={shouldShowErrorMessage}
      errorMsg={sidfot.kontrolleraUppgifter}
    />
  )
}

export default TjanstemanIAnstallningFormSubmit
