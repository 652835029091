import { IAnsokanDraftApiResponse } from '@local/Services/API/hamtaAnsokanApi'
import { RadioChoice } from '@local/Types/form.types'
import {
  initialOnboardingValues,
  IOnboardingFormValues,
} from '@local/Views/OnboardingWrapper/Onboarding/OnboardingForm/OnboardingForm.schema'
import { TypeOfAnsokan } from '@local/Views/OnboardingWrapper/Onboarding/Types'

const mapOnboardingFormValues = (
  ansokanDraft?: IAnsokanDraftApiResponse
): IOnboardingFormValues => {
  if (ansokanDraft) {
    return {
      ...initialOnboardingValues,
      iAnstallning:
        ansokanDraft.type === TypeOfAnsokan.Anstalld
          ? RadioChoice.Yes
          : RadioChoice.No,
      uppsagdTyp:
        ansokanDraft.type === TypeOfAnsokan.Tidsbegransad
          ? RadioChoice.No
          : null,
    }
  }

  return initialOnboardingValues
}

export { mapOnboardingFormValues }
