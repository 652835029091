export interface IUppsagningFormValues {
  [UppsagningFormTypes.SistaAnstallningsdatum]: Date
  [UppsagningFormTypes.ForetagKontaktpersonEpostadress]: string
  [UppsagningFormTypes.ForetagKontaktpersonNamn]: string
}

export enum UppsagningFormTypes {
  SistaAnstallningsdatum = 'sistaAnstallningsdatum',
  ForetagKontaktpersonEpostadress = 'foretagKontaktpersonEpostadress',
  ForetagKontaktpersonNamn = 'foretagKontaktpersonNamn',
}

export interface IUppsagningFormValuesApiModel
  extends Omit<
    IUppsagningFormValues,
    UppsagningFormTypes.SistaAnstallningsdatum
  > {
  sistaAnstallningsdatum: string
}
