import { IAnsokanApiModel } from '@local/Services/Types/apiTypes'
import { RadioChoice } from '@local/Types/form.types'
import { initialArbetsplatsValues } from '@local/Views/SkapaAnsokan/TjanstemanIAnstallning/Arbetsplats/Arbetsplats.schema'
import { IArbetsplatsFormValues } from '@local/Views/SkapaAnsokan/TjanstemanIAnstallning/Arbetsplats/Types'

const harAgandeskapFromAgarandel = (agarandel: number) => {
  if (typeof agarandel !== 'number') {
    return undefined
  } else {
    return agarandel > 0 ? RadioChoice.Yes : RadioChoice.No
  }
}

const mapAnsokanToArbetsplatsFormValues = (
  ansokan: IAnsokanApiModel
): IArbetsplatsFormValues => {
  const { anstallningsuppgifter, foretag } = ansokan

  return {
    ...initialArbetsplatsValues,
    agarandel:
      anstallningsuppgifter?.agarandel ?? initialArbetsplatsValues.agarandel,
    harAgandeskap: harAgandeskapFromAgarandel(anstallningsuppgifter?.agarandel),
    nuvarandeArbetsplats: foretag?.organisationsnummer
      ? RadioChoice.Yes
      : RadioChoice.No,
    foretagorganisationsnummer:
      foretag?.organisationsnummer ??
      initialArbetsplatsValues.foretagorganisationsnummer,
  }
}

export { mapAnsokanToArbetsplatsFormValues }
