import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { isNil } from 'ramda'
import { selectTidsbegransadAnstallningStepper } from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/tidsbegransadAnstallningStepperSlice'

const useUpdateStepParam = () => {
  const history = useHistory()
  const { activeStep } = useSelector(selectTidsbegransadAnstallningStepper)

  const stepParam = new URLSearchParams(location.search).get('step')

  useEffect(() => {
    if (!isNil(activeStep) && stepParam !== activeStep.toString()) {
      const urlSearchParams = new URLSearchParams({
        step: activeStep.toString(),
      })

      history.push({
        search: urlSearchParams.toString(),
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStep, history])
}

export default useUpdateStepParam
