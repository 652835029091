import { useEffect } from 'react'
import FileUpload from '@local/Components/FileUpload'
import { IUniqueFile } from '@local/Components/FileUpload/FileUpload.types'
import {
  useGetAllIntygBilagaQuery,
  usePostBilagaMutation,
  useDeleteBilagaMutation,
} from '@local/Services/API/bilagorApi'
import { ILaddaUppBilaga } from '@local/Types'
import { useAuthentication } from '@trr/app-shell-data'
import { FormikErrors, FormikHelpers, FormikValues } from 'formik'

interface BilagorProps {
  content: ILaddaUppBilaga
  formikFieldName: string
  files: IUniqueFile[]
  setFieldValue: FormikHelpers<unknown>['setFieldValue']
  errorMessage?: string | FormikErrors<FormikValues>
}

export const Bilagor = ({
  content,
  formikFieldName,
  files,
  setFieldValue,
  errorMessage,
}: BilagorProps) => {
  const { sub } = useAuthentication()
  const { data } = useGetAllIntygBilagaQuery(sub)
  const [uploadBilaga, { isLoading: isLoadingUpload }] = usePostBilagaMutation()
  const [deleteBilaga] = useDeleteBilagaMutation()

  useEffect(() => {
    if (data) {
      const bilagor: IUniqueFile[] = data.map((bilaga) => ({
        id: bilaga.fileId,
        name: bilaga.name,
      }))
      setFieldValue(formikFieldName, bilagor)?.catch((err) => {
        console.log(err)
      })
    }
  }, [data, setFieldValue, formikFieldName])

  const uploadHandler = async (
    file: IUniqueFile,
    fileStream: File
  ): Promise<void> => {
    const formData = new FormData()
    formData.append('fileId', file.id)
    formData.append('file', fileStream)

    return uploadBilaga({ formData, userSub: sub }).unwrap()
  }

  const deleteHandler = (file: IUniqueFile) =>
    deleteBilaga({ bilagaId: file.id, userSub: sub })

  return (
    <FileUpload
      isLoadingUpload={isLoadingUpload}
      content={content}
      inputName={formikFieldName}
      files={files}
      setFieldValue={setFieldValue}
      errorMessage={errorMessage}
      filePickedHandler={uploadHandler}
      fileDeletedHandler={deleteHandler}
      multiple
    />
  )
}
