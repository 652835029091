export interface IBefattningApiModel {
  id: string
  jobTitle: string
  jobTitleWithHighLightedSearchTerm: string
}

export type IAnsokanPatchApiModel = IAnsokanOrganisationsnummerPatchApiModel &
  IAnstallningsuppgifter &
  ILoneuppgifter &
  IEtableringsvillkor

export interface IAnsokanOrganisationsnummerPatchApiModel {
  foretagNamn: string
  foretagOrganisationsnummer: string
  foretagId: string
}

interface IAnstallningsuppgifter {
  isTjansteman: true
  agarandel: number
  anstallningsdatum: string
  anstallningsgrad: number
  arbetstimmarPerVecka: number
  befattning: string
  befattningId: string
  isVdEllerUndantagsgrupp: boolean
  sistaAnstallningsdatum: string
  uppsagningsdatum: string
  uppsagningsgrad: number
}

interface ILoneuppgifter {
  avgangsvederlag: number
  jourBelopp: number
  kostLogiBelopp: number
  lonerevisionsdatum: string
  manadslon: number
  provisionBelopp: number
  sjukersattningsgrad: number
}

interface IEtableringsvillkor {
  hasIntygatEtableringsvillkor: string
}

interface IPersonuppgifter {
  efternamn: string
  personnummer: string
  epostadress: string
  fornamn: string
}

export interface ICreateAnsokanBodyApiModel {
  fornamn: string
  efternamn: string
  personnummer: string
  userId: string
}

export interface IKontaktPerson {
  namn: string
  epostadress: string
  telefon: string
}

export interface IForetagUppgifter {
  id: string
  namn: string
  organisationsnummer: string
  kontaktperson: IKontaktPerson
}

export interface IAnsokanApiModel {
  id: string
  anstallningsuppgifter: IAnstallningsuppgifter
  loneuppgifter: ILoneuppgifter
  personuppgifter: IPersonuppgifter
  uppdragId: string
  foretag: IForetagUppgifter
  hasIntygatEtableringsvillkor: boolean
}

export enum CreateAnsokanTypes {
  Anstalld = 'Anstalld',
  Tidsbegransad = 'OmstallningTidsbegransadanstallningAvTjansteperson',
}
