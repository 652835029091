import { RadioChoice } from '@local/Types/form.types'
import { EtableringsvillkorFormTypes } from '@local/Views/SkapaAnsokan/Components/Etableringsvillkor/Types'

export interface IArbetslivserfarenhetTIAFormValues {
  [EtableringsvillkorFormTypes.HasIntygatEtableringsvillkor]: RadioChoice
  [ArbetslivserfarenhetTIAFormTypes.ArbetstimmarPerVecka]: number
}

export enum ArbetslivserfarenhetTIAFormTypes {
  ArbetstimmarPerVecka = 'arbetstimmarPerVecka',
}
