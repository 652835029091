import { useState } from 'react'

interface IUseToggleReturnType {
  handleToggle: () => void
  isToggled: boolean
  isClosed: boolean
}

const useToggle = (initialToggle = false): IUseToggleReturnType => {
  const [toggle, setToggle] = useState<boolean>(initialToggle)

  const handleToggle = () => {
    setToggle(!toggle)
  }

  return {
    handleToggle,
    isToggled: toggle,
    isClosed: !toggle,
  }
}

export default useToggle
