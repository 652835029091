import { IArbetslivserfarenhetTBFormValues } from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/ArbetslivserfarenhetTB/Types'
import { IArbetslivserfarenhetTIAFormValues } from '@local/Views/SkapaAnsokan/TjanstemanIAnstallning/ArbetslivserfarenhetTIA/Types'

export interface IEtableringsvillkor {
  heading: string
  ja: string
  nej: string
  varning: string
}

export type ArbetslivserfarenhetSharedFormValues =
  | IArbetslivserfarenhetTIAFormValues
  | IArbetslivserfarenhetTBFormValues

export enum EtableringsvillkorFormTypes {
  HasIntygatEtableringsvillkor = 'hasIntygatEtableringsvillkor',
}
