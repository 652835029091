import { RadioChoice } from '@local/Types/form.types'
import { IOnboardingFormValues } from '@local/Views/OnboardingWrapper/Onboarding/OnboardingForm/OnboardingForm.schema'
import { useFormikContext } from 'formik'

const useIsTIO = (): boolean => {
  const { values } = useFormikContext<IOnboardingFormValues>()
  return values.iAnstallning === RadioChoice.No
}

export default useIsTIO
