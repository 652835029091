import { RadioChoice } from '@local/Types/form.types'
import { IMiddlewareField } from '@local/Utils/Hooks/useFormikWatch'
import {
  IArbetsplatsFormValues,
  ArbetsplatsFormTypes,
} from '@local/Views/SkapaAnsokan/Components/Arbetsplats/Types'

export const arbetsplatsMiddleware = (
  field: IMiddlewareField<IArbetsplatsFormValues>,
  next: (
    field: IMiddlewareField<IArbetsplatsFormValues>
  ) => Partial<IArbetsplatsFormValues>
): Partial<IArbetsplatsFormValues> => {
  switch (field.id) {
    case ArbetsplatsFormTypes.NuvarandeArbetsplats:
    case ArbetsplatsFormTypes.ForetagOrganisationsnummer:
      return

    case ArbetsplatsFormTypes.HarAgandeskap: {
      if (field.value === RadioChoice.No) return { agarandel: null }
      else return
    }
    default:
      return next(field)
  }
}
