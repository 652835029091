export enum Routes {
  Start = 'ansokan',
  TiaAnsokan = 'tjansteman-i-anstallning',
  TbAnsokan = 'tidsbegransad-anstallning',
  OgiltigtOrgnummer = 'ogiltigt-orgnummer',
}

export enum QueryParams {
  OgiltigtOrgnummer = 'organisationsnummer',
}
