import Gutter from '@local/Components/Gutter'
import { useGetAnsokanQuery } from '@local/Services/API/hamtaAnsokanApi'
import { formatCurrencySEK, formatPercentageNumber } from '@local/Utils/Helpers'
import { trrFormat } from '@local/Utils/Helpers/formatDate'
import { usePickEpiContent } from '@local/Utils/Hooks'
import GranskaAnsokan from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/GranskaAnsokan'
import { ISammanfattningSektionProps } from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/GranskaAnsokan/Components/SammanfattningSektion'
import { ITidsbegransadAnstallningContent } from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/TidsbegransadAnstallning.types'
import { Chip, Grid2 as Grid, Typography } from '@mui/material'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import Spinner from '@local/Components/Spinner'
import ChipEllipsis from '@local/Components/ChipEllipsis'
import { ITidsbegransadAnstallningFormValues } from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/TidsbegransadAnstallningForm/types'
import { useFormikContext } from 'formik'
import { getDesiredOrDefault } from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/GranskaAnsokanTB/helpers'
import { ISelectOption, RadioChoice } from '@local/Types/form.types'

const GranskaAnsokanTB = () => {
  const { data, isLoading: isLoadingAnsokan } = useGetAnsokanQuery()

  const {
    granskaAnsokan: {
      omDinArbetsplats,
      omDinAnstallning,
      omDinUppsagning,
      omDinLonOchErsattning,
      omDinArbetslivserfarenhet,
    },
  } = usePickEpiContent<ITidsbegransadAnstallningContent>()

  const {
    values: {
      harAgandeskap,
      agarandel,
      befattning,
      isVdEllerUndantagsgrupp,
      anstallningsdatum,
      arbetstimmarPerVecka,
      anstallningsgrad,
      anstallningBilagor,
      sistaAnstallningsdatum,
      foretagKontaktpersonEpostadress,
      foretagKontaktpersonNamn,
      manadslon,
      provision: harProvision,
      provisionBelopp,
      jour: harJour,
      jourBelopp,
      sjukersattningsgrad,
      sjukersattningsgradChoice: harSjukersattning,
      hasIntygatEtableringsvillkor,
    },
  } = useFormikContext<ITidsbegransadAnstallningFormValues>()

  const arbetsplatsSektion: ISammanfattningSektionProps = {
    title: omDinArbetsplats.titel,
    undersektioner: [
      {
        title: omDinArbetsplats.arbetsgivare,
        body: data?.foretag?.namn,
      },
      {
        title: omDinArbetsplats.agandedel,
        body: getDesiredOrDefault(
          harAgandeskap,
          harAgandeskap === RadioChoice.Yes
            ? omDinArbetsplats.ja
            : omDinArbetsplats.nej
        ),
      },
    ],
  }

  if (harAgandeskap === RadioChoice.Yes) {
    arbetsplatsSektion.undersektioner.push({
      title: omDinArbetsplats.agarandelar,
      body: getDesiredOrDefault(agarandel, formatPercentageNumber(agarandel)),
    })
  }

  const anstallningSektion: ISammanfattningSektionProps = {
    title: omDinAnstallning.titel,
    undersektioner: [
      {
        title: omDinAnstallning.befattning,
        body: getDesiredOrDefault(
          befattning,
          (befattning as ISelectOption)?.label
        ),
      },
      {
        title: omDinAnstallning.vd,
        body: getDesiredOrDefault(
          isVdEllerUndantagsgrupp,
          isVdEllerUndantagsgrupp === RadioChoice.Yes
            ? omDinAnstallning.ja
            : omDinAnstallning.nej
        ),
      },
      {
        title: omDinAnstallning.anstallningsdatum,
        body: getDesiredOrDefault(
          anstallningsdatum,
          trrFormat(anstallningsdatum)
        ),
      },
      {
        title: omDinAnstallning.arbetstimmar,
        body: getDesiredOrDefault(
          arbetstimmarPerVecka,
          arbetstimmarPerVecka?.toString()
        ),
      },
      {
        title: omDinAnstallning.bifogadeFiler,
        body: getDesiredOrDefault(
          anstallningBilagor,
          <>
            <Gutter xs={8} />
            {anstallningBilagor?.map((bilaga) => (
              <div key={bilaga.id}>
                <ChipEllipsis>
                  <Chip
                    icon={<AttachFileIcon />}
                    label={bilaga.name}
                    variant="outlined"
                  />
                </ChipEllipsis>
                <Gutter xs={8} />
              </div>
            ))}
          </>
        ),
      },
    ],
  }

  const uppsagningSektion: ISammanfattningSektionProps = {
    title: omDinUppsagning.titel,
    undersektioner: [
      {
        title: omDinUppsagning.sistaAnstallningsdag,
        body: getDesiredOrDefault(
          sistaAnstallningsdatum,
          trrFormat(sistaAnstallningsdatum)
        ),
      },
      {
        title: omDinUppsagning.omfattning,
        body: getDesiredOrDefault(
          anstallningsgrad,
          formatPercentageNumber(anstallningsgrad)
        ),
      },
      {
        title: omDinUppsagning.kontaktperson,
        body: (
          <Grid container>
            <Grid size={{ xs: 12 }}>
              {getDesiredOrDefault(
                foretagKontaktpersonNamn,
                <Typography variant="body1" gutterBottom>
                  {foretagKontaktpersonNamn}
                </Typography>
              )}
            </Grid>
            <Grid size={{ xs: 12 }}>
              {getDesiredOrDefault(
                foretagKontaktpersonEpostadress,
                <Typography variant="body1">
                  {foretagKontaktpersonEpostadress}
                </Typography>
              )}
            </Grid>
          </Grid>
        ),
      },
    ],
  }

  const lonOchErsattningSektion: ISammanfattningSektionProps = {
    title: omDinLonOchErsattning.titel,
    undersektioner: [
      {
        title: omDinLonOchErsattning.manadslon,
        body: getDesiredOrDefault(manadslon, formatCurrencySEK(manadslon)),
      },
      {
        title: omDinLonOchErsattning.provision,
        body:
          harProvision === RadioChoice.No
            ? formatCurrencySEK(0)
            : getDesiredOrDefault(
                provisionBelopp,
                formatCurrencySEK(provisionBelopp)
              ),
      },
      {
        title: omDinLonOchErsattning.jour,
        body:
          harJour === RadioChoice.No
            ? formatCurrencySEK(0)
            : getDesiredOrDefault(jourBelopp, formatCurrencySEK(jourBelopp)),
      },
      {
        title: omDinLonOchErsattning.sjukersattning,
        body:
          harSjukersattning === RadioChoice.No
            ? omDinLonOchErsattning.saknarSjukersattning
            : getDesiredOrDefault(
                sjukersattningsgrad,
                formatPercentageNumber(sjukersattningsgrad)
              ),
      },
    ],
  }

  const arbetslivserfarenhetSektion: ISammanfattningSektionProps = {
    title: omDinArbetslivserfarenhet.heading,
    undersektioner: [
      {
        title: omDinArbetslivserfarenhet.etableringsvillkor,

        body: getDesiredOrDefault(
          hasIntygatEtableringsvillkor,
          hasIntygatEtableringsvillkor === RadioChoice.Yes
            ? omDinArbetslivserfarenhet.ja
            : omDinArbetslivserfarenhet.nej
        ),
      },
    ],
  }
  const sammanfattning = [
    arbetsplatsSektion,
    anstallningSektion,
    uppsagningSektion,
    lonOchErsattningSektion,
  ]

  if (isLoadingAnsokan) {
    return <Spinner centered />
  }

  return (
    <GranskaAnsokan
      sammanfattningSektioner={[...sammanfattning, arbetslivserfarenhetSektion]}
    />
  )
}

export default GranskaAnsokanTB
