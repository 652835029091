import { useCallback } from 'react'
import { mapAnsokanToLonOchErsattningFormValues } from '@local/Views/SkapaAnsokan/TidsbegransadAnstallningOld/LonOchErsattning/Helpers'
import { lonOchErsattningValidationSchema } from '@local/Views/SkapaAnsokan/TidsbegransadAnstallningOld/LonOchErsattning/LonOchErsattningForm.schema'
import {
  ILonOchErsattningContent,
  ILonOchErsattningFormProps,
} from '@local/Views/SkapaAnsokan/TidsbegransadAnstallningOld/LonOchErsattning/Types/content'
import Gutter from '@local/Components/Gutter'
import { usePatchAnsokanMutation } from '@local/Services/API/skapaAnsokanApi'
import { usePickEpiContent, useWizardNextStep } from '@local/Utils/Hooks'
import useFormikWatch from '@local/Utils/Hooks/useFormikWatch'
import { ILonOchErsattningFormValues } from '@local/Views/SkapaAnsokan/TidsbegransadAnstallningOld/LonOchErsattning/Types'
import { lonOchErsattningMiddleware } from '@local/Views/SkapaAnsokan/TidsbegransadAnstallningOld/LonOchErsattning/lonOchErsattningMiddleware'
import { useWizard, WizardStep } from '@trr/wizard-library'
import {
  FormikTouched,
  setNestedObjectValues,
  useFormikContext,
  withFormik,
} from 'formik'
import { isEmpty } from 'ramda'
import Provision from '@local/Views/SkapaAnsokan/TidsbegransadAnstallningOld/LonOchErsattning/Components/Provision'
import Jour from '@local/Views/SkapaAnsokan/TidsbegransadAnstallningOld/LonOchErsattning/Components/Jour'
import Sjukersattningsgrad from '@local/Views/SkapaAnsokan/TidsbegransadAnstallningOld/LonOchErsattning/Components/Sjukersattningsgrad'
import { useAppDispatch } from '@local/Store/configureStore'
import { setShouldGoToNextStep } from '@local/Utils/Hooks/formikWatchSlice'
import { ITidsbegransadAnstallningContent } from '@local/Views/SkapaAnsokan/TidsbegransadAnstallningOld/TidsbegransadAnstallning.types'
import Manadslon from '@local/Views/SkapaAnsokan/TidsbegransadAnstallningOld/LonOchErsattning/Components/Manadslon'
import { Grid2 as Grid } from '@mui/material'

const LonOchErsattningForm = () => {
  const dispatch = useAppDispatch()
  const { stepCount, activeStep } = useWizard()
  const [patchAnsokan] = usePatchAnsokanMutation({
    fixedCacheKey: 'patchAnsokan',
  })

  const { validateForm, setTouched } =
    useFormikContext<ILonOchErsattningFormValues>()

  const {
    lonOchErsattning: { stegGuide },
  } = usePickEpiContent<ITidsbegransadAnstallningContent>()

  const patchAnsokanCallback = useCallback(
    async (values: Partial<ILonOchErsattningFormValues>) => {
      await patchAnsokan({ values })
    },
    [patchAnsokan]
  )

  useFormikWatch(lonOchErsattningMiddleware, patchAnsokanCallback)
  useWizardNextStep()

  const handleNext = () => {
    validateForm()
      .then((validationErrors) => {
        if (!isEmpty(validationErrors)) {
          setTouched(
            setNestedObjectValues<FormikTouched<ILonOchErsattningFormValues>>(
              validationErrors,
              true
            )
          ).catch((err) => {
            console.log(err)
          })
        } else {
          dispatch(setShouldGoToNextStep(true))
        }
      })
      .catch((e) => {
        console.error(e)
      })
  }

  return (
    <Grid container>
      <Grid size={{ lg: 8 }} sx={{ width: 1 }}>
        <WizardStep
          progressBar={{ max: stepCount - 1, current: activeStep + 1 }}
          stepInfo={{
            currentStepLabel: stegGuide.nuvarandeSteg,
            nextStepLabel: stegGuide.nastaSteg,
          }}
          nextButton={{ onClick: handleNext }}
          prevButton={{}}
        >
          <Manadslon />

          <Gutter xs={32} />

          <Provision />

          <Gutter xs={32} />

          <Jour />

          <Gutter xs={32} />

          <Sjukersattningsgrad />
        </WizardStep>
      </Grid>
    </Grid>
  )
}

const LonOchErsattningFormik = withFormik<
  ILonOchErsattningFormProps,
  ILonOchErsattningFormValues
>({
  validateOnBlur: true,
  enableReinitialize: true,
  mapPropsToValues: ({ ansokan }) =>
    mapAnsokanToLonOchErsattningFormValues(ansokan),
  handleSubmit: (_, { setSubmitting }) => {
    setSubmitting(false)
  },
  validationSchema: ({ content }: { content: ILonOchErsattningContent }) =>
    lonOchErsattningValidationSchema(content),
  displayName: 'LonOchErsattning',
})(LonOchErsattningForm)

export default LonOchErsattningFormik
