import { Grid2 as Grid } from '@mui/material'
import Etableringsvillkor from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/ArbetslivserfarenhetTB/Components/Etableringsvillkor'

const ArbetslivserfarenhetTB = () => (
  <Grid container>
    <Grid size={{ lg: 10 }} sx={{ width: 1 }}>
      <Etableringsvillkor />
    </Grid>
  </Grid>
)

export default ArbetslivserfarenhetTB
