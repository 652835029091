import { JSX } from 'react'
import { usePickEpiContent } from '@local/Utils/Hooks'
import { ITjanstemanIAnstallningContent } from '@local/Views/SkapaAnsokan/TjanstemanIAnstallning/TjanstemanIAnstallning.types'
import { useFormikContext } from 'formik'
import HTMLMapper from '@local/Components/HTMLMapper'
import Tooltip from '@local/Components/Tooltip'
import NumberInput from '@local/Components/NumberInput'
import { AnsokanKeys } from '@local/Types'
import { ITjanstemanIAnstallningFormValues } from '@local/Views/SkapaAnsokan/TjanstemanIAnstallning/TjanstemanIAnstallningForm/types'

const Manadslon = (): JSX.Element => {
  const {
    anstallning: { manadslon, tooltipManadslon },
  } = usePickEpiContent<ITjanstemanIAnstallningContent>()

  const formikContext = useFormikContext<ITjanstemanIAnstallningFormValues>()

  return (
    <>
      <Tooltip
        heading={manadslon.titel}
        tooltipContent={<HTMLMapper body={tooltipManadslon.mainBody} />}
      />

      <NumberInput
        formikContext={formikContext}
        label={manadslon.textFaltTitel}
        name={AnsokanKeys.Manadslon}
      />
    </>
  )
}

export default Manadslon
