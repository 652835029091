export enum PaborjadAnsokanVal {
  Fortsatt = 'fortsatt',
  Radera = 'radera',
}

export interface PaborjadAnsokanProps {
  ansokanValValue: PaborjadAnsokanVal
  setAnsokanVal: (val: PaborjadAnsokanVal) => void
  onContinue: () => Promise<void>
}
