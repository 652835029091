import * as Yup from 'yup'
import { arbetsplatsValidationSchema } from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/Arbetsplats/Arbetsplats.schema'
import { ITidsbegransadAnstallningContent } from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/TidsbegransadAnstallning.types'
import { anstallningTBValidationSchema } from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/AnstallningTB/AnstallningTB.schema'
import { arbetslivserfarenhetTBValidationSchema } from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/ArbetslivserfarenhetTB/ArbetslivserfarenhetTB.schema'
import { granskaAnsokanValidationSchema } from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/GranskaAnsokan/GranskaAnsokan.schema'
import { uppsagningValidationSchema } from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/Uppsagning/Uppsagning.schema'
import { lonOchErsattningValidationSchema } from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/LonOchErsattning/LonOchErsattning.schema'

export const tidsbegransadAnstallningValidationSchema = (
  content: ITidsbegransadAnstallningContent
) =>
  Yup.object()
    .concat(arbetsplatsValidationSchema(content.tjanstepersonansokanValidering))
    .concat(
      anstallningTBValidationSchema(
        content.tjanstepersonansokanValidering,
        content.datumValidering
      )
    )
    .concat(
      uppsagningValidationSchema(
        content.tjanstepersonansokanValidering,
        content.datumValidering
      )
    )
    .concat(
      lonOchErsattningValidationSchema(content.tjanstepersonansokanValidering)
    )
    .concat(
      arbetslivserfarenhetTBValidationSchema(
        content.tjanstepersonansokanValidering
      )
    )
    .concat(
      granskaAnsokanValidationSchema(content.tjanstepersonansokanValidering)
    )
