import { RadioChoice } from '@local/Types/form.types'
import { EtableringsvillkorFormTypes } from '@local/Views/SkapaAnsokan/Components/Etableringsvillkor/Types'
import { IArbetslivserfarenhetTBFormValues } from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/ArbetslivserfarenhetTB/Types'
import { IArbetslivserfarenhetTBContent } from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/ArbetslivserfarenhetTB/Types/content'
import * as Yup from 'yup'

export const arbetslivserfarenhetTBValidationSchema = (
  content: IArbetslivserfarenhetTBContent
): Yup.ObjectSchema<IArbetslivserfarenhetTBFormValues> =>
  Yup.object().shape({
    [EtableringsvillkorFormTypes.HasIntygatEtableringsvillkor]:
      Yup.mixed<RadioChoice>().required(content.etableringsvillkor.varning),
  })

export const initialArbetslivserfarenhetTBValues: IArbetslivserfarenhetTBFormValues =
  {
    hasIntygatEtableringsvillkor: null,
  }
