import Gutter from '@local/Components/Gutter'
import NumberInput from '@local/Components/NumberInput'
import { usePickEpiContent } from '@local/Utils/Hooks'
import { IAnstallningTBFormValues } from '@local/Views/SkapaAnsokan/TidsbegransadAnstallningOld/AnstallningTB/Types'
import { ITidsbegransadAnstallningContent } from '@local/Views/SkapaAnsokan/TidsbegransadAnstallningOld/TidsbegransadAnstallning.types'
import { AnstallningFormTypes } from '@local/Views/SkapaAnsokan/Types'
import { Typography } from '@mui/material'
import { useFormikContext } from 'formik'

const Anstallningsgrad = () => {
  const formikContext = useFormikContext<IAnstallningTBFormValues>()

  const {
    anstallningTb: { anstallningsgrad },
  } = usePickEpiContent<ITidsbegransadAnstallningContent>()

  return (
    <>
      <Typography variant="h6" gutterBottom>
        {anstallningsgrad.titel}
      </Typography>

      <Gutter xs={8} />

      <NumberInput
        formikContext={formikContext}
        label={anstallningsgrad.textFaltTitel}
        name={AnstallningFormTypes.Anstallningsgrad}
      />
    </>
  )
}

export default Anstallningsgrad
