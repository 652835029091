import { AnsokanKeys } from '@local/Types'
import { TidsbegransadAnstallningStepFormikId } from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/TidsbegransadAnstallning.types'
import { GranskaAnsokanFormKeys } from '@local/Views/SkapaAnsokan/TjanstemanIAnstallning/GranskaAnsokan/Components/GranskaAnsokanFormModal/types'

interface IStepsContext {
  [TidsbegransadAnstallningStepFormikId.OmDinArbetsplats]: AnsokanKeys[]
  [TidsbegransadAnstallningStepFormikId.OmDinAnstallning]: AnsokanKeys[]
  [TidsbegransadAnstallningStepFormikId.OmDinUppsagning]: AnsokanKeys[]
  [TidsbegransadAnstallningStepFormikId.LonOchErsattning]: AnsokanKeys[]
  [TidsbegransadAnstallningStepFormikId.OmDinSysselsattning]: AnsokanKeys[]
  [TidsbegransadAnstallningStepFormikId.GranskaOchSkickaIn]: GranskaAnsokanFormKeys[]
}

const stepsContext: IStepsContext = {
  [TidsbegransadAnstallningStepFormikId.OmDinArbetsplats]: [
    AnsokanKeys.NuvarandeArbetsplats,
    AnsokanKeys.Organisationsnummer,
    AnsokanKeys.HarAgandeskap,
    AnsokanKeys.Agarandel,
  ],
  [TidsbegransadAnstallningStepFormikId.OmDinAnstallning]: [
    AnsokanKeys.Befattning,
    AnsokanKeys.IsVdEllerUndantagsgrupp,
    AnsokanKeys.Anstallningsdatum,
    AnsokanKeys.ArbetstimmarPerVecka,
    AnsokanKeys.AnstallningBilagor,
    AnsokanKeys.Anstallningsgrad,
  ],
  [TidsbegransadAnstallningStepFormikId.OmDinUppsagning]: [
    AnsokanKeys.SistaAnstallningsdatum,
    AnsokanKeys.ForetagKontaktpersonEpostadress,
    AnsokanKeys.ForetagKontaktpersonNamn,
  ],
  [TidsbegransadAnstallningStepFormikId.LonOchErsattning]: [
    AnsokanKeys.Manadslon,
    AnsokanKeys.Jour,
    AnsokanKeys.JourBelopp,
    AnsokanKeys.Provision,
    AnsokanKeys.ProvisionBelopp,
    AnsokanKeys.Sjukersattningsgrad,
    AnsokanKeys.SjukersattningsgradChoice,
  ],
  [TidsbegransadAnstallningStepFormikId.OmDinSysselsattning]: [
    AnsokanKeys.HasIntygatEtableringsvillkor,
  ],
  [TidsbegransadAnstallningStepFormikId.GranskaOchSkickaIn]: [
    GranskaAnsokanFormKeys.ForsakratUppgifter,
  ],
}

export default stepsContext
