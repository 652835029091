import * as Yup from 'yup'
import { ITjanstemanIAnstallningContent } from '@local/Views/SkapaAnsokan/TjanstemanIAnstallning/TjanstemanIAnstallning.types'
import { arbetsplatsValidationSchema } from '@local/Views/SkapaAnsokan/TjanstemanIAnstallning/Arbetsplats/Arbetsplats.schema'
import { anstallningTIAValidationSchema } from '@local/Views/SkapaAnsokan/TjanstemanIAnstallning/AnstallningTIA/AnstallningTIA.schema'
import { arbetslivserfarenhetTIAValidationSchema } from '@local/Views/SkapaAnsokan/TjanstemanIAnstallning/ArbetslivserfarenhetTIA/ArbetslivserfarenhetTIA.schema'
import { granskaAnsokanValidationSchema } from '@local/Views/SkapaAnsokan/TjanstemanIAnstallning/GranskaAnsokan/GranskaAnsokan.schema'

export const tjanstemanIAnstallningValidationSchema = (
  content: ITjanstemanIAnstallningContent
) =>
  Yup.object()
    .concat(arbetsplatsValidationSchema(content.tjanstepersonansokanValidering))
    .concat(
      anstallningTIAValidationSchema(
        content.tjanstepersonansokanValidering,
        content.datumValidering
      )
    )
    .concat(
      arbetslivserfarenhetTIAValidationSchema(
        content.tjanstepersonansokanValidering
      )
    )
    .concat(
      granskaAnsokanValidationSchema(content.tjanstepersonansokanValidering)
    )
