import { ISteps } from '@local/Components/Stepper/Stepper.types'
import { RootState } from '@local/Store/configureStore'
import { TidsbegransadAnstallningStep } from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/TidsbegransadAnstallning.types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface ITidsbegransadAnstallningStepperState {
  steps: ISteps[]
  activeStep: TidsbegransadAnstallningStep
}

const initialState: ITidsbegransadAnstallningStepperState = {
  steps: [],
  activeStep: null,
}

export const tidsbegransadAnstallningStepperSlice = createSlice({
  name: 'tidsbegransadAnstallningStepper',
  initialState,
  reducers: {
    initStepper: (
      state,
      action: PayloadAction<Partial<ITidsbegransadAnstallningStepperState>>
    ) => {
      state.steps = action.payload.steps
      state.activeStep = action.payload.activeStep
    },
    setActiveStep: (state, action: PayloadAction<number>) => {
      state.activeStep = action.payload
    },
    goToNextStep: (state) => {
      state.activeStep =
        (state.activeStep as number) < state.steps.length - 1
          ? state.activeStep + 1
          : state.activeStep
    },
    goToPreviousStep: (state) => {
      state.activeStep =
        (state.activeStep as number) > 0
          ? state.activeStep - 1
          : state.activeStep
    },
    resetStepperState: () => initialState,
  },
})

export const {
  setActiveStep,
  goToNextStep,
  goToPreviousStep,
  initStepper,
  resetStepperState,
} = tidsbegransadAnstallningStepperSlice.actions

export const selectTidsbegransadAnstallningStepper = (state: RootState) =>
  state.tidsbegransadAnstallningStepper

export default tidsbegransadAnstallningStepperSlice.reducer
