import HTMLMapper from '@local/Components/HTMLMapper'
import NumberInput from '@local/Components/NumberInput'
import Tooltip from '@local/Components/Tooltip'
import { usePickEpiContent } from '@local/Utils/Hooks'
import { IAnstallningTBFormValues } from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/AnstallningTB/Types'
import { ITidsbegransadAnstallningContent } from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/TidsbegransadAnstallning.types'
import { AnstallningFormTypes } from '@local/Views/SkapaAnsokan/Types'
import { useFormikContext } from 'formik'

const ArbetstimmarPerVecka = () => {
  const formikContext = useFormikContext<IAnstallningTBFormValues>()

  const {
    anstallningTb: { arbetstimmarPerVecka, tooltiparbetstimmarPerVecka },
  } = usePickEpiContent<ITidsbegransadAnstallningContent>()

  return (
    <>
      <Tooltip
        heading={arbetstimmarPerVecka.titel}
        tooltipContent={
          <HTMLMapper body={tooltiparbetstimmarPerVecka.mainBody} />
        }
      />

      <NumberInput
        formikContext={formikContext}
        label={arbetstimmarPerVecka.textFaltTitel}
        name={AnstallningFormTypes.ArbetstimmarPerVecka}
      />
    </>
  )
}

export default ArbetstimmarPerVecka
