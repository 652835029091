import { useMemo } from 'react'
import { ISteps } from '@local/Components/Stepper/Stepper.types'
import { usePickEpiContent } from '@local/Utils/Hooks'
import {
  ITjanstemanIAnstallningContent,
  TjanstemanIAnstallningStep,
  TjanstemanIAnstallningStepFormikId,
} from '@local/Views/SkapaAnsokan/TjanstemanIAnstallning/TjanstemanIAnstallning.types'
import { FormikContextType, useFormikContext } from 'formik'
import { IKeyValue } from '@local/Types'
import stepsContext from '@local/Views/SkapaAnsokan/TjanstemanIAnstallning/TjanstemanIAnstallningForm/stepsContext'
import { isFieldErrorAndTouched } from '@local/Utils/Helpers/Forms/form.helpers'
import { ITjanstemanIAnstallningFormValues } from '@local/Views/SkapaAnsokan/TjanstemanIAnstallning/TjanstemanIAnstallningForm/types'

const getStepHasErrors = (
  step: TjanstemanIAnstallningStepFormikId,
  formikContext: FormikContextType<IKeyValue>
) => {
  let stepHasErrors = false
  const { touched, errors } = formikContext

  stepsContext[step].forEach((fieldInStep) => {
    if (isFieldErrorAndTouched(errors, touched, fieldInStep)) {
      stepHasErrors = true
    }
  })

  return stepHasErrors
}

const useGetSteps = () => {
  const formikContext = useFormikContext<ITjanstemanIAnstallningFormValues>()

  const { steps: stepsContent } =
    usePickEpiContent<ITjanstemanIAnstallningContent>()

  const steps: ISteps[] = useMemo(
    () => [
      {
        step: TjanstemanIAnstallningStep.OmDinArbetsplats,
        title: stepsContent.omDinArbetsplats,
        formikId: TjanstemanIAnstallningStepFormikId.OmDinArbetsplats,
        stepHasErrors: getStepHasErrors(
          TjanstemanIAnstallningStepFormikId.OmDinArbetsplats,
          formikContext
        ),
        shouldShowStep: true,
      },
      {
        step: TjanstemanIAnstallningStep.OmDinAnstallning,
        title: stepsContent.omDinAnstallning,
        formikId: TjanstemanIAnstallningStepFormikId.OmDinAnstallning,
        stepHasErrors: getStepHasErrors(
          TjanstemanIAnstallningStepFormikId.OmDinAnstallning,
          formikContext
        ),
        shouldShowStep: true,
      },
      {
        step: TjanstemanIAnstallningStep.OmDinSysselsattning,
        title: stepsContent.omDinSysselsattning,
        formikId: TjanstemanIAnstallningStepFormikId.OmDinSysselsattning,
        stepHasErrors: getStepHasErrors(
          TjanstemanIAnstallningStepFormikId.OmDinSysselsattning,
          formikContext
        ),
        shouldShowStep: true,
      },
      {
        step: TjanstemanIAnstallningStep.GranskaOchSkickaIn,
        title: stepsContent.granskaOchSkickaIn,
        formikId: TjanstemanIAnstallningStepFormikId.GranskaOchSkickaIn,
        stepHasErrors: getStepHasErrors(
          TjanstemanIAnstallningStepFormikId.GranskaOchSkickaIn,
          formikContext
        ),
        shouldShowStep: true,
      },
    ],
    [stepsContent, formikContext]
  )

  return { steps }
}

export default useGetSteps
