import { AnsokanKeys } from '@local/Types'
import { RadioChoice } from '@local/Types/form.types'
import { ITidsbegransadAnstallningFormValues } from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/TidsbegransadAnstallningForm/types'
import { isNil } from 'ramda'

export const purifyEntries = (entries: string[]) => ({
  id: entries[0],
  value: entries[1],
})

//Middleware to be used to transform data before storing in redux slice
export const tidsbegransadAnstallningStoreMiddleware = (
  changes: Partial<ITidsbegransadAnstallningFormValues>
): Partial<ITidsbegransadAnstallningFormValues> => {
  let changesToStore: Partial<ITidsbegransadAnstallningFormValues> = changes

  Object.entries(changes).forEach((change) => {
    const fieldChange = purifyEntries(change)

    switch (fieldChange.id as AnsokanKeys) {
      case AnsokanKeys.Agarandel:
        if (!isNil(fieldChange.value)) {
          changesToStore = {
            ...changesToStore,
            [AnsokanKeys.HarAgandeskap]: RadioChoice.Yes,
          }
        }

        break
      case AnsokanKeys.JourBelopp:
        if (!isNil(fieldChange.value)) {
          changesToStore = {
            ...changesToStore,
            [AnsokanKeys.Jour]: RadioChoice.Yes,
          }
        }

        break

      case AnsokanKeys.ProvisionBelopp:
        if (!isNil(fieldChange.value)) {
          changesToStore = {
            ...changesToStore,
            [AnsokanKeys.Provision]: RadioChoice.Yes,
          }
        }

        break

      case AnsokanKeys.Sjukersattningsgrad:
        if (!isNil(fieldChange.value)) {
          changesToStore = {
            ...changesToStore,
            [AnsokanKeys.SjukersattningsgradChoice]: RadioChoice.Yes,
          }
        }

        break
    }
  })

  return changesToStore
}
