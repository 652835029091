import { Grid2 as Grid } from '@mui/material'
import { useFormikContext } from 'formik'
import { usePickEpiContent } from '@local/Utils/Hooks'
import { ITidsbegransadAnstallningContent } from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/TidsbegransadAnstallning.types'
import { useGetAnsokanQuery } from '@local/Services/API/hamtaAnsokanApi'
import Spinner from '@local/Components/Spinner'
import Gutter from '@local/Components/Gutter'
import { generateErrorMessage } from '@local/Utils/Helpers/Forms/form.helpers'
import Anstallningsgrad from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/AnstallningTB/Components/Anstallningsgrad'
import ArbetstimmarPerVecka from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/AnstallningTB/Components/ArbetstimmarPerVecka'
import { AnstallningFormTypes } from '@local/Views/SkapaAnsokan/Types'
import DateInput from '@local/Components/DateInput'
import { replaceEpiVariables } from '@local/Utils/Helpers'
import {
  dayBeforeDate,
  getEarliestOccuringDate,
  startDate,
  yesterday,
} from '@local/Utils/Helpers/Datetime.helpers'
import VD from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/AnstallningTB/Components/VD'
import Bilagor from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/AnstallningTB/Components/Bilagor'
import { Befattningar } from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/AnstallningTB/Components/Befattningar/Befattningar'
import { ITidsbegransadAnstallningFormValues } from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/TidsbegransadAnstallningForm/types'

const AnstallningTB = () => {
  const { data, isLoading } = useGetAnsokanQuery()

  const formikContext = useFormikContext<ITidsbegransadAnstallningFormValues>()
  const { errors, touched, values, setFieldValue } = formikContext

  const {
    anstallningTb: {
      befattning,
      anstallningsdatum,
      bifogaArbetsgivarintyg,
      tooltipBefattning,
      laddaUppBilaga,
    },
  } = usePickEpiContent<ITidsbegransadAnstallningContent>()

  if (isLoading) {
    return <Spinner centered />
  }

  return (
    <Grid container>
      <Grid size={{ lg: 10 }} sx={{ width: 1 }}>
        <Befattningar
          content={befattning}
          tooltipContent={tooltipBefattning}
          formikContext={formikContext}
        />

        <Gutter xs={32} />

        <VD />

        <Gutter xs={32} />

        <DateInput
          formikFieldName={AnstallningFormTypes.Anstallningsdatum}
          formikContext={formikContext}
          label={anstallningsdatum.textFaltTitel}
          minDate={startDate}
          maxDate={getEarliestOccuringDate([
            yesterday,
            dayBeforeDate(values.sistaAnstallningsdatum),
          ])}
          title={replaceEpiVariables(anstallningsdatum.titel, {
            foretag: data.foretag.namn,
          })}
        />

        <Gutter xs={32} />

        <ArbetstimmarPerVecka />

        <Gutter xs={32} />

        <Anstallningsgrad />

        <Gutter xs={32} />

        <Bilagor
          content={{
            ...laddaUppBilaga,
            heading: bifogaArbetsgivarintyg.heading,
            uploadButtonText: bifogaArbetsgivarintyg.laddaUppKnapp,
          }}
          formikFieldName={AnstallningFormTypes.AnstallningBilagor}
          files={values.anstallningBilagor}
          setFieldValue={setFieldValue}
          errorMessage={generateErrorMessage({
            touched: touched?.anstallningBilagor !== undefined,
            errorMsg: errors?.anstallningBilagor as string,
          })}
        />
      </Grid>
    </Grid>
  )
}

export default AnstallningTB
