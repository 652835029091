import { IAnsokanPatchApiModel } from '@local/Services/Types/apiTypes'
import {
  IRequestedUpdates,
  ITjanstemanIAnstallningFormValues,
} from '@local/Views/SkapaAnsokan/TjanstemanIAnstallning/TjanstemanIAnstallningForm/types'
import { useCallback, useState } from 'react'

const useFormikWatcherCallback = () => {
  const [requestedUpdates, setRequestedUpdates] = useState<IRequestedUpdates>({
    updatesToPatch: null,
    updatesToStore: null,
  })

  const formikWatcherCallback = useCallback(
    (
      valuesToPatch: Partial<IAnsokanPatchApiModel>,
      valuesToStore: Partial<ITjanstemanIAnstallningFormValues>
    ) => {
      setRequestedUpdates({
        updatesToPatch: {
          ...requestedUpdates.updatesToPatch,
          ...valuesToPatch,
        },
        updatesToStore: {
          ...requestedUpdates.updatesToStore,
          ...valuesToStore,
        },
      })
    },
    [requestedUpdates.updatesToPatch, requestedUpdates.updatesToStore]
  )

  return { formikWatcherCallback, requestedUpdates, setRequestedUpdates }
}

export default useFormikWatcherCallback
