import HTMLMapper from '@local/Components/HTMLMapper'
import TextInput from '@local/Components/TextInput'
import Tooltip from '@local/Components/Tooltip'
import Gutter from '@local/Components/Gutter'
import { useGetAnsokanQuery } from '@local/Services/API/hamtaAnsokanApi'
import { replaceEpiVariables } from '@local/Utils/Helpers'
import { usePickEpiContent } from '@local/Utils/Hooks'
import { ITidsbegransadAnstallningContent } from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/TidsbegransadAnstallning.types'
import { useFormikContext } from 'formik'
import { ITidsbegransadAnstallningFormValues } from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/TidsbegransadAnstallningForm/types'
import Spinner from '@local/Components/Spinner'
import { AnsokanKeys } from '@local/Types'

const Kontaktpersoner = () => {
  const formikContext = useFormikContext<ITidsbegransadAnstallningFormValues>()

  const {
    uppsagning: { kontaktPersoner, tooltipKontaktPersoner },
  } = usePickEpiContent<ITidsbegransadAnstallningContent>()

  const { data, isLoading } = useGetAnsokanQuery()

  if (isLoading) {
    return <Spinner />
  }

  return (
    <>
      <Tooltip
        heading={replaceEpiVariables(kontaktPersoner.titel, {
          foretag: data.foretag.namn,
        })}
        tooltipContent={<HTMLMapper body={tooltipKontaktPersoner.mainBody} />}
      />
      <TextInput
        inputMode="text"
        formikContext={formikContext}
        label={kontaktPersoner.textFaltTitelNamn}
        name={AnsokanKeys.ForetagKontaktpersonNamn}
        maxLength={100}
      />

      <Gutter xs={16} />

      <TextInput
        withTrim
        inputMode="email"
        formikContext={formikContext}
        label={kontaktPersoner.textFaltTitelMailadress}
        name={AnsokanKeys.ForetagKontaktpersonEpostadress}
      />
    </>
  )
}

export default Kontaktpersoner
