import { IAnsokanApiModel } from '@local/Services/Types/apiTypes'
import { booleanToRadioChoice } from '@local/Utils/Helpers/Forms/form.helpers'
import { initialArbetslivserfarenhetTIAValues } from '@local/Views/SkapaAnsokan/TjanstemanIAnstallningOld/ArbetslivserfarenhetTIA/ArbetslivserfarenhetTIAForm.schema'
import { IArbetslivserfarenhetTIAFormValues } from '@local/Views/SkapaAnsokan/TjanstemanIAnstallningOld/ArbetslivserfarenhetTIA/Types'

const mapAnsokanToArbetslivserfarenhetTIAFormValues = (
  ansokan: IAnsokanApiModel
): IArbetslivserfarenhetTIAFormValues => {
  const { hasIntygatEtableringsvillkor, anstallningsuppgifter } = ansokan

  return {
    ...initialArbetslivserfarenhetTIAValues,
    hasIntygatEtableringsvillkor:
      booleanToRadioChoice(hasIntygatEtableringsvillkor) ??
      initialArbetslivserfarenhetTIAValues.hasIntygatEtableringsvillkor,
    arbetstimmarPerVecka:
      anstallningsuppgifter?.arbetstimmarPerVecka ??
      initialArbetslivserfarenhetTIAValues.arbetstimmarPerVecka,
  }
}

export { mapAnsokanToArbetslivserfarenhetTIAFormValues }
