import { JSX } from 'react'
import { usePickEpiContent } from '@local/Utils/Hooks'
import { replaceEpiVariables } from '@local/Utils/Helpers'
import { ITjanstepersonAnsokanContent } from '@local/Types'
import { Typography } from '@mui/material'
import { useUser } from '@trr/app-shell-data'

const Preamble = (): JSX.Element => {
  const {
    onboarding: { titelOchIngress },
  } = usePickEpiContent<ITjanstepersonAnsokanContent>()

  const { firstName } = useUser()

  return (
    <>
      <Typography variant="h1" gutterBottom>
        {replaceEpiVariables(titelOchIngress.titel, {
          firstName: firstName,
        })}
      </Typography>
      <Typography variant="body1">{titelOchIngress.ingress}</Typography>
    </>
  )
}

export default Preamble
