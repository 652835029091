import { IUniqueFile } from '@local/Components/FileUpload/FileUpload.types'
import { RootState } from '@local/Store/configureStore'
import { ITjanstemanIAnstallningFormValues } from '@local/Views/SkapaAnsokan/TjanstemanIAnstallning/TjanstemanIAnstallningForm/types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface ISkapaAnsokanState {
  values: ITjanstemanIAnstallningFormValues
  formIsDirty: boolean
}

const initialState: ISkapaAnsokanState = {
  values: null,
  formIsDirty: false,
}

export const tjanstemanIAnstallningFormSlice = createSlice({
  name: 'tjanstemanIAnstallningForm',
  initialState,
  reducers: {
    setValues: (
      state,
      action: PayloadAction<ITjanstemanIAnstallningFormValues>
    ) => {
      state.values = action.payload
    },
    setAnstallningBilagor: (state, action: PayloadAction<IUniqueFile[]>) => {
      if (state.values) {
        state.values.anstallningBilagor = action.payload
      }
    },
    setFormIsDirty: (state, action: PayloadAction<boolean>) => {
      state.formIsDirty = action.payload
    },
    resetState: () => initialState,
  },
})

export const { setValues, setAnstallningBilagor, setFormIsDirty, resetState } =
  tjanstemanIAnstallningFormSlice.actions

export const selectTjanstemanIAnstallningForm = (state: RootState) =>
  state.tjanstemanIAnstallningForm

export default tjanstemanIAnstallningFormSlice.reducer
