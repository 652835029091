import { useGetAnsokanQuery } from '@local/Services/API/hamtaAnsokanApi'
import Spinner from '@local/Components/Spinner'
import NuvarandeArbetsplats from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/Arbetsplats/Components/NuvarandeArbetsplats'
import Agarandel from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/Arbetsplats/Components/Agarandel'
import Organisationsnummer from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/Arbetsplats/Components/Organisationsnummer'
import { Grid2 as Grid } from '@mui/material'
import Gutter from '@local/Components/Gutter'
import { useFormikContext } from 'formik'
import { ITidsbegransadAnstallningFormValues } from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/TidsbegransadAnstallningForm/types'
import { RadioChoice } from '@local/Types/form.types'

const Arbetsplats = () => {
  const { values } = useFormikContext<ITidsbegransadAnstallningFormValues>()

  const { data, isLoading } = useGetAnsokanQuery()

  if (isLoading) {
    return <Spinner centered />
  }

  return (
    <Grid container>
      <Grid size={{ lg: 10 }} sx={{ width: 1 }}>
        {data.foretag.namn && (
          <>
            <NuvarandeArbetsplats />

            <Gutter xs={32} />
          </>
        )}

        {values.nuvarandeArbetsplats === RadioChoice.No && (
          <>
            <Organisationsnummer />
            <Gutter xs={32} />
          </>
        )}

        <Agarandel />
      </Grid>
    </Grid>
  )
}

export default Arbetsplats
