import React, { JSX } from 'react'
import { isNil } from 'ramda'
import { INumberInput } from '@local/Components/NumberInput/Types'
import { TextField } from '@mui/material'

const NumberInput = ({
  formikContext,
  label,
  name,
}: INumberInput): JSX.Element => {
  const { touched, errors, values, handleChange, handleBlur } = formikContext
  const isError = touched?.[name] && !isNil(errors?.[name])

  const handleScrollwheel = (e: React.WheelEvent<HTMLInputElement>) => {
    e.currentTarget.blur()
  }

  return (
    <TextField
      fullWidth
      inputProps={{ onWheel: handleScrollwheel }}
      type="number"
      error={isError}
      helperText={isError ? (errors?.[name] as string) : null}
      defaultValue={values?.[name] as number}
      label={label}
      name={name}
      onChange={handleChange}
      onBlur={handleBlur}
    />
  )
}

export default NumberInput
