import * as Yup from 'yup'
import { AnsokanKeys, ICommonAnsokanValidering } from '@local/Types'
import { RadioChoice } from '@local/Types/form.types'
import { IArbetslivserfarenhetTBFormValues } from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/ArbetslivserfarenhetTB/Types'

export const arbetslivserfarenhetTBValidationSchema = (
  validering: ICommonAnsokanValidering
): Yup.ObjectSchema<IArbetslivserfarenhetTBFormValues> =>
  Yup.object().shape({
    [AnsokanKeys.HasIntygatEtableringsvillkor]:
      Yup.mixed<RadioChoice>().required(
        validering.etableringsvillkorIsRequired
      ),
  })

export const initialArbetslivserfarenhetTBValues: IArbetslivserfarenhetTBFormValues =
  {
    hasIntygatEtableringsvillkor: null,
  }
