import HTMLMapper from '@local/Components/HTMLMapper'
import TextInput from '@local/Components/TextInput'
import Tooltip from '@local/Components/Tooltip'
import Gutter from '@local/Components/Gutter'
import { useGetAnsokanState } from '@local/Services/API/hamtaAnsokanApi'
import { replaceEpiVariables } from '@local/Utils/Helpers'
import { usePickEpiContent } from '@local/Utils/Hooks'
import { ITidsbegransadAnstallningContent } from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/TidsbegransadAnstallning.types'
import {
  IUppsagningFormValues,
  UppsagningFormTypes,
} from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/Uppsagning/Types'
import { useFormikContext } from 'formik'

const Kontaktpersoner = () => {
  const formikContext = useFormikContext<IUppsagningFormValues>()

  const {
    uppsagning: { kontaktPersoner, tooltipKontaktPersoner },
  } = usePickEpiContent<ITidsbegransadAnstallningContent>()

  const {
    data: {
      foretag: { namn: foretagsNamn },
    },
  } = useGetAnsokanState()

  return (
    <>
      <Tooltip
        heading={replaceEpiVariables(kontaktPersoner.titel, {
          foretag: foretagsNamn,
        })}
        tooltipContent={<HTMLMapper body={tooltipKontaktPersoner.mainBody} />}
      />
      <TextInput
        inputMode="text"
        formikContext={formikContext}
        label={kontaktPersoner.textFaltTitelNamn}
        name={UppsagningFormTypes.ForetagKontaktpersonNamn}
        maxLength={100}
      />

      <Gutter xs={16} />

      <TextInput
        withTrim
        inputMode="email"
        formikContext={formikContext}
        label={kontaktPersoner.textFaltTitelMailadress}
        name={UppsagningFormTypes.ForetagKontaktpersonEpostadress}
      />
    </>
  )
}

export default Kontaktpersoner
