import { AnsokanKeys, IKeyValue } from '@local/Types'
import { RadioChoice } from '@local/Types/form.types'
import { GranskaAnsokanFormKeys } from '@local/Views/SkapaAnsokan/TjanstemanIAnstallning/GranskaAnsokan/Components/GranskaAnsokanFormModal/types'
import { FormikErrors, FormikValues, FormikTouched } from 'formik'
import { isNil } from 'ramda'
import * as Yup from 'yup'

export const generateErrorMessage = ({
  errorMsg,
  touched,
}: {
  /** The error provided from Formik */
  errorMsg: string | FormikErrors<FormikValues>
  /** The touched object provided by Formik */
  touched: boolean | FormikTouched<FormikValues>
}): string | FormikErrors<FormikValues> => (touched && errorMsg) || ''

const toDigits = (numString: string) =>
  numString.replace(/\D/g, '').split('').map(Number)

export const validateOrganizationNumber = (
  organizationNumber: string
): boolean => {
  if (!organizationNumber) {
    return false
  }
  const digits = toDigits(organizationNumber)
  const checkDigit = digits[digits.length - 1]
  const total = digits
    .slice(0, -1)
    .reverse()
    .map((current: number, index: number) =>
      index % 2 === 0 ? current * 2 : current
    )
    .map((current: number) => (current > 9 ? current - 9 : current))
    .reduce(
      (current: number, accumulator: number) => current + accumulator,
      checkDigit
    )
  return total % 10 === 0
}

export const countDecimals = (value: number): number => {
  if (!value || Math.floor(value) === value) {
    return 0
  }
  return value.toString().split('.')[1].length || 0
}

export const booleanToRadioChoice = (value: boolean): RadioChoice => {
  if (!isNil(value)) {
    return value ? RadioChoice.Yes : RadioChoice.No
  }
  return null
}

export const trimString = (str: string) => str.replace(/\s/g, '')

export const handleChangeWithTrim = (
  e: React.ChangeEvent<HTMLInputElement>,
  handleChange: (e: React.ChangeEvent<unknown>) => void
) => {
  e.currentTarget.value = trimString(e.currentTarget.value)

  handleChange(e)
}

export const isFieldErrorAndTouched = (
  errors: FormikErrors<IKeyValue>,
  touched: FormikTouched<IKeyValue>,
  fieldName: string | AnsokanKeys | GranskaAnsokanFormKeys
): boolean => {
  const fieldError = errors?.[fieldName]
  const fieldTouched = touched?.[fieldName]
  if (typeof fieldError === 'string' && fieldTouched === true) {
    return true
  } else if (
    typeof fieldError === 'object' &&
    typeof fieldTouched === 'object'
  ) {
    // Check for nested fields
    const nestedFieldNames = Object.keys(fieldError)
    return nestedFieldNames.some((nestedField) =>
      isFieldErrorAndTouched(
        fieldError as FormikErrors<IKeyValue>,
        fieldTouched as FormikTouched<IKeyValue>,
        nestedField
      )
    )
  }
  return false
}

/**
 * The typings for Yup are missing some fields,
 * so extend it to include the needed fields
 */
interface TestContextExtended<T> extends Omit<Yup.TestContext, 'options'> {
  from: {
    schema: Yup.AnyObjectSchema
    value: T
  }[]
  options: {
    index: number
  }
  parent: T
}

export const getYupTestContext = <T>(
  testContext: Yup.TestContext
): TestContextExtended<T> => testContext as TestContextExtended<T>

export const getYupCurrentSchemaValues = <T>(
  testContext: Yup.TestContext
): T => {
  const context = getYupTestContext<T>(testContext)
  const formValues = context.parent

  return formValues
}
