import { useEffect } from 'react'

/**
 * Hook for using unmount, calling the callback on unmount
 * @param callback called on unmount
 */
const useOnUnmount = (callback: () => void) => {
  useEffect(() => () => {
    callback()
  })
}

export default useOnUnmount
