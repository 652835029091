import * as Yup from 'yup'
import { RadioChoice } from '@local/Types/form.types'
import { countDecimals } from '@local/Utils/Helpers/Forms/form.helpers'
import {
  ILonOchErsattningFormValues,
  LonOchErsattningFormTypes,
} from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/LonOchErsattning/Types'
import { ILonOchErsattningContent } from '@local/Views/SkapaAnsokan/TidsbegransadAnstallning/LonOchErsattning/Types/content'

export const lonOchErsattningValidationSchema = (
  content: ILonOchErsattningContent
): Yup.ObjectSchema<ILonOchErsattningFormValues> =>
  Yup.object().shape({
    [LonOchErsattningFormTypes.Manadslon]: Yup.number()
      .required(content.manadslon.varning)
      .typeError(content.manadslon.varning)
      .integer(content.manadslon.varningDecimaler)
      .positive(content.manadslon.varningNegativt)
      .max(999999, content.manadslon.maxAmountExceeded),
    [LonOchErsattningFormTypes.Provision]: Yup.mixed<RadioChoice>()
      .oneOf(Object.values(RadioChoice))
      .required(content.provision.varning),
    [LonOchErsattningFormTypes.ProvisionBelopp]: Yup.number()
      .nullable()
      .when(
        [LonOchErsattningFormTypes.Provision],
        ([provision]: [RadioChoice], schema) => {
          if (provision === RadioChoice.Yes) {
            return schema
              .required(content.provisionBelopp.varning)
              .positive(content.provisionBelopp.varningNegativt)
              .test(
                'len',
                content.provisionBelopp.varningDecimaler,
                (value: number) => countDecimals(value) <= 2
              )
              .max(999999, content.provisionBelopp.maxAmountExceeded)
              .typeError(content.provisionBelopp.varning)
          }
        }
      ),
    [LonOchErsattningFormTypes.Jour]: Yup.mixed<RadioChoice>()
      .oneOf(Object.values(RadioChoice))
      .required(content.jour.varning),
    [LonOchErsattningFormTypes.JourBelopp]: Yup.number()
      .nullable()
      .when(
        [LonOchErsattningFormTypes.Jour],
        ([jour]: [RadioChoice], schema) => {
          if (jour === RadioChoice.Yes) {
            return schema
              .required(content.jourBelopp.varning)
              .positive(content.jourBelopp.varningNegativt)
              .test(
                'len',
                content.jourBelopp.varningDecimaler,
                (value: number) => countDecimals(value) <= 2
              )
              .max(999999, content.jourBelopp.maxAmountExceeded)
              .typeError(content.jourBelopp.varning)
          }
        }
      ),
    [LonOchErsattningFormTypes.SjukersattningsgradChoice]:
      Yup.mixed<RadioChoice>()
        .oneOf(Object.values(RadioChoice))
        .required(content.sjukersattningsgradChoice.varning),
    [LonOchErsattningFormTypes.Sjukersattningsgrad]: Yup.number()
      .nullable()
      .when(
        [LonOchErsattningFormTypes.SjukersattningsgradChoice],
        ([sjukersattningsgradChoice]: [RadioChoice], schema) => {
          if (sjukersattningsgradChoice === RadioChoice.Yes) {
            return schema
              .required(content.sjukersattningsgrad.varning)
              .positive(content.sjukersattningsgrad.varningNegativt)
              .max(100, content.sjukersattningsgrad.varningHogtVarde)
              .integer(content.sjukersattningsgrad.varningDecimaler)
              .typeError(content.sjukersattningsgrad.varning)
          }
        }
      ),
  })

export const initialLonOchErsattningValues: ILonOchErsattningFormValues = {
  manadslon: null,
  provision: null,
  provisionBelopp: null,
  jour: null,
  jourBelopp: null,
  sjukersattningsgradChoice: null,
  sjukersattningsgrad: null,
}
